import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, initialStateReducer } from "src/app/utils/redux";
import { StateReducer } from "src/app/types/redux.types";
import { BackendVersion } from "src/app/types/misc.types";
import { applicationStart, changeLanguage, fetchBackendVersionAsync, setImpersonateUserId } from "src/app/store/features/misc/misc.actions";
import { Nullable } from "src/app/types/util.types";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";
import { LanguageCode } from "src/app/translations/utils";

const reducer = combineReducers({
	backendVersion: createReducer(initialStateReducer as StateReducer<BackendVersion>)
		.handleAction([ fetchBackendVersionAsync.request, fetchBackendVersionAsync.success, fetchBackendVersionAsync.failure ], handleBasicActions(fetchBackendVersionAsync)),
	impersonateUserId: createReducer(null as Nullable<number>)
		.handleAction([ setImpersonateUserId ], (_, action) => action.payload)
		.handleAction([ logoutAsync.success, forgetSession ], () => null),
	language: createReducer(LanguageCode.EN)
		.handleAction([ applicationStart ], state => {
			if (Object.values(LanguageCode).includes(state)) return state;

			return Object.values(LanguageCode)[ 0 ];
		})
		.handleAction([ changeLanguage ], (_, action) => action.payload),
});

export default reducer;
