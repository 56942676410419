import { Nullable } from "src/app/types/util.types";

export enum EventStatus {
	BEGIN = "BEGIN",
	PROGRESS = "PROGRESS",
	END = "END"
}

export enum EventType {
	EVENT_UNSPEC = "UNSPEC",
	EVENT_DIP = "DIP",
	EVENT_SWELL = "SWELL",
	EVENT_INTERRUPT = "INTERRUPT",
	EVENT_MIN = "MIN",
	EVENT_MAX = "MAX",
}

export enum EventChannel {
	CH_L1 = "CH_L1",
	CH_L2 = "CH_L2",
	CH_L3 = "CH_L3",
	CH_N = "CH_N",
	CH_E = "CH_E",
	CH_TOTAL = "CH_TOTAL"
}

export enum EventFlag {
	FLAGGED_61000_4_30 = "FLAGGED_61000_4_30",
	PLL_OUT_OF_SYNC = "PLL_OUT_OF_SYNC",
	ADC_OVERFLOW = "ADC_OVERFLOW",
	GPS_OUT_OF_SYNC = "GPS_OUT_OF_SYNC",
	TIME_RESYNC = "TIME_RESYNC",
	WAVEFORM_CHART = "WAVEFORM_CHART",
	RMS12_CHART = "RMS12_CHART",
	PQDIF_FILE = "PQDIF_FILE",
}

export enum EventOrigin {
	UNKNOWN = "UNKNOWN",
	VOLTAGE = "VOLTAGE",
	CURRENT = "CURRENT",
	FREQUENCY = "FREQUENCY",
	POWER_ACTIVE = "POWER_ACTIVE",
	POWER_ACTIVE_PLUS = "POWER_ACTIVE_PLUS",
	POWER_ACTIVE_MINUS = "POWER_ACTIVE_MINUS",
	POWER_REACTIVE = "POWER_REACTIVE",
	POWER_REACTIVE_FUNDAMENTAL = "POWER_REACTIVE_FUNDAMENTAL",
	POWER_APPARENT = "POWER_APPARENT",
	POWER_APPARENT_FUNDAMENTAL = "POWER_APPARENT_FUNDAMENTAL",
	DPF = "DPF",
	POWER_FACTOR = "POWER_FACTOR",
	ENERGY_ACTIVE_PLUS = "ENERGY_ACTIVE_PLUS",
	ENERGY_ACTIVE_MINUS = "ENERGY_ACTIVE_MINUS",
	ENERGY_REACTIVE_Q1 = "ENERGY_REACTIVE_Q1",
	ENERGY_REACTIVE_Q2 = "ENERGY_REACTIVE_Q2",
	ENERGY_REACTIVE_Q3 = "ENERGY_REACTIVE_Q3",
	ENERGY_REACTIVE_Q4 = "ENERGY_REACTIVE_Q4",
	VOLTAGE_THDF = "VOLTAGE_THDF",
	CURRENT_THDF = "CURRENT_THDF",
	UNBALANCE_VOLTAGE_NEGATIVE = "UNBALANCE_VOLTAGE_NEGATIVE",
	FLICKER_PST = "FLICKER_PST",
	FLICKER_PLT = "FLICKER_PLT",
	WAVESHAPE = "WAVESHAPE",
	PHASE_JUMPS = "PHASE_JUMPS",
	INPUT_DIGITAL_INTERNAL_1 = "INPUT_DIGITAL_INTERNAL_1",
	INPUT_DIGITAL_INTERNAL_2 = "INPUT_DIGITAL_INTERNAL_2",
}

export type StandardEvents = {
	events: EventsCore & {
		eventsRecordedTotal: number
		eventsRecordedDip: number
		eventsRecordedSwell: number
		eventsRecordedInterrupt: number
		eventsRecordedOther: number
	}
}

export type UserEvents = {
	events: EventsCore
}

export type EventsCore = {
	status: {
		value: number
		text: EventStatus
	}[]
	types: {
		value: number
		text: EventType
	}[]
	channels: {
		mask: number
		text: EventChannel
	}[]
	flags: {
		mask: number
		text: EventFlag
	}[]
	origins: {
		value: number
		text: EventOrigin
	}[]
	event: Event[]
}

export type SingleEvent = {
	events: {
		status: {
			value: number
			text: EventStatus
		}[]
		types: {
			value: number
			text: EventType
		}[]
		channels: {
			value: number
			text: EventChannel
		}[]
		flags: {
			mask: number
			text: EventFlag
		}[]
		cfgWaveformRecDuration: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgWaveformPretriggerDuration: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgRms12RecTime: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgRms12PretriggerTime: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		event: Event
	}
}

export type Event = {
	id: number
	timestampStart: Nullable<number>
	timestampEnd: Nullable<number>
	timestampStatus: number
	duration: Nullable<number> // in us (e-6)
	type: number
	channels: number
	flags: number
	origin: number
	avgValue: {
		value: Nullable<number>
		unit: string
	}
	extremum: {
		value: Nullable<number>
		unit: string
	}
	threshold: {
		value: Nullable<number>
		unit: string
	}
}
