import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { EventWaveform, FetchEventRequest, RmsWaveform, Summary } from "src/app/types/api/dashboard.types";
import { SingleEvent, StandardEvents, UserEvents } from "src/app/types/api/event.types";

export const fetchSummaryAsync = createNetworkAction<undefined, SuccessPayload<Summary>>("DASHBOARD__FETCH_SUMMARY");

export const fetchStandardEventsAsync = createNetworkAction<undefined, SuccessPayload<StandardEvents>>("DASHBOARD__FETCH_STANDARD_EVENTS");
export const fetchStandardEventsWithOffsetAsync = createNetworkAction<number, SuccessPayload<StandardEvents>>("DASHBOARD__FETCH_STANDARD_EVENTS_WITH_OFFSET");
export const fetchNewStandardEventsAsync = createNetworkAction<undefined, SuccessPayload<StandardEvents>>("DASHBOARD__FETCH_NEW_STANDARD_EVENTS");

export const fetchUserEventsAsync = createNetworkAction<undefined, SuccessPayload<UserEvents>>("DASHBOARD__FETCH_USER_EVENTS");
export const fetchUserEventsWithOffsetAsync = createNetworkAction<number, SuccessPayload<UserEvents>>("DASHBOARD__FETCH_USER_EVENTS_WITH_OFFSET");
export const fetchNewUserEventsAsync = createNetworkAction<undefined, SuccessPayload<UserEvents>>("DASHBOARD__FETCH_NEW_USER_EVENTS");

export const fetchStandardEventByIdAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<SingleEvent>, FailurePayloadWithId>("DASHBOARD__FETCH_STANDARD_EVENT_BY_ID");
export const fetchUserEventByIdAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<SingleEvent>, FailurePayloadWithId>("DASHBOARD__FETCH_USER_EVENT_BY_ID");

export const fetchStandardEventWaveformAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<EventWaveform>, FailurePayloadWithId>("DASHBOARD__FETCH_STANDARD_EVENT_WAVEFORM");
export const fetchUserEventWaveformAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<EventWaveform>, FailurePayloadWithId>("DASHBOARD__FETCH_USER_EVENT_WAVEFORM");

export const fetchStandardRmsAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<RmsWaveform>, FailurePayloadWithId>("DASHBOARD__FETCH_STANDARD_RMS");
export const fetchUserRmsAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<RmsWaveform>, FailurePayloadWithId>("DASHBOARD__FETCH_USER_RMS");

export const fetchStandardEventPqdifAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<any>>("DASHBOARD__FETCH_STANDARD_EVENT_PQDIF");
export const fetchUserEventPqdifAsync = createNetworkAction<FetchEventRequest, SuccessPayloadWithId<any>>("DASHBOARD__FETCH_STANDARD_USER_PQDIF");

export const exportSettingsAsync = createNetworkAction<undefined, SuccessPayload<any>>("DASHBOARD__EXPORT_SETTINGS");
export const importSettingsAsync = createNetworkAction<File, SuccessPayload<any>>("DASHBOARD__IMPORT_SETTINGS");
