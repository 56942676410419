import { getEnvValue } from "src/app/utils/helpers";
import { LoggedUser } from "src/app/types/api/user.types";
import { SelectOption, SidebarItem } from "src/app/types/util.types";
import { MdDashboard } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaGear, FaUsers } from "react-icons/fa6";
import { GiChart } from "react-icons/gi";
import { ftpUploadTimeDictionary } from "src/app/utils/constants/dictionaries";
import { TFunction } from "i18next";

export const appVersion = getEnvValue("REACT_APP_VERSION");
export const isHostSource = getEnvValue("REACT_APP_API") === "same-origin";
export const baseUrl = getEnvValue("REACT_APP_API_CUSTOM_URL");
export const wsUrl = getEnvValue("REACT_APP_WS_CUSTOM_URL");
export const defaultView = getEnvValue("REACT_APP_DEFAULT_VIEW");

export const DEFAULT_PAGINATION_PAGE_INDEX = 0;
export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

// Sidebar
export const sidebarItems: (t: TFunction, loggedUser: LoggedUser, isAdmin: boolean) => SidebarItem[] = (t, loggedUser, isAdmin) => [
	{
		icon: MdDashboard,
		title: t("SIDEBAR.summary"),
		path: "/summary",
	}, {
		icon: BsFillLightningChargeFill,
		title: t("SIDEBAR.real time"),
		childrens: [
			{
				title: t("SIDEBAR.measurements"),
				path: "/realtime/measurements",
			}, {
				title: t("SIDEBAR.waveforms"),
				path: "/realtime/waveforms",
			}, {
				title: t("SIDEBAR.timeplot"),
				path: "/realtime/timeplot",
			}, {
				title: t("SIDEBAR.phasors"),
				path: "/realtime/phasors",
			}, {
				title: t("SIDEBAR.harmonics"),
				path: "/realtime/harmonics",
			}, {
				title: t("SIDEBAR.interharmonics"),
				path: "/realtime/interharmonics",
			},
		],
	}, {
		icon: GiChart,
		title: t("SIDEBAR.events"),
		childrens: [
			{
				title: t("SIDEBAR.events standard"),
				path: "/standard-events",
			}, {
				title: t("SIDEBAR.events user"),
				path: "/user-events",
			},
		],
	}, {
		icon: FaGear,
		title: t("SIDEBAR.settings"),
		childrens: [
			{
				title: t("SIDEBAR.analyzer"),
				path: "/analyzer",
			}, {
				title: t("SIDEBAR.recordings"),
				path: "/recordings",
			}, {
				title: t("SIDEBAR.communications"),
				path: "/communications",
			}, {
				title: t("SIDEBAR.input output"),
				path: "/input-output",
			}, {
				title: t("SIDEBAR.memory"),
				path: "/memory",
			}, {
				title: t("SIDEBAR.ftp"),
				path: "/ftp-client",
			}, {
				title: t("SIDEBAR.time"),
				path: "/time",
			}, {
				title: t("SIDEBAR.measurements"),
				path: "/measurements",
			}, {
				title: t("SIDEBAR.export import"),
				path: "/export-import",
			}, {
				title: t("SIDEBAR.firmware upgrade"),
				path: "/firmware-upgrade",
				hidden: !isAdmin,
			}, {
				title: t("SIDEBAR.password"),
				path: "/password",
			},
		],
	}, {
		icon: FaUsers,
		title: t("SIDEBAR.users"),
		path: "/users",
		hidden: !isAdmin,
	},
];

export const ftpUploadTimeSelectOptions = (length: number, startValue: number): SelectOption<number>[] => new Array(length).fill(0).map((_, i) => ({ value: startValue + i, label: ftpUploadTimeDictionary()[ startValue + i ] }));
