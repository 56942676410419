export type BackendVersion = {
	version: string
};

export enum MeasurementCategory {
	VOLTAGES = "voltages",
	VOLTAGES_DC = "voltagesDc",
	CURRENTS = "currents",
	VOLTAGES_WAVE = "voltagesWave",
	CURRENTS_WAVE = "currentsWave",
	ACTIVE_POWERS = "activePowers",
	ACTIVE_POWERS_WAVE = "activePowersWave",
	REACTIVE_POWERS = "reactivePowers",
	REACTIVE_POWERS_WAVE = "reactivePowersWave",
	APPARENT_POWERS = "apparentPowers",
	APPARENT_POWERS_WAVE = "apparentPowersWave",
	FREQUENCIES = "frequencies",
	FREQUENCIES_WAVE = "frequenciesWave",
	ENERGY = "energies",
	ACTIVE_ENERGY = "activeEnergy",
	REACTIVE_ENERGY = "reactiveEnergy",
	APPARENT_ENERGY = "apparentEnergy",
	PHASORS = "phasors",
	FACTOR = "factors",
	FLICKER = "flicker",
	POWER_FACTOR = "powerFactor",
	HARMONICS = "harmonics",
	INTERHARMONICS = "interharmonics",
	TANGENT = "tangent",
	ANGLES = "angles",
	PERCENT = "percent",
	DEFAULT = "default"
}
