import { Trans } from "react-i18next";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		className?: string
		i18nKey: string
	};

function Translate(props: Props) {

	const {
		className = "",
		i18nKey,
		language,
	} = props;

	return (
		<span className={ className }>
			<Trans
				lang={ language }
				i18nKey={ i18nKey }
				components={ {
					sub: <sub/>,
					sup: <sup/>,
				} }
			/>
		</span>
	);
}

const mapStateToProps = (state: RootState) => ({
	language: state.misc.language,
});

export default connect(mapStateToProps)(Translate);