import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { FormValidator } from "src/app/types/ui/form.types";
import { Button, Card } from "flowbite-react";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapCurrentThdfConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type CurrentThdfForm = {
	cfgCurrentThdfRecOpt: number
	cfgCurrentThdfRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgCurrentThdfThresholdMax: string
	cfgCurrentThdfThresholdMaxMin: number
	cfgCurrentThdfThresholdMaxMax: number
	cfgCurrentThdfChannels: ("cfgRelayCurrentThdf1RecOpt" | "cfgRelayCurrentThdf2RecOpt" | "cfgRelayCurrentThdf3RecOpt" | "cfgRelayCurrentThdf4RecOpt")[]
	cfgCurrentThdfActions: number
}

const validator: FormValidator<CurrentThdfForm> = {
	cfgCurrentThdfRecOpt: () => null,
	cfgCurrentThdfRecOptOptions: () => null,
	cfgCurrentThdfThresholdMax: (cfgCurrentThdfThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgCurrentThdfRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgCurrentThdfRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgCurrentThdfThresholdMax, optional, "he", { from: form.cfgCurrentThdfThresholdMaxMin.value, to: form.cfgCurrentThdfThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgCurrentThdfThresholdMaxMin: () => null,
	cfgCurrentThdfThresholdMaxMax: () => null,
	cfgCurrentThdfChannels: () => null,
	cfgCurrentThdfActions: () => null,
};

const ID = "current-thdf";

function CurrentThdfCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgCurrentThdf,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: CurrentThdfForm) => {
		reducerHandleChange("cfgCurrentThdfRecOpt", values.cfgCurrentThdfRecOpt);
		reducerHandleChange("cfgCurrentThdfThresholdMax", values.cfgCurrentThdfThresholdMax);
		reducerHandleChange("cfgCurrentThdfChannels", values.cfgCurrentThdfChannels);
		reducerHandleChange("cfgCurrentThdfActions", values.cfgCurrentThdfActions);
	};

	const _getInitialState = () => {
		const [ cfgCurrentThdfChannels, cfgCurrentThdfActions ] = mapCurrentThdfConfig(cfgCurrentThdf);
		return {
			cfgCurrentThdfRecOpt: createFormField((reducerForm.cfgCurrentThdfRecOpt.value !== reducerForm.cfgCurrentThdfRecOpt.initialValue) ? reducerForm.cfgCurrentThdfRecOpt.value : cfgCurrentThdf?.cfgCurrentThdfRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentThdfRecOptOptions: createFormField(cfgCurrentThdf?.cfgCurrentThdfRecOpt?.options ?? []),
			cfgCurrentThdfThresholdMax: createFormField((reducerForm.cfgCurrentThdfThresholdMax.value !== reducerForm.cfgCurrentThdfThresholdMax.initialValue) ? reducerForm.cfgCurrentThdfThresholdMax.value : cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentThdfThresholdMaxMin: createFormField(cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentThdfThresholdMaxMax: createFormField(cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentThdfChannels: createFormField(isChannelsDiff(reducerForm.cfgCurrentThdfChannels) ? reducerForm.cfgCurrentThdfChannels.value : cfgCurrentThdfChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentThdfActions: createFormField((reducerForm.cfgCurrentThdfActions.value !== reducerForm.cfgCurrentThdfActions.initialValue) ? reducerForm.cfgCurrentThdfActions.value : cfgCurrentThdfActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgCurrentThdf ]);

	useEffect(() => {
		toggleDisable("cfgCurrentThdfRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgCurrentThdfRecOpt", false);
		toggleDisable("cfgCurrentThdfThresholdMax", false);
		toggleDisable("cfgCurrentThdfChannels", false);
		toggleDisable("cfgCurrentThdfActions", false);

		handleChange("cfgCurrentThdfRecOpt", reducerForm.cfgCurrentThdfRecOpt.value);
		handleChange("cfgCurrentThdfThresholdMax", reducerForm.cfgCurrentThdfThresholdMax.value);
		handleChange("cfgCurrentThdfChannels", reducerForm.cfgCurrentThdfChannels.value);
		handleChange("cfgCurrentThdfActions", reducerForm.cfgCurrentThdfActions.value);

		toggleDisable("cfgCurrentThdfRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentThdfActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgCurrentThdfRecOpt.value,
		reducerForm.cfgCurrentThdfThresholdMax.value,
		reducerForm.cfgCurrentThdfChannels.value,
		reducerForm.cfgCurrentThdfActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				<Translate i18nKey="MEASUREMENTS HARMONICS.current THDF"/>
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgCurrentThdf?.cfgCurrentThdfRecOpt?.options ?? [] }
					formItem={ form.cfgCurrentThdfRecOpt }
					handleChange={ value => handleChange("cfgCurrentThdfRecOpt", value) }
					reducerFormItem={ reducerForm.cfgCurrentThdfRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgCurrentThdf?.cfgCurrentThdfRecOpt?.options ?? [] }
						formItem={ form.cfgCurrentThdfRecOpt }
						handleChange={ value => handleChange("cfgCurrentThdfRecOpt", value) }
						reducerFormItem={ reducerForm.cfgCurrentThdfRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("MEASUREMENTS.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgCurrentThdfThresholdMax }
											label={ t("MEASUREMENTS.max") }
											name="cfgCurrentThdfThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgCurrentThdfThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgCurrentThdfThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgCurrentThdfThresholdMax.value !== reducerForm.cfgCurrentThdfThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgCurrentThdf) && isNotNull(cfgCurrentThdf.relayChannelLabel) && isNotNull(cfgCurrentThdf.relayChannelLabel.ch1)) ? {
														value: "cfgRelayCurrentThdf1RecOpt",
														label: cfgCurrentThdf.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgCurrentThdf) && isNotNull(cfgCurrentThdf.relayChannelLabel) && isNotNull(cfgCurrentThdf.relayChannelLabel.ch2)) ? {
														value: "cfgRelayCurrentThdf2RecOpt",
														label: cfgCurrentThdf.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgCurrentThdf) && isNotNull(cfgCurrentThdf.relayChannelLabel) && isNotNull(cfgCurrentThdf.relayChannelLabel.ch3)) ? {
														value: "cfgRelayCurrentThdf3RecOpt",
														label: cfgCurrentThdf.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgCurrentThdf) && isNotNull(cfgCurrentThdf.relayChannelLabel) && isNotNull(cfgCurrentThdf.relayChannelLabel.ch4)) ? {
														value: "cfgRelayCurrentThdf4RecOpt",
														label: cfgCurrentThdf.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgCurrentThdfChannels }
												reducerFormItem={ reducerForm.cfgCurrentThdfChannels }
												handleChange={ value => handleChange("cfgCurrentThdfChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgCurrentThdfActions }
												reducerFormItem={ reducerForm.cfgCurrentThdfActions }
												options={ cfgCurrentThdf?.cfgRelayCurrentThdf1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgCurrentThdfChannels.value) }
												handleChange={ value => handleChange("cfgCurrentThdfActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(CurrentThdfCard);
