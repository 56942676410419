import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { Button, Card } from "flowbite-react";
import { useTranslation } from "react-i18next";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type CurrentTidrForm = {
	cfgCurrentTidrRecOpt: number
}

const validator: FormValidator<CurrentTidrForm> = {
	cfgCurrentTidrRecOpt: () => null,
};

const ID = "current-tidr";

function CurrentTidrCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgCurrentTidr,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: CurrentTidrForm) => {
		reducerHandleChange("cfgCurrentTidrRecOpt", values.cfgCurrentTidrRecOpt);
	};

	const _getInitialState = () => ({
		cfgCurrentTidrRecOpt: createFormField((reducerForm.cfgCurrentTidrRecOpt.value !== reducerForm.cfgCurrentTidrRecOpt.initialValue) ? reducerForm.cfgCurrentTidrRecOpt.value : cfgCurrentTidr?.cfgCurrentTidrRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgCurrentTidr ]);

	useEffect(() => {
		toggleDisable("cfgCurrentTidrRecOpt", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgCurrentTidrRecOpt", false);

		handleChange("cfgCurrentTidrRecOpt", reducerForm.cfgCurrentTidrRecOpt.value);

		toggleDisable("cfgCurrentTidrRecOpt", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgCurrentTidrRecOpt.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				<Translate i18nKey="MEASUREMENTS HARMONICS.current TIDR"/>
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgCurrentTidr?.cfgCurrentTidrRecOpt?.options ?? [] }
					formItem={ form.cfgCurrentTidrRecOpt }
					handleChange={ value => handleChange("cfgCurrentTidrRecOpt", value) }
					reducerFormItem={ reducerForm.cfgCurrentTidrRecOpt }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(CurrentTidrCard);
