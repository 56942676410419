import MUIDataTable, { Display, MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState, MUISortOptions } from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyString, isNotNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import classNames from "classnames";
import { GridLoader } from "react-spinners";
import { DataTableAction, TableURLParamsKey } from "src/app/types/ui/table.types";
import { getFiltersFromUrl } from "src/app/utils/helpers";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		title: string | JSX.Element
		data: any[]
		className?: string
		columns: MUIDataTableColumn[]
		options?: MUIDataTableOptions
		filters?: string[][] // [ColumnIndex][FilterValueIndex]
		cellHeight?: number
		wrapperHeight?: number
		mobileOverflow?: CSSProperties["overflow"]
		isLoading?: boolean
		usePersist?: boolean
		tablePersistPrefix?: string
		cellPadding?: number
		isEventsTable?: boolean
	};

type DisplayedColumnsState = {
	name: string
	isDisplayed: Display
}

export const APP_HEADER_HEIGHT = 67;
export const TABLE_TITLE_HEIGHT = 64;
export const TABLE_THEAD_HEIGHT = 69;
export const TABLE_FOOTER_HEIGHT = 53;
export const CONTENT_PADDING = 40;

export const TABLE_DEFAULT_CELL_HEIGHT = 60;

// Standard row height - 60
// With avatars - 69

const _getTheme = (
	mobileOverflow: CSSProperties["overflow"],
	cellPadding: number,
	isLoading: boolean,
	isDarkTheme: boolean,
	isEventsTable: boolean,
) => createTheme({
	palette: {
		type: isDarkTheme ? "dark" : "light",
		background: {
			paper: isDarkTheme ? "#1f2937" : "#ffffff",
			default: isDarkTheme ? "#1f2937" : "#ffffff",
		},
		primary: {
			"50": "#FFFFFF",
			"100": "#F0F9FD",
			"200": "#E0F2FB",
			"300": "#C0E4F8",
			"400": "#0093DD",
			"500": "#0093DD",
			"600": "#193E50",
			"700": "#1D3039",
			"800": "#1D3039",
			"900": "#1D3039",
		},
		divider: isDarkTheme ? "#accdfe" : "#e5e7eb",
	},
	typography: {
		fontFamily: "Inter, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: "14px",
				lineHeight: 1.4,
				whiteSpace: "normal",
			},
		},
		MuiPopover: {
			root: {
				// @ts-ignore
				zIndex: "30001 !important",
			},
		},

		// Table
		MUIDataTable: {
			paper: {
				boxShadow: "none",
				border: isDarkTheme ? "1px solid #374151" : "1px solid #e5e7eb",
				borderRadius: "8px !important",
				overflow: "hidden",
				"& > div:nth-child(3)": {
					overflow: mobileOverflow,
					"@media (min-width: 1200px)": {
						overflow: "visible",
					},
					"@media (min-width: 600px)": {
						overflow: mobileOverflow,
					},
				},
			},
		},
		MUIDataTableResize: {
			resizer: {
				display: "none",
			},
		},
		MUIDataTableSearch: {
			searchIcon: {
				display: "none",
			},
			searchText: {
				display: "flex",
				flexDirection: "row",
				flex: "0.6 0",
				"@media (max-width: 600px)": {
					flexGrow: "1",
				},
				"& .MuiInput-underline": {
					"&:before": {
						display: "none",
					},
					"&:after": {
						display: "none",
					},
				},
				"& input": {
					border: "1px solid #d1d5db",
					borderRadius: "0.5rem",
					padding: "13px",
					transition: "border 0.2s ease-in",
					"&:focus": {
						border: "1px solid #3F83F8",
						boxShadow: "0 0 0 0px #fff, 0 0 0 calc(1px + 0px ) rgb(6 182 212 / 1 ), 0 0 #0000",
					},
				},
			},
			clearIcon: {
				marginLeft: "12px",
				"&:hover": {
					color: "#E7492E",
				},
			},
		},

		// Toolbar
		MUIDataTableToolbar: {
			actions: {
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				"@media (max-width: 600px)": {
					justifyContent: "center",
					flexWrap: "wrap",
				},
			},
		},

		// Toolbar filters
		MUIDataTableFilter: {
			header: {
				marginBottom: "4px",
			},
			root: {
				minWidth: "200px",
				padding: "8px",
				"@media (max-width: 576px)": {
					width: "250px",
				},
				"& .MuiInputLabel-root": {
					whiteSpace: "nowrap",
				},
				"& .MuiSelect-selectMenu": {
					backgroundColor: "transparent",
					padding: "6px 24px 7px 6px",
					border: isDarkTheme ? "1px solid #374151" : "1px solid #e5e7eb",
					borderRadius: "4px",
					marginTop: "4px",
				},
				"& .MuiInput-underline:after": {
					borderBottom: "none !important",
				},
				"& .MuiInput-underline:before": {
					borderBottom: "none !important",
				},
				"& > .MuiGrid-root": {
					margin: "0",
					width: "100%",
					flexDirection: "column",
					"& > div": {
						marginTop: "0",
						padding: "8px",
						flexBasis: "100%",
						maxWidth: "100%",
						width: "100%",
					},
				},
			},
		},
		MUIDataTableFilterList: {
			root: {
				"@media (max-width: 576px)": {
					marginBottom: "1rem",
				},
			},
		},

		// Table row
		MUIDataTableBodyRow: {
			root: {
				cursor: "pointer",
				"&:hover": {
					backgroundColor: isDarkTheme ? "#374151 !important" : "#f3f4f6 !important",
				},
				"@media (max-width: 600px)": {
					"&:not(:first-child)": {
						borderTop: "solid 4px rgba(0, 0, 0, 0.15) !important",
					},
					borderBottom: "solid 4px rgba(0, 0, 0, 0.15) !important",
					"&:hover": {
						backgroundColor: "#F1F1F1 !important",
					},
					"& .MuiTableCell-root > div:nth-child(2)": {
						marginTop: "10px",
					},
				},
			},
		},
		MUIDataTableHeadCell: {
			root: {
				borderBottom: isLoading ? "1px solid transparent" : (isDarkTheme ? "1px solid #374151" : "1px solid #e5e7eb"),
				...(
					isEventsTable ? {
						padding: "4px !important",
						"& > div": {
							padding: "6px 8px",
						},
					} : {}
				),
			},
		},
		MUIDataTableBodyCell: {
			root: {
				padding: `${ cellPadding }px !important`,
				"& > div": {
					padding: "0 !important",
				},
				"td&": {
					borderBottom: isLoading ? "1px solid transparent" : (isDarkTheme ? "1px solid #374151" : "1px solid #e5e7eb"),
				},
			},
		},
		MUIDataTableSelectCell: {
			root: {
				"td&, th&": {
					borderBottom: isLoading ? "1px solid transparent" : (isDarkTheme ? "1px solid #374151" : "1px solid #e5e7eb"),
				},
			},
			headerCell: {
				zIndex: 40,
			},
			fixedLeft: {
				zIndex: 40,
			},
		},

		// MuiTableBody: {
		// root: isLoading ? {
		// 	position: "relative",
		// 	"&:after": {
		// 		position: "absolute",
		// content: "\"\"", //solution: content is provided based on loading, because 2 contexts in one view are crushing
		// width: "100%",
		// height: "100%",
		// backgroundColor: "rgba(0, 0, 0, 0.01)",
		// backdropFilter: "blur(2px)",
		// top: 0,
		// left: 0,
		// },
		// } : {},
		// },

		// Pagination
		MUIDataTablePagination: {
			tableCellContainer: {
				borderBottom: "none !important",
			},
		},
		MuiTablePagination: {
			root: {
				overflow: "hidden",
				"@media (max-width: 992px)": {
					margin: "0 auto",
				},
			},
			toolbar: {
				padding: "0px",
				flexWrap: "wrap",
				justifyContent: "center",
				alignContent: "center",
			},
			caption: {
				fontSize: "14px",
			},
		},
	},
});

function Table(props: Props) {

	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(location.search);

	const tableRef = useRef<HTMLDivElement>(null);

	const {
		data,
		columns,
		className = "",
		options,
		title,
		cellHeight = TABLE_DEFAULT_CELL_HEIGHT,
		wrapperHeight,
		mobileOverflow = "auto",
		usePersist = false,
		tablePersistPrefix = "table",
		isLoading = false,
		bodySize,
		isDarkTheme,
		filters = [],
		cellPadding = 8,
		isEventsTable = false,
	} = props;

	const pageIndexKey = `${ tablePersistPrefix }${ TableURLParamsKey.PAGE_INDEX }`;
	const pageSizeKey = `${ tablePersistPrefix }${ TableURLParamsKey.PAGE_SIZE }`;
	const searchKey = `${ tablePersistPrefix }${ TableURLParamsKey.SEARCH }`;
	const sortNameKey = `${ tablePersistPrefix }${ TableURLParamsKey.SORT_NAME }`;
	const sortDirectionKey = `${ tablePersistPrefix }${ TableURLParamsKey.SORT_DIRECTION }`;
	const filtersKey = `${ tablePersistPrefix }${ TableURLParamsKey.FILTER }`;

	const persistedPageIndex = urlParams.get(pageIndexKey);
	const persistedPageSize = urlParams.get(pageSizeKey);
	const persistedSearch = urlParams.get(searchKey);
	const persistedSortName = urlParams.get(sortNameKey);
	const persistedSortDirection = urlParams.get(sortDirectionKey);
	const persistedFilters = urlParams.getAll(filtersKey);

	const [ rowsPerPage, setRowsPerPage ] = useState<MUIDataTableOptions["rowsPerPage"]>(1);
	const [ fittableRowsPerPage, setFittableRowsPerPage ] = useState<MUIDataTableOptions["rowsPerPage"]>(1);
	const [ displayedColumns, setDisplayedColumns ] = useState<DisplayedColumnsState[]>([]);

	useEffect(() => {
		setDisplayedColumns(columns.map(column =>
			({
				name: column.name,
				isDisplayed: column.options?.display ?? true,
			}),
		));
	}, [ columns.reduce<number>((prev, next) => prev + +Boolean(next.options?.display ?? 0), 0) ]);

	useEffect(() => {
		if (!tableRef.current) return;

		const newRowsPerPage = Math.floor(((wrapperHeight ?? (bodySize.height - APP_HEADER_HEIGHT)) - TABLE_TITLE_HEIGHT - TABLE_THEAD_HEIGHT - TABLE_FOOTER_HEIGHT - CONTENT_PADDING) / cellHeight);
		if (newRowsPerPage > 0) {
			if (rowsPerPage === fittableRowsPerPage) {
				setRowsPerPage(Math.min(newRowsPerPage, data.length));
			}
			setFittableRowsPerPage(Math.min(newRowsPerPage, data.length));
		}
	}, [ wrapperHeight, data.length, bodySize.height ]);

	const theme = useMemo(() =>
			_getTheme(mobileOverflow, cellPadding, isLoading, isDarkTheme, isEventsTable),
		[ mobileOverflow, cellPadding, isLoading, isDarkTheme, isEventsTable ],
	);

	const _navigate = (tableState: MUIDataTableState) => {
		if (!usePersist) return;

		const pageIndex = tableState.page;
		const pageSize = tableState.rowsPerPage;
		const search = tableState.searchText;
		const sort = tableState.sortOrder;
		const filters = tableState.filterList;

		urlParams.delete(searchKey);
		urlParams.delete(sortNameKey);
		urlParams.delete(sortDirectionKey);
		urlParams.delete(filtersKey);

		urlParams.set(pageIndexKey, pageIndex.toString());
		urlParams.set(pageSizeKey, pageSize.toString());

		if (isNotNull(search) && !isEmptyString(search)) {
			urlParams.set(searchKey, search);
		}

		if (isNotNull(sort.name) && isNotNull(sort.direction) && (sort.direction === "asc" || sort.direction === "desc")) {
			urlParams.set(sortNameKey, sort.name!);
			urlParams.set(sortDirectionKey, sort.direction!);
		}

		filters.forEach((filter, filterIndex) => {
			if (filter.length > 0) {
				filter.forEach(singleFilter => {
					urlParams.append(filtersKey, `${ filterIndex }${ TableURLParamsKey.FILTER_SEPARATOR }${ singleFilter }`);
				});
			}
		});

		navigate({
			search: urlParams.toString(),
		}, {
			replace: true,
		});
	};

	const defaultOptions: MUIDataTableOptions = {
		filterType: "dropdown",
		responsive: "simple",
		fixedHeader: false,
		resizableColumns: true,
		print: false,
		rowsPerPage: isNotNull(persistedPageSize) ? +persistedPageSize : rowsPerPage,
		onChangeRowsPerPage: setRowsPerPage,
		rowsPerPageOptions: fittableRowsPerPage ? Array.from(new Set([ 10, 25, 50, fittableRowsPerPage ])).sort((a, b) => a - b) : undefined,
		selectableRows: "none",
		page: isNotNull(persistedPageIndex) ? +persistedPageIndex : undefined,
		searchText: persistedSearch ?? undefined,
		sortOrder:
			(isNotNull(persistedSortName) && isNotNull(persistedSortDirection))
				?
				{
					name: persistedSortName,
					direction: persistedSortDirection as MUISortOptions["direction"],
				}
				:
				undefined,
		onViewColumnsChange: (changedColumn, action) => {
			setDisplayedColumns(prevState =>
				prevState.map(column => {
					if (column.name !== changedColumn) return column;

					return {
						...column,
						isDisplayed: action === "add",
					};
				}),
			);
		},
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			switch (action as DataTableAction) {
				case DataTableAction.PAGE_CHANGE:
				case DataTableAction.FILTER_CHANGE:
				case DataTableAction.RESET_FILTERS:
				case DataTableAction.SORT:
				case DataTableAction.SEARCH:
				case DataTableAction.CHANGE_ROWS_PER_PAGE:
					if (
						(persistedPageIndex !== tableState.page.toString()) ||
						(persistedPageSize !== tableState.rowsPerPage.toString()) ||
						(persistedSortName !== tableState.sortOrder.name || persistedSortDirection !== tableState.sortOrder.direction) ||
						(persistedSearch !== tableState.searchText) ||
						(getFiltersFromUrl(persistedFilters).some((filter, index) => tableState.filterList[ index ].length !== filter.length)) // fixme: maybe better check for diff between filters
					) {
						_navigate(tableState);
					}
					break;
			}
		},
		searchPlaceholder: t("TABLE.search"),
		disableToolbarSelect: true,
		download: false,
		textLabels: {
			filter: {
				title: t("TABLE.filters"),
				all: t("TABLE.all"),
			},
			viewColumns: {
				title: t("TABLE.show columns"),
			},
			body: {
				noMatch: t("TABLE.no match"),
				toolTip: t("TABLE.tooltip"),
			},
			pagination: {
				rowsPerPage: t("TABLE.rows per page"),
				displayRows: t("TABLE.display rows"),
				next: t("TABLE.next"),
				previous: t("TABLE.previous"),
			},
			toolbar: {
				filterTable: t("TABLE.filter"),
				viewColumns: t("TABLE.view columns"),
				search: t("TABLE.search"),
			},
		},
	};

	const _getData = (data: any[]) => data.reduce((prev, next) => {
		const row = new Array(columns.length).fill(next);
		prev.push(row);
		return prev;
	}, []);

	const _setupColumns = (columns: MUIDataTableColumn[]) =>
		columns
			// Add sortThirdClickReset to all sortable columns
			.map(column => {
				if (!column.options?.sort) return column;

				return {
					...column,
					options: {
						...column.options,
						sortThirdClickReset: true,
					},
				};
			})
			// Add filterList if table is paginated
			.map((column, index) => {
				if (!options?.serverSide && !usePersist) return column;

				return {
					...column,
					options: {
						...column.options,
						filterList: column.options?.filterList ?? filters[ index ] ?? getFiltersFromUrl(persistedFilters)[ index ],
					},
				};
			})
			.map(column => {
				return {
					...column,
					options: {
						...column.options,
						display: displayedColumns.find(col => col.name === column.name)?.isDisplayed,
					},
				};
			});

	return (
		<div
			className={
				classNames(
					className,
					"shadow rounded-lg bg-transparent relative",
					{ "after:[&_tbody.MuiTableBody-root]:content-['']": isLoading },
				)
			}
			ref={ tableRef }
		>
			<MuiThemeProvider theme={ theme }>
				<MUIDataTable
					title={ title }
					data={
						_getData(
							isLoading ?
								new Array(10)
									.fill(1)
								:
								data,
						)
					}
					columns={
						isLoading ?
							[ {
								name: "",
								options: {
									customBodyRender: (parameter: number) => <div className="opacity-0">{ parameter }</div>,
									sort: false,
									filter: false,
								},
							} ]
							:
							_setupColumns(columns)
					}
					options={ { ...defaultOptions, ...options } }
				/>
			</MuiThemeProvider>
			{
				isLoading &&
                <div className="absolute inset-0 flex justify-center items-center">
                    <GridLoader size={ 15 } color="#0093DD"/>
                </div>
			}
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
	isDarkTheme: state.ui.layout.isDarkTheme,
});

export default connect(mapStateToProps)(Table);
