import { PropsWithChildren, useEffect } from "react";
import LoggedUserProvider from "src/app/hoc/providers/LoggedUser.provider";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { GridLoader } from "react-spinners";
import AppVersion from "src/app/components/Utils/AppVersion.component";
import ImpersonateUser from "src/app/components/Utils/ImpersonateUser.component";
import classNames from "classnames";
import Navbar from "src/app/components/Layout/Navbar/Navbar.component";
import { Outlet } from "react-router-dom";
import PrivateErrorBoundary from "src/app/hoc/boundaries/PrivateError.boundary";
import { isDashboardNoSidebarLoading } from "src/app/store/features/misc/misc.selectors";
import { uiFetchSettings } from "src/app/store/features/ui/settings/ui.settings.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function DashboardNoSidebarLayout(props: PropsWithChildren<Props>) {

	useEffect(() => {
		return () => {
			props.fetchSettings();
		};
	}, []);

	return (
		<div className="h-screen">
			{
				props.isAppLoading
					?
					<div className="h-full flex items-center justify-center">
						<GridLoader size={ 20 } color="#0093DD"/>
					</div>
					:
					<div className="h-full">
						<LoggedUserProvider>
							<ImpersonateUser/>
							<Navbar
								withSidebar={ false }
							/>
							<div className="h-full flex items-start pt-16">
								<main
									className={
										classNames(
											"overflow-y-auto relative w-full h-full bg-gray-100 dark:bg-gray-900 p-5",
										)
									}
								>
									<PrivateErrorBoundary>
										<Outlet/>
									</PrivateErrorBoundary>
								</main>
							</div>
						</LoggedUserProvider>
					</div>
			}
			<AppVersion/>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAppLoading: isDashboardNoSidebarLoading(state),
});

const mapDispatchToProps = {
	fetchSettings: uiFetchSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNoSidebarLayout);
