import { Dropdown } from "flowbite-react/lib/esm/components/Dropdown";
import FlagIcon from "src/app/components/Utils/FlagIcon.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { changeLanguage } from "src/app/store/features/misc/misc.actions";
import { LanguageCode, languageToCountryDictionary } from "src/app/translations/utils";
import ISO639 from "iso-639-1";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function LanguageDropdown(props: Props) {

	const {
		language,
		changeLanguage,
	} = props;

	return (
		<Dropdown
			arrowIcon={ false }
			inline
			label={
				<span className="rounded-lg px-2 py-1 hover:bg-gray-100 dark:hover:bg-dark-hoverBg relative group min-w-[100px] h-8 flex items-center justify-center gap-2">
					<FlagIcon countryCode={ languageToCountryDictionary()[ language ] }/>
					<span className="font-medium">{ ISO639.getNativeName(language?.toLowerCase() ?? "") }</span>
				</span>
			}
			className="!min-w-[unset] overflow-hidden"
			color="outline"
			placement="bottom"
		>
			{
				Object.values(LanguageCode).map(languageCode =>
					<Dropdown.Item
						key={ languageCode }
						onClick={ () => changeLanguage(languageCode) }
						className="flex gap-2"
					>
						<FlagIcon countryCode={ languageToCountryDictionary()[ languageCode ] }/>
						<span className="font-medium">{ ISO639.getNativeName(languageCode?.toLowerCase() ?? "") }</span>
					</Dropdown.Item>,
				)
			}
		</Dropdown>
	);
}

const mapStateToProps = (state: RootState) => ({
	language: state.misc.language,
});

const mapDispatchToProps = {
	changeLanguage: changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);