import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isChannelsDiff, mapVoltageThdfConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type VoltageThdfForm = {
	cfgVoltageThdfRecOpt: number
	cfgVoltageThdfRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgVoltageThdfThresholdMax: string
	cfgVoltageThdfThresholdMaxMin: number
	cfgVoltageThdfThresholdMaxMax: number
	cfgVoltageThdfChannels: ("cfgRelayVoltageThdf1RecOpt" | "cfgRelayVoltageThdf2RecOpt" | "cfgRelayVoltageThdf3RecOpt" | "cfgRelayVoltageThdf4RecOpt")[]
	cfgVoltageThdfActions: number
}

const validator: FormValidator<VoltageThdfForm> = {
	cfgVoltageThdfRecOpt: () => null,
	cfgVoltageThdfRecOptOptions: () => null,
	cfgVoltageThdfThresholdMax: (cfgVoltageThdfThresholdMax, optional, form) => validateNumberField(i18n.t("ENERGY.max"), cfgVoltageThdfThresholdMax, optional, "he", { from: form.cfgVoltageThdfThresholdMaxMin.value, to: form.cfgVoltageThdfThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgVoltageThdfThresholdMaxMin: () => null,
	cfgVoltageThdfThresholdMaxMax: () => null,
	cfgVoltageThdfChannels: () => null,
	cfgVoltageThdfActions: () => null,
};

const ID = "voltage-thdf";

function VoltageThdfCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgVoltageThdf,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: VoltageThdfForm) => {
		reducerHandleChange("cfgVoltageThdfRecOpt", values.cfgVoltageThdfRecOpt);
		reducerHandleChange("cfgVoltageThdfThresholdMax", values.cfgVoltageThdfThresholdMax);
		reducerHandleChange("cfgVoltageThdfChannels", values.cfgVoltageThdfChannels);
		reducerHandleChange("cfgVoltageThdfActions", values.cfgVoltageThdfActions);
	};

	const _getInitialState = () => {
		const [ cfgVoltageThdfChannels, cfgVoltageThdfActions ] = mapVoltageThdfConfig(cfgVoltageThdf);

		return {
			cfgVoltageThdfRecOpt: createFormField((reducerForm.cfgVoltageThdfRecOpt.value !== reducerForm.cfgVoltageThdfRecOpt.initialValue) ? reducerForm.cfgVoltageThdfRecOpt.value : cfgVoltageThdf?.cfgVoltageThdfRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageThdfRecOptOptions: createFormField(cfgVoltageThdf?.cfgVoltageThdfRecOpt?.options ?? []),
			cfgVoltageThdfThresholdMax: createFormField((reducerForm.cfgVoltageThdfThresholdMax.value !== reducerForm.cfgVoltageThdfThresholdMax.initialValue) ? reducerForm.cfgVoltageThdfThresholdMax.value : cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageThdfThresholdMaxMin: createFormField(cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageThdfThresholdMaxMax: createFormField(cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageThdfChannels: createFormField(isChannelsDiff(reducerForm.cfgVoltageThdfChannels) ? reducerForm.cfgVoltageThdfChannels.value : cfgVoltageThdfChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageThdfActions: createFormField((reducerForm.cfgVoltageThdfActions.value !== reducerForm.cfgVoltageThdfActions.initialValue) ? reducerForm.cfgVoltageThdfActions.value : cfgVoltageThdfActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgVoltageThdf ]);

	useEffect(() => {
		toggleDisable("cfgVoltageThdfRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgVoltageThdfRecOpt", false);
		toggleDisable("cfgVoltageThdfThresholdMax", false);
		toggleDisable("cfgVoltageThdfChannels", false);
		toggleDisable("cfgVoltageThdfActions", false);

		handleChange("cfgVoltageThdfRecOpt", reducerForm.cfgVoltageThdfRecOpt.value);
		handleChange("cfgVoltageThdfThresholdMax", reducerForm.cfgVoltageThdfThresholdMax.value);
		handleChange("cfgVoltageThdfChannels", reducerForm.cfgVoltageThdfChannels.value);
		handleChange("cfgVoltageThdfActions", reducerForm.cfgVoltageThdfActions.value);

		toggleDisable("cfgVoltageThdfRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageThdfActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgVoltageThdfRecOpt.value,
		reducerForm.cfgVoltageThdfThresholdMax.value,
		reducerForm.cfgVoltageThdfChannels.value,
		reducerForm.cfgVoltageThdfActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDF"/>
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgVoltageThdf?.cfgVoltageThdfRecOpt?.options ?? [] }
					formItem={ form.cfgVoltageThdfRecOpt }
					handleChange={ value => handleChange("cfgVoltageThdfRecOpt", value) }
					reducerFormItem={ reducerForm.cfgVoltageThdfRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgVoltageThdf?.cfgVoltageThdfRecOpt?.options ?? [] }
						formItem={ form.cfgVoltageThdfRecOpt }
						handleChange={ value => handleChange("cfgVoltageThdfRecOpt", value) }
						reducerFormItem={ reducerForm.cfgVoltageThdfRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("MEASUREMENTS.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">

										<Input
											className="flex-1"
											formItem={ form.cfgVoltageThdfThresholdMax }
											label={ t("MEASUREMENTS.max") }
											name="cfgVoltageThdfThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgVoltageThdfThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgVoltageThdfThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgVoltageThdfThresholdMax.value !== reducerForm.cfgVoltageThdfThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgVoltageThdf) && isNotNull(cfgVoltageThdf.relayChannelLabel) && isNotNull(cfgVoltageThdf.relayChannelLabel.ch1)) ? {
														value: "cfgRelayVoltageThdf1RecOpt",
														label: cfgVoltageThdf.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgVoltageThdf) && isNotNull(cfgVoltageThdf.relayChannelLabel) && isNotNull(cfgVoltageThdf.relayChannelLabel.ch2)) ? {
														value: "cfgRelayVoltageThdf2RecOpt",
														label: cfgVoltageThdf.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgVoltageThdf) && isNotNull(cfgVoltageThdf.relayChannelLabel) && isNotNull(cfgVoltageThdf.relayChannelLabel.ch3)) ? {
														value: "cfgRelayVoltageThdf3RecOpt",
														label: cfgVoltageThdf.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgVoltageThdf) && isNotNull(cfgVoltageThdf.relayChannelLabel) && isNotNull(cfgVoltageThdf.relayChannelLabel.ch4)) ? {
														value: "cfgRelayVoltageThdf4RecOpt",
														label: cfgVoltageThdf.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgVoltageThdfChannels }
												reducerFormItem={ reducerForm.cfgVoltageThdfChannels }
												handleChange={ value => handleChange("cfgVoltageThdfChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgVoltageThdfActions }
												reducerFormItem={ reducerForm.cfgVoltageThdfActions }
												options={ cfgVoltageThdf?.cfgRelayVoltageThdf1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgVoltageThdfChannels.value) }
												handleChange={ value => handleChange("cfgVoltageThdfActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(VoltageThdfCard);
