import { Button, Card } from "flowbite-react";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { HiMiniChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { Summary } from "src/app/types/api/dashboard.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { eventOriginNodeDictionary, eventTypeDictionary, nominalFrequencyDictionary, systemTypeDictionary } from "src/app/utils/constants/dictionaries";
import { TimeplotChartData } from "src/app/components/Chart/Timeplot/TimeplotChart.component";
import LineChart from "src/app/components/Chart/LineChart.component";
import EditLocalizationOrUserInfoModal from "src/app/components/Summary/EditLocalizationOrUserInfoModal.component";
import { appColors } from "src/app/utils/constants/theme";
import { formatValueToDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import { formatDuration } from "src/app/utils/helpers";
import { getLastFiveRoutes } from "src/app/store/features/ui/router/ui.router.selectors";
import moment from "moment";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { IoMdRefresh } from "react-icons/io";
import { MeasurementCategory } from "src/app/types/misc.types";
import { OnOff } from "src/app/types/api/settings.types";
import { EventOrigin, EventType } from "src/app/types/api/event.types";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		timeplotData: TimeplotChartData[]
		summary: Summary["summary"]
		recordingDuration: number
		onRefetchSummary: () => void
	};

function SummaryContainer(props: Props) {

	const {
		summary,
		timeplotData,
		onRefetchSummary,
		lastRoutes,
		bodySize,
		isSidebarOpen,
		isRefetching,
		isAdmin,
	} = props;

	const cardRef = useRef<HTMLDivElement>(null);
	const [ cardWidth, setCardWidth ] = useState(0);
	const [ editModalOpen, toggleEditModal ] = useState(false);

	useEffect(() => {
		if (isNotNull(cardRef.current)) {
			setCardWidth(cardRef.current.getBoundingClientRect().width);
		}
	}, [ bodySize.width, isSidebarOpen ]);

	const _getSystemType = () => {
		const systemType = summary?.cfgSystemType?.enum?.find(enumObj => enumObj.value === summary?.cfgSystemType?.value);
		if (isNull(systemType)) return "---";

		return systemTypeDictionary()[ systemType.text ] ?? "---";
	};

	const _getNominalFrequency = () => {
		const nominalFrequency = summary?.cfgNominalFrequency?.enum?.find(enumObj => enumObj.value === summary?.cfgNominalFrequency?.value);
		if (isNull(nominalFrequency)) return "---";

		return `${ nominalFrequencyDictionary()[ nominalFrequency.text ] } ${ summary?.cfgNominalFrequency?.unit ?? "" }`;
	};

	const _getRecordingStatus = () => {
		const recordingStatus = summary?.recordingStatus?.enum?.find(enumObj => enumObj.value === summary?.recordingStatus?.value);
		if (isNull(recordingStatus)) return "---";

		return recordingStatus.text === OnOff.ON ? <Translate i18nKey="SUMMARY.recording status on"/> : <Translate i18nKey="SUMMARY.recording status off"/>;
	};

	const latestMeasure = timeplotData[ timeplotData.length - 1 ];

	const _translateEventType = (eventType: number, eventOrigin: number) => {
		const type = summary?.events?.types?.find(type => type.value === eventType);
		const origin = summary?.events?.origins?.find(origin => origin.value === eventOrigin);
		if (isNull(type) || isNull(origin)) return <Translate i18nKey="EVENTS.unknown"/>;

		if (type.text === EventType.EVENT_UNSPEC) {
			return eventOriginNodeDictionary()[ origin.text ];
		}

		return <span>{ eventOriginNodeDictionary()[ origin.text ] }{ ` - ${ eventTypeDictionary()[ type.text ] }` }</span>;
	};

	const _getEventOrigin = (eventOrigin: number) => {
		const origin = summary?.events?.origins?.find(origin => origin.value === eventOrigin);
		return origin?.text ?? null;
	};

	const isU1n = timeplotData.some(measure => isNotNull(measure.U1n_rms));
	const isU12 = timeplotData.some(measure => isNotNull(measure.U12_rms));
	const isU2n = timeplotData.some(measure => isNotNull(measure.U2n_rms));
	const isU23 = timeplotData.some(measure => isNotNull(measure.U23_rms));
	const isU3n = timeplotData.some(measure => isNotNull(measure.U3n_rms));
	const isU31 = timeplotData.some(measure => isNotNull(measure.U31_rms));
	const isUne = timeplotData.some(measure => isNotNull(measure.Une_rms));
	const isI1 = timeplotData.some(measure => isNotNull(measure.I1_rms));
	const isI2 = timeplotData.some(measure => isNotNull(measure.I2_rms));
	const isI3 = timeplotData.some(measure => isNotNull(measure.I3_rms));
	const isIn = timeplotData.some(measure => isNotNull(measure.In_rms));
	const isIe = timeplotData.some(measure => isNotNull(measure.Ie_rms));

	return (
		<>
			<div
				className={
					classNames(
						"grid gap-4",
						"2xl:grid-cols-10",
						"xl:grid-cols-3",
						"sm:grid-cols-2",
						"grid-cols-1",
					)
				}
			>
				<div
					className={
						classNames(
							"flex flex-col gap-4 order-1",
							"2xl:col-span-3",
							"col-span-1",
						)
					}
				>
					<Card
						className={
							classNames(
								"sm:[&>div]:p-6 sm:[&>div]:gap-4",
								"[&>div]:p-3 [&>div]:gap-1",
							)
						}
					>
						<div className="flex justify-between items-center gap-2">
							<h5
								className={
									classNames(
										"font-bold tracking-tight text-gray-900 dark:text-white leading-none",
										"sm:text-2xl",
										"text-lg",
									)
								}
							>
								<Translate i18nKey="SUMMARY.device info"/>
							</h5>
							{
								isAdmin &&
                                <div className="flex">
                                    <Button
                                        color="transparent"
                                        onClick={ onRefetchSummary }
                                        isProcessing={ isRefetching }
                                        className={
											classNames(
												"p-0 [&>span]:p-2",
												{ "[&>span>span]:left-[6px]": isRefetching },
											)
										}
                                    >
                                        <IoMdRefresh
                                            className={
												classNames(
													"h-5 w-5 text-[#009FE3] dark:text-dark-textGray",
													{ "opacity-0": isRefetching },
												)
											}
                                        />
                                    </Button>
                                    <Button
                                        color="transparent"
                                        onClick={ () => toggleEditModal(true) }
                                        className="p-0 [&>span]:p-2"
                                    >
                                        <HiOutlinePencilAlt
                                            className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
                                        />
                                    </Button>
                                </div>
							}
						</div>
						<div className="flex flex-col gap-1.5">
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.meter model"/> }
								value={ summary?.cfgMeterName?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.serial number"/> }
								value={ summary?.cfgSerialNumber?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.firmware version"/> }
								value={ summary?.cfgFirmwareVersion?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.hardware version"/> }
								value={ summary?.cfgHardwareVersion?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.meter features"/> }
								value={ summary?.cfgMeterFeatures?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.ip address"/> }
								value={ summary?.cfgEthernetIpAddress?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.mac address"/> }
								value={ summary?.cfgEthernetMacAddress?.value ?? "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.internal temperature"/> }
								value={ `${ summary?.internalTemperature?.value ?? "---" } ${ summary?.internalTemperature?.unit ?? "" }` }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.localization"/> }
								value={ isEmptyString(summary?.cfgLocalization?.value ?? "") ? "---" : summary?.cfgLocalization?.value ?? "" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.user info"/> }
								value={ isEmptyString(summary?.cfgMeterUserInfo?.value ?? "") ? "---" : summary?.cfgMeterUserInfo?.value ?? "" }
							/>
						</div>
					</Card>
					<Card
						className={
							classNames(
								"sm:[&>div]:p-6",
								"[&>div]:p-3 [&>div]:gap-4",
							)
						}
					>
						<h5
							className={
								classNames(
									"font-bold tracking-tight text-gray-900 dark:text-white leading-none",
									"sm:text-2xl",
									"text-lg",
								)
							}
						>
							<Translate i18nKey="SUMMARY.recording info"/>
						</h5>
						<div className="flex flex-col gap-1.5">
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.recording status"/> }
								value={ _getRecordingStatus() }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.recording start timestamp"/> }
								value={ (isNotNull(summary?.recordingStartTimestamp?.value) && summary?.recordingDuration?.value !== 0) ? moment.unix(summary?.recordingStartTimestamp?.value ?? 0).format("YYYY-MM-DD HH:mm:ss") : "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="RECORDINGS.recording duration"/> }
								value={ summary?.recordingDuration?.value !== 0 ? formatDuration(summary?.recordingDuration?.value, summary?.recordingDuration?.unit) : "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.system type"/> }
								value={ _getSystemType() }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.nominal frequency"/> }
								value={ _getNominalFrequency() }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.nominal voltage ln"/> }
								value={ `${ summary?.varNominalVoltageUn?.value ?? "---" } ${ summary?.varNominalVoltageUn?.unit ?? "" }` }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.nominal voltage ll"/> }
								value={ isNotNull(summary?.varNominalVoltageLl) ? `${ summary?.varNominalVoltageLl?.value } ${ summary?.varNominalVoltageLl?.unit }` : "---" }
							/>
							<LabelValue
								label={ <Translate i18nKey="SUMMARY.nominal current"/> }
								value={ `${ summary?.cfgNominalCurrentCh1?.value ?? "---" } ${ summary?.cfgNominalCurrentCh1?.unit ?? "" }` }
							/>
						</div>
					</Card>
				</div>
				<div
					className={
						classNames(
							"flex flex-col gap-4",
							"2xl:col-span-4",
							"xl:order-2",
							"sm:order-3",
							"col-span-1 order-2",
						)
					}
				>
					{
						lastRoutes.length > 0 &&
                        <Card
                            className={
								classNames(
									"sm:[&>div]:p-6 sm:[&>div]:gap-4",
									"[&>div]:p-3 [&>div]:gap-1",
								)
							}
                        >
                            <div className="flex justify-between items-center gap-2">
                                <h5
                                    className={
										classNames(
											"font-bold tracking-tight text-gray-900 dark:text-white leading-none",
											"sm:text-2xl",
											"text-lg",
										)
									}
                                >
                                    <Translate i18nKey="SUMMARY.recent actions"/>
                                </h5>
                            </div>
                            <div
                                className={
									classNames(
										"flex gap-x-2 gap-y-0.5 flex-wrap",
										"sm:flex-row",
										"flex-col",
									)
								}
                            >
								{
									lastRoutes.map(route => {
										const { icon: Icon, name, pathname } = route;
										return (
											<div key={ pathname }>
												<Link to={ pathname }>
													<Button
														color="transparent"
													>
														<Icon className="h-5 w-5 mr-2 text-[#009FE3] dark:text-dark-textGray"/>
														<span className="text-[#009FE3] mr-1 font-semibold">{ name }</span>
													</Button>
												</Link>
											</div>
										);
									})
								}
                            </div>
                        </Card>
					}
					<Card className="[&>div]:px-0 [&>div]:pt-0">
						<div>
							<div className="gap-2">
								<div
									className={
										classNames(
											"flex justify-between items-center",
											"sm:p-6 sm:pb-3",
											"p-3 pb-2",
										)
									}
								>
									<h5
										className={
											classNames(
												"font-bold tracking-tight text-gray-900 dark:text-white leading-none",
												"sm:text-2xl",
												"text-lg",
											)
										}
									>
										<Translate i18nKey="SUMMARY.recent events"/>
									</h5>
								</div>
							</div>
							<div
								className={
									classNames(
										"p-2 flex gap-2 border-y border-gray-200 flex-wrap",
										"sm:px-4",
									)
								}
							>
								<div className="min-w-[100px] flex flex-col items-center bg-gray-200 px-3 py-2 rounded-lg">
									<div className="text-3xl font-semibold">{ summary?.events?.eventsRecordedTotal ?? "0" }</div>
									<div className="text-gray-500 text-sm leading-none uppercase"><Translate i18nKey="EVENTS.all"/></div>
								</div>
								<div className="min-w-[100px] flex flex-col items-center bg-gray-200 px-3 py-2 rounded-lg">
									<div className="text-3xl font-semibold">{ summary?.events?.eventsRecordedDip ?? "0" }</div>
									<div className="text-gray-500 text-sm leading-none uppercase"><Translate i18nKey="EVENTS.dip"/></div>
								</div>
								<div className="min-w-[100px] flex flex-col items-center bg-gray-200 px-3 py-2 rounded-lg">
									<div className="text-3xl font-semibold">{ summary?.events?.eventsRecordedSwell ?? "0" }</div>
									<div className="text-gray-500 text-sm leading-none uppercase"><Translate i18nKey="EVENTS.swell"/></div>
								</div>
								<div className="min-w-[100px] flex flex-col items-center bg-gray-200 px-3 py-2 rounded-lg">
									<div className="text-3xl font-semibold">{ summary?.events?.eventsRecordedInterrupt ?? "0" }</div>
									<div className="text-gray-500 text-sm leading-none uppercase"><Translate i18nKey="EVENTS.interrupt"/></div>
								</div>
								<div className="min-w-[100px] flex flex-col items-center bg-gray-200 px-3 py-2 rounded-lg">
									<div className="text-3xl font-semibold">{ summary?.events?.eventsRecordedOther ?? "0" }</div>
									<div className="text-gray-500 text-sm leading-none uppercase"><Translate i18nKey="EVENTS.other"/></div>
								</div>
							</div>
							<div className="flex flex-col">
								{
									(summary?.events?.event ?? [])
										.filter(event => _getEventOrigin(event.origin) !== EventOrigin.UNKNOWN)
										.map((event, i, arr) =>
											<div
												key={ event.id }
												className={
													classNames(
														"py-2",
														"sm:px-6",
														"px-3",
														{ "border-b border-gray-200": arr.length - 1 !== i },
														{ "bg-gray-100": i % 2 !== 0 },
													)
												}
											>
												<div className="font-semibold">
													{ _translateEventType(event.type, event.origin) }
												</div>
												<div className="text-gray-500 text-md">
													{ `${ isNotNull(event.timestampStart) ? moment(event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") : "---" } (${ isNotNull(event.duration) ? formatDuration(event.duration / 1000, "ms") : "---" })` }
												</div>
											</div>,
										)
								}
							</div>
							<div
								className={
									classNames(
										"pt-2 border-t border-gray-200 flex justify-end gap-2 items-center",
										{ "border-none": summary?.events?.event?.length === 0 },
									)
								}
							>
								<Link to="/standard-events">
									<Button
										color="transparent"
									>
										<span className="text-[#009FE3] mr-1 font-semibold uppercase"><Translate i18nKey="SUMMARY.see all"/>{ ` (${ summary?.events?.eventsRecordedTotal ?? "0" })` }</span>
										<HiMiniChevronRight className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray flex-shrink-0"/>
									</Button>
								</Link>
							</div>
						</div>
					</Card>
				</div>
				<div
					className={
						classNames(
							"flex flex-col gap-4",
							"2xl:col-span-3",
							"xl:order-3",
							"col-span-1 order-2",
						)
					}
				>
					<Card
						className={
							classNames(
								"[&>div]:gap-4",
								"sm:[&>div]:p-6",
								"[&>div]:p-3",
							)
						}
					>
						<h5
							className={
								classNames(
									"font-bold tracking-tight text-gray-900 dark:text-white",
									"sm:text-2xl",
									"text-lg",
								)
							}
						>
							<Translate i18nKey="SUMMARY.real time"/>
						</h5>
						<div className="flex flex-col gap-4" ref={ cardRef }>
							{
								(isU1n && cardWidth > 0) &&
                                <div className="flex items-center gap-1">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U1n_rms?.value,
												}))
											}
                                            color={ appColors.U1 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U1
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U1n_rms) && isNotNull(latestMeasure.U1n_rms?.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U1n_rms?.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U1n_rms?.value, latestMeasure.U1n_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(!isU1n && isU12 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U12_rms?.value,
												}))
											}
                                            color={ appColors.U12 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U12
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U12_rms) && isNotNull(latestMeasure.U12_rms?.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U12_rms?.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U12_rms?.value, latestMeasure.U12_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isU2n && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U2n_rms?.value,
												}))
											}
                                            color={ appColors.U2 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U2
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U2n_rms) && isNotNull(latestMeasure.U2n_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U2n_rms.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U2n_rms.value, latestMeasure.U2n_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(!isU2n && isU23 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U23_rms?.value,
												}))
											}
                                            color={ appColors.U23 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U23
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U23_rms) && isNotNull(latestMeasure.U23_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U23_rms.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U23_rms.value, latestMeasure.U23_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isU3n && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U3n_rms?.value,
												}))
											}
                                            color={ appColors.U3 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U3
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U3n_rms) && isNotNull(latestMeasure.U3n_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U3n_rms?.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U3n_rms?.value, latestMeasure.U3n_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(!isU3n && isU31 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.U31_rms?.value,
												}))
											}
                                            color={ appColors.U31 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            U31
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.U31_rms) && isNotNull(latestMeasure.U31_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.U31_rms?.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.U31_rms?.value, latestMeasure.U31_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isUne && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.Une_rms?.value,
												}))
											}
                                            color={ appColors.Un }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            UE
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.Une_rms) && isNotNull(latestMeasure.Une_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.Une_rms?.value, MeasurementCategory.VOLTAGES) } ${ getPrefixToUnit(latestMeasure.Une_rms?.value, latestMeasure.Une_rms?.unit, MeasurementCategory.VOLTAGES) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isI1 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.I1_rms?.value,
												}))
											}
                                            color={ appColors.I1 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            I1
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.I1_rms) && isNotNull(latestMeasure.I1_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.I1_rms.value, MeasurementCategory.CURRENTS) } ${ getPrefixToUnit(latestMeasure.I1_rms.value, latestMeasure.I1_rms?.unit, MeasurementCategory.CURRENTS) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isI2 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.I2_rms?.value,
												}))
											}
                                            color={ appColors.I2 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            I2
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.I2_rms) && isNotNull(latestMeasure.I2_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.I2_rms.value, MeasurementCategory.CURRENTS) } ${ getPrefixToUnit(latestMeasure.I2_rms.value, latestMeasure.I2_rms?.unit, MeasurementCategory.CURRENTS) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isI3 && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.I3_rms?.value,
												}))
											}
                                            color={ appColors.I3 }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            I3
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.I3_rms) && isNotNull(latestMeasure.I3_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.I3_rms.value, MeasurementCategory.CURRENTS) } ${ getPrefixToUnit(latestMeasure.I3_rms.value, latestMeasure.I3_rms?.unit, MeasurementCategory.CURRENTS) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isIn && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.In_rms?.value,
												}))
											}
                                            color={ appColors.In }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            In
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.In_rms) && isNotNull(latestMeasure.In_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.In_rms.value, MeasurementCategory.CURRENTS) } ${ getPrefixToUnit(latestMeasure.In_rms.value, latestMeasure.In_rms?.unit, MeasurementCategory.CURRENTS) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
							{
								(isIe && cardWidth > 0) &&
                                <div className="flex items-center gap-3">
                                    <div className="shrink-0">
                                        <LineChart
                                            width={ (cardWidth - (4 * 3)) * (2 / 3) }
                                            height={ 100 }
                                            data={
												timeplotData.map(measure => ({
													date: measure.date,
													value: measure.Ie_rms?.value,
												}))
											}
                                            color={ appColors.Ie }
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <div
                                            className={
												classNames(
													"text-gray-500 text-right",
													"sm:text-base",
													"text-sm",
												)
											}
                                        >
                                            Ie
                                        </div>
                                        <div
                                            className={
												classNames(
													"font-semibold text-right whitespace-nowrap",
													"2xl:text-2xl",
													"text-xl",
												)
											}
                                        >
											{
												(isNotNull(latestMeasure) && isNotNull(latestMeasure.Ie_rms) && isNotNull(latestMeasure.Ie_rms.value)) ?
													`${ formatValueToDeciSON(latestMeasure.Ie_rms.value, MeasurementCategory.CURRENTS) } ${ getPrefixToUnit(latestMeasure.Ie_rms.value, latestMeasure.Ie_rms?.unit, MeasurementCategory.CURRENTS) }`
													:
													"---"
											}
                                        </div>
                                    </div>
                                </div>
							}
						</div>
						<div className="pt-2 border-t border-gray-200 flex justify-end gap-2 items-center">
							<Link to="/realtime/timeplot">
								<Button
									color="transparent"
								>
									<span className="text-[#009FE3] mr-1 font-semibold"><Translate i18nKey="SUMMARY.timeplot"/></span>
									<HiMiniChevronRight className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray flex-shrink-0"/>
								</Button>
							</Link>
						</div>
					</Card>
				</div>
			</div>
			<EditLocalizationOrUserInfoModal
				isOpen={ editModalOpen }
				handleClose={ () => toggleEditModal(false) }
				summary={ summary }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
	lastRoutes: getLastFiveRoutes(state),
	isRefetching: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SUMMARY }),
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(SummaryContainer);
