import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiExportSettings, uiFetchMoreStandardEvents, uiFetchMoreUserEvents, uiFetchStandardEventPqdif, uiFetchStandardEventRmsWaveform, uiFetchStandardEvents, uiFetchStandardEventWaveform, uiFetchSummary, uiFetchUserEventPqdif, uiFetchUserEventRmsWaveform, uiFetchUserEvents, uiFetchUserEventWaveform, uiImportSettings } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";
import { filter, map, mergeMap, switchMap, take, throttleTime } from "rxjs/operators";
import { exportSettingsAsync, fetchStandardEventByIdAsync, fetchStandardEventPqdifAsync, fetchStandardEventsAsync, fetchStandardEventsWithOffsetAsync, fetchStandardEventWaveformAsync, fetchStandardRmsAsync, fetchSummaryAsync, fetchUserEventByIdAsync, fetchUserEventPqdifAsync, fetchUserEventsAsync, fetchUserEventsWithOffsetAsync, fetchUserEventWaveformAsync, fetchUserRmsAsync, importSettingsAsync } from "src/app/store/features/dashboard/dashboard.actions";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType } from "src/app/types/ui/loading.types";
import { concat, merge, of } from "rxjs";
import { EVENTS_MAX_PACKAGES, EVENTS_PACKAGE_AMOUNT } from "src/app/store/features/dashboard/dashboard.reducer";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import moment from "moment";
import i18n from "src/app/translations/i18n";

export const uiFetchSummaryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchSummary)),
		mergeMap(() =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.FETCH_SUMMARY })),
				of(fetchSummaryAsync.request()),
				action$.pipe(
					filter(action => isActionOf(fetchSummaryAsync.success, action) || isActionOf(fetchSummaryAsync.failure, action)),
					take(1),
					map(() => removeLoadingRecord({ loadableType: LoadableType.FETCH_SUMMARY })),
				),
			),
		),
	);

export const uiFetchStandardEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchStandardEvents)),
		mergeMap(() =>
			concat(
				of(fetchStandardEventsAsync.request()),
				action$.pipe(
					filter(isActionOf(fetchStandardEventsAsync.success)),
					take(1),
					mergeMap(() => {
						const requests = [];
						for (let i = 1 ; i <= EVENTS_MAX_PACKAGES - 1 ; i++) { // One has been already downloaded
							requests.push(of(fetchStandardEventsWithOffsetAsync.request(i * EVENTS_PACKAGE_AMOUNT)));
						}

						return merge(
							...requests,
						);
					}),
				),
			),
		),
	);

export const uiFetchMoreStandardEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchMoreStandardEvents)),
		throttleTime(1000, undefined, { leading: true, trailing: false }),
		mergeMap(action => {
			const requests = [];
			for (let i = 0 ; i < EVENTS_MAX_PACKAGES ; i++) {
				requests.push(of(fetchStandardEventsWithOffsetAsync.request((i * EVENTS_PACKAGE_AMOUNT) + action.payload)));
			}
			return merge(
				...requests,
			);
		}),
	);

export const uiFetchUserEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchUserEvents)),
		mergeMap(() =>
			concat(
				of(fetchUserEventsAsync.request()),
				action$.pipe(
					filter(isActionOf(fetchUserEventsAsync.success)),
					take(1),
					mergeMap(() => {
						const requests = [];
						for (let i = 1 ; i <= EVENTS_MAX_PACKAGES - 1 ; i++) {
							requests.push(of(fetchUserEventsWithOffsetAsync.request(i * EVENTS_PACKAGE_AMOUNT)));
						}

						return merge(
							...requests,
						);
					}),
				),
			),
		),
	);

export const uiFetchMoreUserEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchMoreUserEvents)),
		throttleTime(1000, undefined, { leading: true, trailing: false }),
		mergeMap(action => {
			const requests = [];
			for (let i = 0 ; i < EVENTS_MAX_PACKAGES ; i++) {
				requests.push(of(fetchUserEventsWithOffsetAsync.request((i * EVENTS_PACKAGE_AMOUNT) + action.payload)));
			}
			return merge(
				...requests,
			);
		}),
	);

export const uiFetchStandardEventWaveFormEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchStandardEventWaveform)),
		mergeMap(action =>
			concat(
				of(fetchStandardEventByIdAsync.request(action.payload)),
				action$.pipe(
					filter(isActionOf(fetchStandardEventByIdAsync.success)),
					take(1),
					map(() => fetchStandardEventWaveformAsync.request(action.payload)),
				),
			),
		),
	);

export const uiFetchUserEventWaveformEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchUserEventWaveform)),
		mergeMap(action =>
			concat(
				of(fetchUserEventByIdAsync.request(action.payload)),
				action$.pipe(
					filter(isActionOf(fetchUserEventByIdAsync.success)),
					take(1),
					map(() => fetchUserEventWaveformAsync.request(action.payload)),
				),
			),
		),
	);

export const uiFetchStandardEventRmsWaveformEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchStandardEventRmsWaveform)),
		mergeMap(action =>
			concat(
				of(fetchStandardEventByIdAsync.request(action.payload)),
				action$.pipe(
					filter(isActionOf(fetchStandardEventByIdAsync.success)),
					take(1),
					map(() => fetchStandardRmsAsync.request(action.payload)),
				),
			),
		),
	);

export const uiFetchUserEventRmsWaveformEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchUserEventRmsWaveform)),
		mergeMap(action =>
			concat(
				of(fetchUserEventByIdAsync.request(action.payload)),
				action$.pipe(
					filter(isActionOf(fetchUserEventByIdAsync.success)),
					take(1),
					map(() => fetchUserRmsAsync.request(action.payload)),
				),
			),
		),
	);

export const uiFetchStandardEventPqDifEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchStandardEventPqdif)),
		switchMap(action =>
			concat(
				of(fetchStandardEventPqdifAsync.request(action.payload)),
				of(addLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF })),
				action$.pipe(
					filter(action => isActionOf(fetchStandardEventPqdifAsync.success, action) || isActionOf(fetchStandardEventPqdifAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(fetchStandardEventPqdifAsync.success, responseAction)) {
							const blob = new Blob([ new Uint8Array(responseAction.payload.data) ], { type: "application/octet-stream" });

							// Utwórz link do pobrania
							const downloadLink = document.createElement("a");
							downloadLink.href = URL.createObjectURL(blob);
							downloadLink.download = responseAction.payload.meta?.responseHeaders?.[ "content-filename" ] ?? `standard_event_${ responseAction.payload.id }_${ moment().format("HH:mm:ss_DD-MM-YYYY") }.pqdif`; // nazwa pliku do pobrania

							// Dodaj link do dokumentu i kliknij go programowo
							document.body.appendChild(downloadLink);
							downloadLink.click();

							// Usuń link po pobraniu
							document.body.removeChild(downloadLink);
							return of(removeLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF }));
						}

						return merge(
							of(removeLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF })),
							of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.DASHBOARD.no pqdif file") })),
						);
					}),
				),
			),
		),
	);

export const uiFetchUserEventPqDifEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchUserEventPqdif)),
		switchMap(action =>
			concat(
				of(fetchUserEventPqdifAsync.request(action.payload)),
				of(addLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF })),
				action$.pipe(
					filter(action => isActionOf(fetchUserEventPqdifAsync.success, action) || isActionOf(fetchUserEventPqdifAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(fetchUserEventPqdifAsync.success, responseAction)) {
							const blob = new Blob([ new Uint8Array(responseAction.payload.data) ], { type: "application/octet-stream" });

							// Utwórz link do pobrania
							const downloadLink = document.createElement("a");
							downloadLink.href = URL.createObjectURL(blob);
							downloadLink.download = responseAction.payload.meta?.responseHeaders?.[ "content-filename" ] ?? `user_event_${ responseAction.payload.id }_${ moment().format("HH:mm:ss_DD-MM-YYYY") }.pqdif`; // nazwa pliku do pobrania

							// Dodaj link do dokumentu i kliknij go programowo
							document.body.appendChild(downloadLink);
							downloadLink.click();

							// Usuń link po pobraniu
							document.body.removeChild(downloadLink);
							return of(removeLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF }));
						}

						return merge(
							of(removeLoadingRecord({ loadableId: action.payload.event_id, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF })),
							of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.DASHBOARD.no pqdif file") })),
						);
					}),
				),
			),
		),
	);

export const uiExportSettingsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiExportSettings)),
		switchMap(action =>
			concat(
				of(exportSettingsAsync.request()),
				of(addLoadingRecord({ loadableType: LoadableType.EXPORT_SETTINGS })),
				action$.pipe(
					filter(action => isActionOf(exportSettingsAsync.success, action) || isActionOf(exportSettingsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(exportSettingsAsync.success, responseAction)) {
							const blob = new Blob([ new Uint8Array(responseAction.payload.data) ], { type: "application/octet-stream" });

							// Utwórz link do pobrania
							const downloadLink = document.createElement("a");
							downloadLink.href = URL.createObjectURL(blob);
							downloadLink.download = responseAction.payload.meta?.responseHeaders?.[ "content-filename" ] ?? `configuration_${ moment().format("HH:mm:ss_DD-MM-YYYY") }.pqc`; // nazwa pliku do pobrania

							// Dodaj link do dokumentu i kliknij go programowo
							document.body.appendChild(downloadLink);
							downloadLink.click();

							// Usuń link po pobraniu
							document.body.removeChild(downloadLink);
							return of(removeLoadingRecord({ loadableType: LoadableType.EXPORT_SETTINGS }));
						}

						return merge(
							of(removeLoadingRecord({ loadableType: LoadableType.EXPORT_SETTINGS })),
							of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.DASHBOARD.no pqc file") })),
						);
					}),
				),
			),
		),
	);

export const uiImportSettingsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiImportSettings)),
		switchMap(action =>
			concat(
				of(importSettingsAsync.request(action.payload)),
				of(addLoadingRecord({ loadableType: LoadableType.IMPORT_SETTINGS })),
				action$.pipe(
					filter(action => isActionOf(importSettingsAsync.success, action) || isActionOf(importSettingsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(importSettingsAsync.success, responseAction)) {

							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_SETTINGS })),
								of(displayToast({ type: ToastType.SUCCESS, content: i18n.t("RESPONSES.DASHBOARD.configuration imported") })),
							);
						}

						return merge(
							of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_SETTINGS })),
							of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.DASHBOARD.configuration import error") })),
						);
					}),
				),
			),
		),
	);
