import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import SummaryContainer from "src/app/containers/Setup/Summary.container";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { uiFetchSummary } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataBasicLive } from "src/app/types/api/ws.types";
import { useEffect, useState } from "react";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function SummaryView(props: Props) {

	const {
		summary,
		fetchSummary,
	} = props;

	const data = useWebSocket<DataBasicLive>(WebSocketUri.DATA_BASIC_LIVE);

	const [ eventsRecorded, setEventsRecorded ] = useState(0);
	const lastEventsRecorded = data?.[ data.length - 1 ]?.events?.last_index;

	useEffect(() => {
		const eventCounts = data.reduce<number[]>((prev, next) => {
			if (prev.includes(next.events.last_index)) return prev;

			return [ ...prev, next.events.last_index ];
		}, []);
		const maxEventCount = Math.max(...eventCounts);
		if (eventCounts.length > 1 && maxEventCount !== eventsRecorded) {
			fetchSummary();
		}
		setEventsRecorded(maxEventCount);
	}, [ lastEventsRecorded ]);

	return (
		<CachedThenFreshStrategy
			request={ fetchSummary }
			state={ summary }
		>
			{
				summary =>
					<SummaryContainer
						summary={ summary.summary }
						timeplotData={
							data.map(item =>
								({
									...item.basic,
									date: new Date(item.basic.timestamp),
								}),
							)
						}
						recordingDuration={ data?.[ data.length - 1 ]?.events?.recording_duration ?? summary?.summary?.recordingDuration?.value ?? 0 }
						onRefetchSummary={ fetchSummary }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	summary: state.dashboard.summary,
});

const mapDispatchToProps = {
	fetchSummary: uiFetchSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
