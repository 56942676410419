import { Nullable } from "src/app/types/util.types";
import { Form, FormItem, FormItemError, FormValidator } from "src/app/types/ui/form.types";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import i18n from "src/app/translations/i18n";
import moment from "moment";

export const createFormField = <T>(value: T, { disabled = false, optional = false, success = false } = {}): FormItem<T> =>
	({
		value: value,
		initialValue: value,
		touched: false,
		error: null,
		success: success,
		disabled: disabled,
		optional: optional,
	});

export const validateForm = <T>(form: Form<T>, validator: FormValidator<T>, ignoreDisabled = false) => {
	for (const prop in form) {
		const item = form[ prop ];
		if (ignoreDisabled ? true : !item.disabled) {
			const error = validator[ prop ](item.value, item.optional, form);
			form[ prop ] = {
				...form[ prop ],
				error: error,
				success: isNull(error),
			};
		}
	}
	return { ...form };
};

export const retranslateFormErros = <T>(form: Form<T>, validator: FormValidator<T>) => {
	for (const prop in form) {
		const item = form[ prop ];
		if (isNotNull(item.error)) {
			const error = validator[ prop ](item.value, item.optional, form);
			form[ prop ] = {
				...form[ prop ],
				error: error,
				success: isNull(error),
			};
		}
	}
	return { ...form };
};

export const isFormValid = <T>(form: Form<T>) => {
	for (const prop in form) {
		if (form[ prop ].error) {
			return false;
		}
	}
	return true;
};

export const getAllFormValues = <T>(form: Form<T>): T => {
	const values: T = {} as T;
	for (const prop in form) {
		values[ prop ] = form[ prop ].value;
	}
	return values;
};

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const phoneRegex = /^\+[1-9]\d{6,14}$/;
const ipRegex = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
const asciiRegex = /^[\x00-\x7F]*$/;

const PASSWORD_MIN_LENGTH = 1;
const PASSWORD_MAX_LENGTH = 150;

type TranslationContext =
	| "he"
	| "she"
	| "it"
	| "plural";

export const validateField = (fieldName: string, value: string, optional = false, context: TranslationContext, options?: { maxLength?: Nullable<number>, minLength?: Nullable<number> }): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}
	if (isNotNull(options)) {
		if (!isEmptyString(value.trim()) && isNotNull(options.minLength) && value.trim().length < options.minLength) {
			return `${ fieldName } ${ i18n.t("MESSAGES.field need to have minimum of") } ${ options.minLength } ${ i18n.t("MESSAGES.character", { count: options.minLength }) }`;
		} else if (!isEmptyString(value.trim()) && isNotNull(options.maxLength) && value.trim().length > options.maxLength) {
			return `${ fieldName } ${ i18n.t("MESSAGES.field need to have maximum of") } ${ options.maxLength } ${ i18n.t("MESSAGES.character", { count: options.maxLength }) }`;
		}
	}
	return null;
};

export const validateNullableField = (fieldName: string, value: Nullable<any>, optional = false, context: TranslationContext): FormItemError => {
	if (isNull(value) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}

	return null;
};

export const validateASCIIField = (fieldName: string, value: string, optional = false, context: TranslationContext, options?: { maxLength?: Nullable<number>, minLength?: Nullable<number> }): FormItemError => {
	if (!isEmptyString(value.trim()) && !asciiRegex.test(value)) {
		return i18n.t("MESSAGES.special characters");
	}
	return validateField(fieldName, value, optional, context, options);
};

export const validateJSON = (fieldName: string, value: string, optional = false, context: TranslationContext): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}

	try {
		JSON.parse(value);
	} catch (e) {
		if (!isEmptyString(value.trim())) {
			return `${ fieldName } ${ i18n.t("MESSAGES.field has invalid structure") }`;
		}
	}

	return null;
};

export const validateIPAddress = (fieldName: string, ipAddress: string, optional = false, context: TranslationContext): FormItemError => {
	if (isEmptyString(ipAddress.trim()) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}
	if (!isEmptyString(ipAddress.trim()) && !ipRegex.test(ipAddress)) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is invalid", { context }) }`;
	}
	return null;
};

export const validateNumberField = (fieldName: string, value: number | string, optional = false, context: TranslationContext, options?: { from?: number, to?: number, decimalPlaces?: number }): FormItemError => {
	const numberValue = +value;
	const {
		from = 0,
		to,
		decimalPlaces,
	} = (options ?? { from: undefined, to: undefined, decimalPlaces: undefined });

	if ((isNull(value) || isEmptyString(value)) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}
	if (isNaN(numberValue)) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is invalid", { context }) }`;
	}
	if (isNotNull(decimalPlaces)) {
		const numberString = value.toString();
		const decimalPointIndex = numberString.indexOf(".");
		if (decimalPointIndex !== -1) {

			const numberStringDecimalPlaces = numberString.length - decimalPointIndex - 1;

			if (numberStringDecimalPlaces > decimalPlaces) return i18n.t("MESSAGES.invalid decimal places", { count: decimalPlaces });
		}
	}
	if (numberValue < from) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field must be GE") } ${ from }`;
	}
	if (isNotNull(to) && numberValue > to) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field must be LE") } ${ to }`;
	}
	return null;
};

export const validateDate = (fieldName: string, value: Nullable<Date>, optional = false, context: TranslationContext): FormItemError => {
	if (isNull(value) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}

	if (!moment(value).isValid()) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is invalid", { context }) }`;
	}

	return null;
};

export const validateFutureDate = (fieldName: string, value: Nullable<Date>, optional = false, context: TranslationContext): FormItemError => {
	if (isNull(value) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}

	if (!moment(value).isValid()) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is invalid", { context }) }`;
	}

	if (moment(value).isBefore(moment().add(1, "minute"), "minute")) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is past") }`;
	}

	return null;
};

export const validateArrayField = (fieldName: string, array: any[], optional = false, context: TranslationContext, length = 0): FormItemError => {
	if (array.length === 0 && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}
	if (array.length < length && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field need to have minimum of") } ${ length } ${ i18n.t("MESSAGES.element") }${ length > 1 ? i18n.t("MESSAGES.plural in english element") : "" }`;
	}
	return null;
};

export const validateEmail = (email: string, optional = false): FormItemError => {
	if (!isEmptyString(email.trim()) && !emailRegex.test(email)) {
		return i18n.t("MESSAGES.valid email address");
	}
	if (isEmptyString(email.trim()) && !optional) {
		return i18n.t("MESSAGES.email is required");
	}
	return null;
};

export const validatePhone = (phone: string, optional = false): FormItemError => {
	if (!isEmptyString(phone.trim()) && !phoneRegex.test(phone)) {
		return i18n.t("MESSAGES.valid phone number");
	}
	if (isEmptyString(phone.trim()) && !optional) {
		return `${ i18n.t("MESSAGES.phone is required") }`;
	}

	const PHONE_MIN_LENGTH = 5;
	const PHONE_MAX_LENGTH = 20;

	if (!isEmptyString(phone) && phone.length < PHONE_MIN_LENGTH) {
		return `${ i18n.t("MESSAGES.phone must be at least") } ${ PHONE_MIN_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`;
	}
	if (!isEmptyString(phone) && phone.length > PHONE_MAX_LENGTH) {
		return `${ i18n.t("MESSAGES.phone can be up to") } ${ PHONE_MAX_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`;
	}

	return null;
};

export const validatePassword = (password: string, optional = false): FormItemError => {
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		return `${ i18n.t("MESSAGES.password must be at least") } ${ PASSWORD_MIN_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`;
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		return `${ i18n.t("MESSAGES.password can be up to") } ${ PASSWORD_MAX_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`;
	}
	return validateField(i18n.t("PASSWORD.password"), password, optional, "it");
};

export const validateStrongPassword = (password: string, optional = false): FormItemError => {
	const errors: string[] = [];
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		errors.push(`${ i18n.t("MESSAGES.password must be at least") } ${ PASSWORD_MIN_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`);
	}
	if (!isEmptyString(password) && password.trim().length < PASSWORD_MIN_LENGTH) {
		errors.push(`${ i18n.t("MESSAGES.incorrect password structure") }`);
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		errors.push(`${ i18n.t("MESSAGES.password can be up to") } ${ PASSWORD_MAX_LENGTH } ${ i18n.t("MESSAGES.max/min lenght characters long") }`);
	}
	if (!isEmptyString(password.trim()) && !/[A-Z]/.test(password)) {
		errors.push(i18n.t("MESSAGES.least one capital letter"));
	}
	if (!isEmptyString(password.trim()) && !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)) {
		errors.push(i18n.t("MESSAGES.least one special character"));
	}
	if (!isEmptyString(password.trim()) && !/\d/.test(password)) {
		errors.push(i18n.t("MESSAGES.least one digit"));
	}

	if (errors.length > 1) {
		return errors;
	} else if (errors.length === 1) {
		return errors[ 0 ];
	}

	return validateField(i18n.t("PASSWORD.password"), password, optional, "it");
};

export const comparePasswords = (password: string, passwordConfirmed: string): FormItemError => {
	if (password !== passwordConfirmed) {
		return i18n.t("MESSAGES.passwords dont match");
	}
	return null;
};

enum PhotoFormats {
	JPEG = "image/jpeg",
	PNG = "image/png"
}

export const validatePhoto = (fieldName: string, file: Nullable<File>, optional = false, context: TranslationContext, maxSizeInMegaBytes = 10): FormItemError => {
	if (isNull(file) && !optional) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is required", { context }) }`;
	}

	if (isNotNull(file) && !Object.values<string>(PhotoFormats).includes(file.type)) {
		return i18n.t("MESSAGES.invalid file format");
	}

	if (isNotNull(file) && file.size > maxSizeInMegaBytes * 1024 * 1024) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field can be up to") } ${ maxSizeInMegaBytes } MB`;
	}

	return null;
};

export const compareStrings = (fieldName: string, firstString: string, secondString: string, context: TranslationContext, ignoreCase = true): FormItemError => {
	if ((ignoreCase && firstString !== secondString) || (!ignoreCase && firstString.toLowerCase() !== secondString.toLowerCase())) {
		return `${ fieldName } ${ i18n.t("MESSAGES.field is invalid", { context }) }`;
	}

	return null;
};
