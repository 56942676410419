import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createContext, PropsWithChildren } from "react";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { SettingsFtp, SettingsIec, SettingsIo, SettingsMeasurementsStandard, SettingsMeasurementsUser, SettingsMemory, SettingsModbus, SettingsRecording, SettingsTime } from "src/app/types/api/settings.types";
import { getSettings } from "src/app/store/features/settings/settings.selectors";
import { Summary } from "src/app/types/api/dashboard.types";

type Props = ReturnType<typeof mapStateToProps>;

type Context = {
	summary: Summary
	recording: SettingsRecording
	modbus: SettingsModbus
	ftp: SettingsFtp
	time: SettingsTime
	memory: SettingsMemory
	measurementsUser: SettingsMeasurementsUser
	measurementsStandard: SettingsMeasurementsStandard
	iec: SettingsIec
	io: SettingsIo
}

export const SettingsContext = createContext<Context>(null!);

function SettingsProvider(props: PropsWithChildren<Props>) {

	const {
		settings,
		children,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ () => undefined }
			state={ settings }
			withoutLoading
		>
			{
				({
					 summary,
					 settingsRecording, settingsMemory, settingsModbus,
					 settingsFtp, settingsTime, settingsMeasurementsUser,
					 settingsMeasurementsStandard, settingsIo, settingsIec,
				 }) =>
					<SettingsContext.Provider
						value={ {
							summary: summary,
							recording: settingsRecording,
							modbus: settingsModbus,
							ftp: settingsFtp,
							time: settingsTime,
							memory: settingsMemory,
							measurementsUser: settingsMeasurementsUser,
							measurementsStandard: settingsMeasurementsStandard,
							iec: settingsIec,
							io: settingsIo,
						} }
					>
						{ children }
					</SettingsContext.Provider>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	settings: getSettings(state),
});

export default connect(mapStateToProps)(SettingsProvider);
