import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type ActiveEnergyForm = {
	cfgActiveEnergyRecOpt: number
	cfgActiveEnergyPlusEventThresholdMax: string
	cfgActiveEnergyPlusEventThresholdMaxMin: number
	cfgActiveEnergyPlusEventThresholdMaxMax: number
	cfgActiveEnergyMinusEventThresholdMax: string
	cfgActiveEnergyMinusEventThresholdMaxMin: number
	cfgActiveEnergyMinusEventThresholdMaxMax: number
}

const validator: FormValidator<ActiveEnergyForm> = {
	cfgActiveEnergyRecOpt: () => null,
	cfgActiveEnergyPlusEventThresholdMax: (cfgActiveEnergyPlusEventThresholdMax, optional, form) => validateNumberField(`${ i18n.t("ENERGY.max") } Ep+`, cfgActiveEnergyPlusEventThresholdMax, optional, "he", { from: form.cfgActiveEnergyPlusEventThresholdMaxMin.value, to: form.cfgActiveEnergyPlusEventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgActiveEnergyPlusEventThresholdMaxMin: () => null,
	cfgActiveEnergyPlusEventThresholdMaxMax: () => null,
	cfgActiveEnergyMinusEventThresholdMax: (cfgActiveEnergyMinusEventThresholdMax, optional, form) => validateNumberField(`${ i18n.t("ENERGY.max") } Ep-`, cfgActiveEnergyMinusEventThresholdMax, optional, "he", { from: form.cfgActiveEnergyMinusEventThresholdMaxMin.value, to: form.cfgActiveEnergyMinusEventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgActiveEnergyMinusEventThresholdMaxMin: () => null,
	cfgActiveEnergyMinusEventThresholdMaxMax: () => null,
};

const ID = "active-energy";

function ActiveEnergyCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgActiveEnergy,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ActiveEnergyForm) => {
		reducerHandleChange("cfgActiveEnergyRecOpt", values.cfgActiveEnergyRecOpt);
		reducerHandleChange("cfgActiveEnergyPlusEventThresholdMax", values.cfgActiveEnergyPlusEventThresholdMax);
		reducerHandleChange("cfgActiveEnergyMinusEventThresholdMax", values.cfgActiveEnergyMinusEventThresholdMax);
	};

	const _getInitialState = () => ({
		cfgActiveEnergyRecOpt: createFormField((reducerForm.cfgActiveEnergyRecOpt.value !== reducerForm.cfgActiveEnergyRecOpt.initialValue) ? reducerForm.cfgActiveEnergyRecOpt.value : cfgActiveEnergy?.cfgActiveEnergyRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyPlusEventThresholdMax: createFormField((reducerForm.cfgActiveEnergyPlusEventThresholdMax.value !== reducerForm.cfgActiveEnergyPlusEventThresholdMax.initialValue) ? reducerForm.cfgActiveEnergyPlusEventThresholdMax.value : cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyPlusEventThresholdMaxMin: createFormField(cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyPlusEventThresholdMaxMax: createFormField(cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyMinusEventThresholdMax: createFormField((reducerForm.cfgActiveEnergyMinusEventThresholdMax.value !== reducerForm.cfgActiveEnergyMinusEventThresholdMax.initialValue) ? reducerForm.cfgActiveEnergyMinusEventThresholdMax.value : cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyMinusEventThresholdMaxMin: createFormField(cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgActiveEnergyMinusEventThresholdMaxMax: createFormField(cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgActiveEnergy ]);

	useEffect(() => {
		toggleDisable("cfgActiveEnergyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActiveEnergyPlusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActiveEnergyMinusEventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgActiveEnergyRecOpt", false);
		toggleDisable("cfgActiveEnergyPlusEventThresholdMax", false);
		toggleDisable("cfgActiveEnergyMinusEventThresholdMax", false);

		handleChange("cfgActiveEnergyRecOpt", reducerForm.cfgActiveEnergyRecOpt.value);
		handleChange("cfgActiveEnergyPlusEventThresholdMax", reducerForm.cfgActiveEnergyPlusEventThresholdMax.value);
		handleChange("cfgActiveEnergyMinusEventThresholdMax", reducerForm.cfgActiveEnergyMinusEventThresholdMax.value);

		toggleDisable("cfgActiveEnergyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActiveEnergyPlusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActiveEnergyMinusEventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgActiveEnergyRecOpt.value,
		reducerForm.cfgActiveEnergyPlusEventThresholdMax.value,
		reducerForm.cfgActiveEnergyMinusEventThresholdMax.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				<Translate i18nKey="ENERGY.active energy"/>
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgActiveEnergy?.cfgActiveEnergyRecOpt?.options ?? [] }
					formItem={ form.cfgActiveEnergyRecOpt }
					handleChange={ value => handleChange("cfgActiveEnergyRecOpt", value) }
					reducerFormItem={ reducerForm.cfgActiveEnergyRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("ENERGY.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgActiveEnergy?.cfgActiveEnergyRecOpt?.options ?? [] }
						formItem={ form.cfgActiveEnergyRecOpt }
						handleChange={ value => handleChange("cfgActiveEnergyRecOpt", value) }
						reducerFormItem={ reducerForm.cfgActiveEnergyRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("ENERGY.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<div className="flex gap-2.5">
											<Input
												className="flex-1"
												formItem={ form.cfgActiveEnergyPlusEventThresholdMax }
												label={
													<Translate i18nKey="ENERGY.max active energy +"/>
												}
												name="cfgActiveEnergyPlusEventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgActiveEnergyPlusEventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgActiveEnergyPlusEventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgActiveEnergyPlusEventThresholdMax.value !== reducerForm.cfgActiveEnergyPlusEventThresholdMax.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgActiveEnergyMinusEventThresholdMax }
												label={
													<Translate i18nKey="ENERGY.max active energy -"/>
												}
												name="cfgActiveEnergyMinusEventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgActiveEnergyMinusEventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgActiveEnergyMinusEventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgActiveEnergyMinusEventThresholdMax.value !== reducerForm.cfgActiveEnergyMinusEventThresholdMax.initialValue }
											/>
										</div>
										<div className="flex-[7_1_0%] items-center flex gap-2">
											{/*<RelayChannelChooser
												options={ [
													isNotNull(cfgCurrentRms.relayChannelLabel.ch1) ? {
														value: "cfgRelayCurrent1RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch1,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch2) ? {
														value: "cfgRelayCurrent2RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch2,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch3) ? {
														value: "cfgRelayCurrent3RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch3,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch4) ? {
														value: "cfgRelayCurrent4RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch4,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch5) ? {
														value: "cfgRelayCurrent5RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch5,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgCurrentChannels }
												reducerFormItem={ reducerForm.cfgCurrentChannels }
												handleChange={ value => handleChange("cfgCurrentChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgCurrentActions }
												reducerFormItem={ reducerForm.cfgCurrentActions }
												options={ cfgCurrentRms.cfgRelayCurrent1Map.options }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgCurrentChannels.value) }
												handleChange={ value => handleChange("cfgCurrentActions", value) }
											/>*/ }
										</div>
									</div>
									<hr className="mx-2"/>
								</div>

						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ActiveEnergyCard);
