import { EnumDictionary } from "src/app/types/util.types";

export enum LanguageCode {
	EN = "EN",
	PL = "PL",
	ES = "ES",
	DE = "DE",
}

export enum CountryCode {
	GB = "GB",
	PL = "PL",
	ES = "ES",
	DE = "DE",
}

export const languageToCountryDictionary: EnumDictionary<LanguageCode, CountryCode> = (lng) => ({
	[ LanguageCode.EN ]: CountryCode.GB,
	[ LanguageCode.PL ]: CountryCode.PL,
	[ LanguageCode.ES ]: CountryCode.ES,
	[ LanguageCode.DE ]: CountryCode.DE,
});