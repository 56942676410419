import { Event, EventChannel, EventFlag, EventOrigin, EventsCore, EventStatus, EventType, StandardEvents } from "src/app/types/api/event.types";
import { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import { SortCompare } from "src/app/types/util.types";
import Table from "src/app/components/Utils/Table.component";
import { eventChannelDictionary, eventFlagDictionary, eventOriginDictionary, eventOriginNodeDictionary, eventOriginToMeasurementCategoryDictionary, eventTypeDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Card, Checkbox, Label, Tooltip } from "flowbite-react";
import { memo, useMemo, useState } from "react";
import { PiChartLineLight, PiWaveformBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { formatDuration } from "src/app/utils/helpers";
import { useTranslation } from "react-i18next";
import { HiInformationCircle } from "react-icons/hi";
import { formatValueToFullDeciSON } from "src/app/utils/dataFormatting";
import classNames from "classnames";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		events: EventsCore
		fetchMoreEvents: (eventsAmount: number) => void
		title: string
		getEventWaveformLink: (event: Event) => string
		getEventRmsWaveformLink: (event: Event) => string
		onDownloadPqDif: (event: Event) => void
	};

function EventsContainer(props: Props) {

	const { t } = useTranslation();

	const {
		events,
		fetchMoreEvents,
		title,
		mainHeight,
		hasMoreEvents,
		getEventWaveformLink,
		getEventRmsWaveformLink,
		onDownloadPqDif,
		isFetchingPqDif,
	} = props;

	const [ types, setTypes ] = useState(events.types.map(type => type.text));
	const [ sources, setSources ] = useState(events.channels.map(channel => channel.text));

	const _changeType = (type: EventType) => {
		setTypes(prevState => {
			if (prevState.includes(type)) {
				return prevState.filter(stateType => stateType !== type);
			} else {
				return [ ...prevState, type ];
			}
		});
	};

	const _changeSource = (source: EventChannel) => {
		setSources(prevState => {
			if (prevState.includes(source)) {
				return prevState.filter(stateSource => stateSource !== source);
			} else {
				return [ ...prevState, source ];
			}
		});
	};

	const _getEventType = (eventType: number) => {
		const type = events.types.find(type => type.value === eventType);
		return type?.text ?? null;
	};

	const _getEventOrigin = (eventOrigin: number) => {
		const origin = events.origins.find(origin => origin.value === eventOrigin);
		return origin?.text ?? null;
	};

	const _getEventChannels = (eventChannel: number) => {
		const channels = events.channels.filter(channel => (eventChannel & channel.mask) > 0);
		return channels.map(channel => channel.text);
	};

	const data = useMemo(() => {
		return events.event
					 .filter(event => _getEventOrigin(event.origin) !== EventOrigin.UNKNOWN)
					 .filter(event => {
						 const type = _getEventType(event.type);
						 if (isNull(type)) return false;

						 return types.includes(type);
					 })
					 .filter(event => {
						 const channels = _getEventChannels(event.channels);

						 return channels.length === 0 || channels.some(channel => sources.includes(channel));
					 });
	}, [ types.length, sources.length, events.event.length ]);

	return (
		<div className="flex flex-col gap-4">
			<Card>
				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-1.5">
						<h2 className="text-xl font-medium">{ t("EVENTS.type") }</h2>
						<div className="flex gap-4">
							{
								Object
									.values(EventType)
									.map(type =>
										<div
											key={ type }
											className="flex items-center gap-x-2"
										>
											<Checkbox
												className="cursor-pointer"
												id={ type }
												name={ type }
												checked={ types.includes(type) }
												onChange={ () => _changeType(type) }
											/>
											<Label
												htmlFor={ type }
												className="cursor-pointer"
											>
												{ `${ eventTypeDictionary()[ type ] } (${ events.event.filter(event => _getEventOrigin(event.origin) !== EventOrigin.UNKNOWN).filter(event => _getEventType(event.type) === type).length })` }
											</Label>
										</div>,
									)
							}
						</div>
					</div>
					<div className="flex flex-col gap-1.5">
						<h2 className="text-xl font-medium">{ t("EVENTS.source") }</h2>
						<div className="flex gap-4">
							{
								Object
									.values(EventChannel)
									.map(channel =>
										<div
											key={ channel }
											className="flex items-center gap-x-2"
										>
											<Checkbox
												className="cursor-pointer"
												id={ channel }
												name={ channel }
												checked={ sources.includes(channel) }
												onChange={ () => _changeSource(channel) }
											/>
											<Label
												htmlFor={ channel }
												className="cursor-pointer"
											>
												{ `${ eventChannelDictionary()[ channel ] } (${ events.event.filter(event => _getEventOrigin(event.origin) !== EventOrigin.UNKNOWN).filter(event => _getEventChannels(event.channels).includes(channel)).length })` }
											</Label>
										</div>,
									)
							}
						</div>
					</div>
				</div>
			</Card>
			<div
				className={
					classNames(
						"2xl:col-span-10",
						"col-span-9",
					)
				}
			>
				<EventTable
					title={ title }
					mainHeight={ mainHeight }
					hasMoreEvents={ hasMoreEvents }
					fetchMoreEvents={ fetchMoreEvents }
					getEventWaveformLink={ getEventWaveformLink }
					getEventRmsWaveformLink={ getEventRmsWaveformLink }
					onDownloadPqDif={ onDownloadPqDif }
					events={ data }
					types={ events.types }
					channels={ events.channels }
					status={ events.status }
					flags={ events.flags }
					origins={ events.origins }
					isFetchingPqDif={ isFetchingPqDif }
				/>
			</div>
		</div>
	);
}

type EventTableProps = {
	mainHeight: number
	hasMoreEvents: boolean
	title: string
	fetchMoreEvents: (eventsAmount: number) => void
	events: Event[]
	getEventWaveformLink: (event: Event) => string
	getEventRmsWaveformLink: (event: Event) => string
	onDownloadPqDif: (event: Event) => void
	types: StandardEvents["events"]["types"]
	channels: StandardEvents["events"]["channels"]
	status: StandardEvents["events"]["status"]
	flags: StandardEvents["events"]["flags"]
	origins: StandardEvents["events"]["origins"]
	isFetchingPqDif: (eventId: number) => boolean
}

const EventTable = memo((props: EventTableProps) => {

	const { t } = useTranslation();

	const {
		mainHeight,
		hasMoreEvents,
		title,
		fetchMoreEvents,
		events,
		getEventWaveformLink,
		getEventRmsWaveformLink,
		onDownloadPqDif,
		types,
		channels,
		status,
		flags,
		origins,
		isFetchingPqDif,
	} = props;

	const _getEventType = (eventType: number) => {
		const type = types.find(type => type.value === eventType);
		return type?.text ?? null;
	};

	const _getEventOrigin = (eventOrigin: number) => {
		const origin = origins.find(origin => origin.value === eventOrigin);
		return origin?.text ?? null;
	};

	const _getEventChannels = (eventChannel: number) => {
		return channels
			.filter(channel => (eventChannel & channel.mask) > 0)
			.map(channel => channel.text);
	};

	const _getEventStatus = (eventStatus: number) => {
		const findedStatus = status.find(status => status.value === eventStatus);
		return findedStatus?.text ?? null;
	};

	const _getFlags = (eventFlag: number) =>
		flags
			.filter(flag => (eventFlag & flag.mask) > 0)
			.map(flag => flag.text);

	const eventColumns: MUIDataTableColumn[] = [
		{
			name: "ID",
			label: t("EVENTS.id").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => <span className="!px-1.5">{ event.id }</span>,
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return a.data.id - b.data.id;
					} else {
						return b.data.id - a.data.id;
					}
				},
				setCellHeaderProps: () => ({
					className: "[&>span>button]:!m-0",
				}),
			},
		}, {
			name: "Type",
			label: t("EVENTS.type").toUpperCase(),
			options: {
				filter: false,
				// filterOptions: {
				// 	names: Object
				// 		.values<EventType>(EventType)
				// 		.map(type => eventTypeDictionary()[ type ]),
				// 	logic: (item, filters, row) => {
				// 		if (isNull(row) || isNull(row[ 0 ])) return false;
				// 		const event: Event = row[ 0 ];
				// 		const eventType = _getEventType(event.type);
				// 		let translatedType = t("EVENTS.unknown");
				// 		if (isNotNull(eventType)) translatedType = eventTypeDictionary()[ eventType ] ?? t("EVENTS.unknown");
				// 		return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(translatedType.toLowerCase());
				// 	},
				// },
				customBodyRender: (event: Event) => {
					const type = _getEventType(event.type);
					const origin = _getEventOrigin(event.origin);
					if (isNull(type) || isNull(origin)) return t("EVENTS.unknown");

					if (type === EventType.EVENT_UNSPEC) {
						return eventOriginNodeDictionary()[ origin ] ?? t("EVENTS.unknown");
					}

					return <span className="whitespace-nowrap">{ eventOriginNodeDictionary()[ origin ] }{ ` - ${ eventTypeDictionary()[ type ] }` }</span>;
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					const eventTypeA = _getEventType(a.data.type);
					const eventOriginA = _getEventOrigin(a.data.origin);
					const eventTypeB = _getEventType(b.data.type);
					const eventOriginB = _getEventOrigin(b.data.origin);

					let translatedTypeA = t("EVENTS.unknown");
					if (isNotNull(eventTypeA) && isNotNull(eventOriginA)) {
						if (eventTypeA === EventType.EVENT_UNSPEC) {
							translatedTypeA = eventOriginDictionary()[ eventOriginA ];
						} else {
							translatedTypeA = `${ eventOriginDictionary()[ eventOriginA ] } - ${ eventTypeDictionary()[ eventTypeA ] }`;
						}
					}
					let translatedTypeB = t("EVENTS.unknown");
					if (isNotNull(eventTypeB) && isNotNull(eventOriginB)) {
						if (eventTypeB === EventType.EVENT_UNSPEC) {
							translatedTypeB = eventOriginDictionary()[ eventOriginB ];
						} else {
							translatedTypeB = `${ eventOriginDictionary()[ eventOriginB ] } - ${ eventTypeDictionary()[ eventTypeB ] }`;
						}
					}
					if (order === "asc") {
						return translatedTypeA.localeCompare(translatedTypeB);
					} else {
						return translatedTypeB.localeCompare(translatedTypeA);
					}
				},
			},
		}, {
			name: "Source",
			label: t("EVENTS.source").toUpperCase(),
			options: {
				filter: false,
				// filterOptions: {
				// 	names: Object
				// 		.values<EventChannel>(EventChannel)
				// 		.map(channel => eventChannelDictionary()[ channel ]),
				// 	logic: (item, filters, row) => {
				// 		if (isNull(row) || isNull(row[ 0 ])) return false;
				// 		const event: Event = row[ 0 ];
				// 		const translatedChannels = _getEventChannels(event.channels).map(channel => eventChannelDictionary()[ channel ]);
				// 		return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).some(filterEl => translatedChannels.includes(filterEl.toLowerCase()));
				// 	},
				// },
				customBodyRender: (event: Event) => {
					const channels = _getEventChannels(event.channels);
					return channels.map(channel => eventChannelDictionary()[ channel ]).join(", ");
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					const eventChannelsA = _getEventChannels(a.data.channels).map(channel => eventChannelDictionary()[ channel ]).join(", ");
					const eventChannelsB = _getEventChannels(b.data.channels).map(channel => eventChannelDictionary()[ channel ]).join(", ");
					if (order === "asc") {
						return eventChannelsA.localeCompare(eventChannelsB);
					} else {
						return eventChannelsB.localeCompare(eventChannelsA);
					}
				},
			},
		}, {
			name: "Flags",
			label: t("EVENTS.flags").toUpperCase(),
			options: {
				sort: false,
				filter: false,
				/*filterOptions: {
					names: Object
						.values<EventFlag>(EventFlag)
						.map(flag => eventFlagDictionary()[ flag ].name),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const event: Event = row[ 0 ];
						const eventFlags = _getFlags(event.flags);
						return isNotNull(row) && eventFlags.some(flag => !filters.map(filterElement => filterElement.toLowerCase()).includes(eventFlagDictionary()[ flag ].name.toLowerCase()));
					},
				},*/
				customBodyRender: (event: Event) =>
					<div className="flex gap-0.5">
						{
							_getFlags(event.flags)
								.filter(flag =>
									flag !== EventFlag.RMS12_CHART &&
									flag !== EventFlag.WAVEFORM_CHART &&
									flag !== EventFlag.PQDIF_FILE,
								)
								.map(flag => {
									const { icon: Icon, name } = eventFlagDictionary()[ flag ];
									return (
										<Tooltip key={ flag } content={ name }>
											<Icon className="text-gray-500 w-6 h-6"/>
										</Tooltip>
									);
								})
						}
					</div>,
			},
		}, {
			name: "Start",
			label: t("EVENTS.start").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const isBegin = _getEventStatus(event.timestampStatus) === EventStatus.BEGIN;
					if (isNull(event.timestampStart)) return "---";

					if (isBegin) {
						return <span className="whitespace-nowrap">{ `< ${ moment(event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }` }</span>;
					} else {
						return <span className="whitespace-nowrap">{ moment(event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }</span>;
					}
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.timestampStart ?? 0) - (a.data.timestampStart ?? 0);
					} else {
						return (a.data.timestampStart ?? 0) - (b.data.timestampStart ?? 0);
					}
				},
			},
		}, {
			name: "End",
			label: t("EVENTS.end").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const isProgress = _getEventStatus(event.timestampStatus) === EventStatus.PROGRESS;
					if (isNull(event.timestampEnd)) return "---";

					if (isProgress) {
						return <span className="whitespace-nowrap">{ `> ${ moment(event.timestampEnd / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }` }</span>;
					} else {
						return <span className="whitespace-nowrap">{ moment(event.timestampEnd / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }</span>;
					}
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.timestampEnd ?? 0) - (a.data.timestampEnd ?? 0);
					} else {
						return (a.data.timestampEnd ?? 0) - (b.data.timestampEnd ?? 0);
					}
				},
			},
		}, {
			name: "Duration",
			label: t("EVENTS.duration").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => isNotNull(event.duration) ? formatDuration(event.duration / 1000, "ms") : "---",
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.duration ?? 0) - (a.data.duration ?? 0);
					} else {
						return (a.data.duration ?? 0) - (b.data.duration ?? 0);
					}
				},
			},
		}, {
			name: "Extremum",
			label: t("EVENTS.extremum").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const origin = _getEventOrigin(event.origin);

					return formatValueToFullDeciSON(event?.extremum?.value, event?.extremum?.unit, eventOriginToMeasurementCategoryDictionary()[ origin ?? EventOrigin.UNKNOWN ]) ?? "---";
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.extremum.value ?? 0) - (a.data.extremum.value ?? 0);
					} else {
						return (a.data.extremum.value ?? 0) - (b.data.extremum.value ?? 0);
					}
				},
			},
		}, {
			name: "Average",
			label: t("EVENTS.average").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const origin = _getEventOrigin(event.origin);

					return formatValueToFullDeciSON(event?.avgValue?.value, event?.avgValue?.unit, eventOriginToMeasurementCategoryDictionary()[ origin ?? EventOrigin.UNKNOWN ]) ?? "---";
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.avgValue.value ?? 0) - (a.data.avgValue.value ?? 0);
					} else {
						return (a.data.avgValue.value ?? 0) - (b.data.avgValue.value ?? 0);
					}
				},
			},
		}, {
			name: "Threshold",
			label: t("EVENTS.threshold").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const origin = _getEventOrigin(event.origin);

					return formatValueToFullDeciSON(event?.threshold?.value, event?.threshold?.unit, eventOriginToMeasurementCategoryDictionary()[ origin ?? EventOrigin.UNKNOWN ]) ?? "---";
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.threshold.value ?? 0) - (a.data.threshold.value ?? 0);
					} else {
						return (a.data.threshold.value ?? 0) - (b.data.threshold.value ?? 0);
					}
				},
			},
		}, {
			name: "Actions",
			label: t("EVENTS.actions").toUpperCase(),
			options: {
				filter: false,
				sort: false,
				customBodyRender: (event: Event) => {
					const isFetching = isFetchingPqDif(event.id);
					const flags = _getFlags(event.flags);
					const isWaveform = flags.includes(EventFlag.WAVEFORM_CHART);
					const isRms = flags.includes(EventFlag.RMS12_CHART);
					const isPqdif = flags.includes(EventFlag.PQDIF_FILE);
					return (
						<div className="flex gap-2 items-center sm:justify-end">
							{
								(!isWaveform && !isRms && !isPqdif) &&
                                <Button
                                    color="transparent"
                                    className="p-0 [&>span]:p-2 opacity-0"
                                >
                                    <PiWaveformBold
                                        className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
                                    />
                                </Button>
							}
							{
								isWaveform &&
                                <Link to={ getEventWaveformLink(event) } target="_blank">
                                    <Tooltip placement="left" content={ t("EVENTS.waveform") }>
                                        <Button
                                            color="transparent"
                                            className="p-0 [&>span]:p-2"
                                        >
                                            <PiWaveformBold
                                                className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
                                            />
                                        </Button>
                                    </Tooltip>
                                </Link>
							}
							{
								isRms &&
                                <Link to={ getEventRmsWaveformLink(event) } target="_blank">
                                    <Tooltip placement="left" content={ t("EVENTS.rms") }>
                                        <Button
                                            color="transparent"
                                            className="p-0 [&>span]:p-2"
                                        >
                                            <PiChartLineLight
                                                className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
                                            />
                                        </Button>
                                    </Tooltip>
                                </Link>
							}
							{
								isPqdif &&
                                <Button
                                    color="transparent"
                                    className={
										classNames(
											"p-0 [&>span]:p-2",
											{ "[&>span>span]:left-[20px]": isFetching },
										)
									}
                                    isProcessing={ isFetching }
                                    onClick={ () => onDownloadPqDif(event) }
                                >
									<span
                                        className={
											classNames(
												"font-bold text-sm text-[#009FE3]",
												{ "opacity-0": isFetching },
											)
										}
                                    >
										PQDIF
									</span>
                                </Button>
							}
						</div>
					);
				},
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		search: false,
		filter: false,
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			const pages = Math.ceil(tableState.data.length / tableState.rowsPerPage);
			const actualPage = tableState.page + 1; // Goes from 0;
			const pageOffset = 3;
			if (pages - pageOffset <= actualPage && hasMoreEvents) {
				fetchMoreEvents(tableState.data.length);
			}
		},
	};

	return (
		<Table
			title={
				<div className="flex gap-2 items-center">
					<h6 className="text-xl font-medium">{ title }</h6>
					<Tooltip
						placement="right"
						content={
							<div className="flex flex-col">
								<span>{ t("EVENTS.display times for graphs") }</span>
								<span>{ t("EVENTS.oscillograms") }</span>
								<span>{ t("EVENTS.rms 1/2") }</span>
							</div>
						}
					>
						<HiInformationCircle
							className="cursor-pointer h-6 w-6 text-[#009FE3] dark:text-dark-textGray"
						/>
					</Tooltip>
				</div>
			}
			columns={ eventColumns }
			options={ tableOptions }
			wrapperHeight={ mainHeight }
			data={ events }
			cellHeight={ 53 }
			cellPadding={ 4 }
			isEventsTable
		/>
	);
});

const mapStateToProps = (state: RootState) => ({
	mainHeight: state.ui.layout.mainSize.height,
	hasMoreEvents: state.dashboard.hasMoreStandardEvents,
	isFetchingPqDif: (eventId: number) => didLoadingRecordExist(state, { loadableId: eventId, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF }),
});

export default connect(mapStateToProps)(EventsContainer);
