export type LoadingRecord = {
	loadableId?: string | number,
	loadableType: LoadableType
}

export enum LoadableType {
	LOGIN = "LOGIN",
	AUTH_ME = "AUTH_ME",

	// User
	FETCH_USERS = "FETCH_USERS",
	CREATE_USER = "CREATE_USER",
	DELETE_USER = "DELETE_USER",

	RESET_PASSWORD = "RESET_PASSWORD",
	UPDATE_PASSWORD = "UPDATE_PASSWORD",

	// Dashboard
	FETCH_SUMMARY = "FETCH_SUMMARY",
	FETCH_SUMMARY_SETTINGS = "FETCH_SUMMARY_SETTINGS",
	FETCH_SETTINGS_RECORDING = "FETCH_SETTINGS_RECORDING",
	FETCH_SETTINGS_MODBUS = "FETCH_SETTINGS_MODBUS",
	FETCH_SETTINGS_TIME = "FETCH_SETTINGS_TIME",
	FETCH_SETTINGS_MEASUREMENTS_USER = "FETCH_SETTINGS_MEASUREMENTS_USER",
	FETCH_SETTINGS_MEASUREMENTS_STANDARD = "FETCH_SETTINGS_MEASUREMENTS_STANDARD",
	FETCH_SETTINGS_MEMORY = "FETCH_SETTINGS_MEMORY",
	FETCH_SETTINGS_FTP = "FETCH_SETTINGS_FTP",
	FETCH_SETTINGS_IEC = "FETCH_SETTINGS_IEC",
	FETCH_SETTINGS_IO = "FETCH_SETTINGS_IO",

	// Recording
	SET_BUFFOR_CONFIGURATION = "SET_BUFFOR_CONFIGURATION",

	// Firmware
	UPLOADING_FIRMWARE_FILE = "UPLOADING_FIRMWARE_FILE",
	PROCESSING_FIRMWARE_FILE = "PROCESSING_FIRMWARE_FILE",
	REMOVE_FIRMWARE_FILE = "REMOVE_FIRMWARE_FILE",
	UPGRADE_FIRMWARE_REQUEST = "UPGRADE_FIRMWARE_REQUEST",

	// FTP
	TEST_FTP_CONNECTION = "TEST_FTP_CONNECTION",

	// IEC
	DOWNLOAD_IEC_PROFILE = "DOWNLOAD_IEC_PROFILE",
	DOWNLOAD_EVENT_PQDIF = "DOWNLOAD_EVENT_PQDIF",
	EXPORT_SETTINGS = "EXPORT_SETTINGS",
	IMPORT_SETTINGS = "IMPORT_SETTINGS"
	// Loadable types - don't remove
}
