import { MeasurementCategory } from "src/app/types/misc.types";
import { Nullable } from "src/app/types/util.types";

enum Prefix {
	NONE = 0,
	ATTO = 1,
	FEMTO = 2,
	PICO = 3,
	NANO = 4,
	MICRO = 5,
	MILLI = 6,
	CENTI = 7,
	DECI = 8,
	DECA = 9,
	HECTO = 10,
	KILO = 11,
	MEGA = 12,
	GIGA = 13,
	TERA = 14,
	PETA = 15
}

enum Multiplier {
	N9 = 0,
	N8 = 1,
	N7 = 2,
	N6 = 3,
	N5 = 4,
	N4 = 5,
	N3 = 6,
	N2 = 7,
	N1 = 8,
	NONE = 9,
	P1 = 10,
	P2 = 11,
	P3 = 12,
	P4 = 13,
	P5 = 14,
	P6 = 15
}

enum Unit {
	NONE = 0,
	VOLT = 1,
	AMPERE = 2,
	OHM = 3,
	HZ = 4,
	WATT = 5,
	VA = 6,
	VAR = 7,
	PERCENT = 8,
	SECOND = 9,
	RSVD1 = 10,
	RSVD2 = 11,
	RSVD3 = 12,
	RSVD4 = 13,
	RSVD5 = 14,
	RSVD6 = 15,
	FARAD = 16,
	HENRY = 17,
	METER = 18,
	KELVIN = 19,
	CELSIUS = 20,
	FAHRENHEIT = 21,
	GRAMM = 22,
	JOULE = 23,
	OHMMETER = 24,
	DEGREE = 25,
	RADIAN = 26,
	LUX = 27,
	LUMEN = 28,
	AMPEREHOUR = 29,
	DECIBEL = 30,
	DECIBELMW = 31,
	WATTM2 = 32,
	WATTH = 33,
	VAH = 34,
	VARH = 35,
	MINUTE = 36,
	HOUR = 37
}

interface RangeDef {
	range: number;
	fValue: number;
	multiplier: Multiplier;
	prefix: Prefix;
	unit: Unit;
}

const defaultRanges: RangeDef[] = [ // Display range from 0.00 to >999.99
	{ range: 0, fValue: 0.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
];

/*const voltageRanges: RangeDef[] = [ // unused
	{ range: 0, fValue: 0.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },
];*/

const voltageWaveRanges: RangeDef[] = [
	{ range: 0, fValue: -999999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },
	{ range: -1, fValue: 999999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },
];

const voltageRanges: RangeDef[] = [
	{ range: 0, fValue: 0.0000, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },	// 0.00 kV ... 1000 V
	{ range: 1, fValue: 1.0000, multiplier: Multiplier.N4, prefix: Prefix.KILO, unit: Unit.VOLT },	// 1.0000 kV ... 10.00000 kV
	{ range: 2, fValue: 10.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VOLT },	// 10.000 kV ... 99.999 kV
	{ range: -1, fValue: 99.99, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },	// >99.99 kV
];

/*const voltageDcVRanges: RangeDef[] = [ // unused
	{ range: 0, fValue: -999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },	// <-999.99 ... 999.99
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },	// >999.99
];

const voltageDcKvRanges: RangeDef[] = [ // unused
	{ range: 0, fValue: -999.99, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },	// <-999.99 ... 999.99
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },	// >999.99
];*/

const voltageDcRanges: RangeDef[] = [
	{ range: 0, fValue: -999.99, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },		// <-999.99 kV ... -100.00 kV
	{ range: 1, fValue: -100.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VOLT },	// -99.999 kV ... -10.000 kV
	{ range: 2, fValue: -10.0000, multiplier: Multiplier.N4, prefix: Prefix.KILO, unit: Unit.VOLT },	// -9.9999 kV ... -1.0000 kV
	{ range: 3, fValue: -1000.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.VOLT },	// -999.99 V ... 999.99 V
	{ range: 4, fValue: 1.0000, multiplier: Multiplier.N4, prefix: Prefix.KILO, unit: Unit.VOLT },		// 1.0000 kV ... 9.9999 kV
	{ range: 5, fValue: 10.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VOLT },		// 10.000 kV ... 99.999 kV
	{ range: 6, fValue: 100.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },		// 100.00 kV ... 999.99 kV
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VOLT },		// >999.99 kV
];

const currentRanges: RangeDef[] = [
	{ range: 0, fValue: 0.0000, multiplier: Multiplier.N4, prefix: Prefix.NONE, unit: Unit.AMPERE },	// 0.0000 ... 9.9999
	{ range: 1, fValue: 10.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.AMPERE },	// 10.000 ... 99.999
	{ range: 1, fValue: 100.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.AMPERE },	// 100.00 ... 999.99
	{ range: 2, fValue: 1000.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.AMPERE },	// 1000.0 ... 9999.9
	{ range: 3, fValue: 10000, multiplier: Multiplier.NONE, prefix: Prefix.NONE, unit: Unit.AMPERE },	// 10000 ... 99999
	{ range: -1, fValue: 99999, multiplier: Multiplier.NONE, prefix: Prefix.NONE, unit: Unit.AMPERE },	// >99999
];

const currentWaveRanges: RangeDef[] = [
	{ range: 0, fValue: -999999.9999, multiplier: Multiplier.N4, prefix: Prefix.NONE, unit: Unit.AMPERE },
	{ range: -1, fValue: 999999.9999, multiplier: Multiplier.N4, prefix: Prefix.NONE, unit: Unit.AMPERE },
];

const frequencyRanges: RangeDef[] = [ // Display range from <40.000 to >70.000
	{ range: 0, fValue: 40.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.HZ },
	{ range: -1, fValue: 70.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.HZ },
];

const frequencyWaveRanges: RangeDef[] = [ // Display range from <40.000 to >70.000
	{ range: 0, fValue: -999999.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.HZ },
	{ range: -1, fValue: 999999.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.HZ },
];

const angleRanges: RangeDef[] = [
	{ range: 0, fValue: -1080.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.DEGREE },
	{ range: -1, fValue: 1080.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.DEGREE },
];

const percentRanges: RangeDef[] = [
	{ range: 0, fValue: -999999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.PERCENT },
	{ range: -1, fValue: 999999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.PERCENT },
];

const flickerRanges: RangeDef[] = [
	{ range: 0, fValue: 0.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 1, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 2, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 999.9, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
];

const activePowerRanges: RangeDef[] = [ // Display range from <-999.9 to >999.9
	{ range: 0, fValue: -9999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.WATT },	// <-9999 ... -1000
	{ range: 1, fValue: -1000.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.WATT },	// -999.9 ... -100.0
	{ range: 2, fValue: -100.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.WATT },	// -99.99 ... -10.00
	{ range: 3, fValue: -10.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.WATT },	// -9.999 ...  9.999
	{ range: 4, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.WATT },	// 10.00 ...  99.99
	{ range: 5, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.WATT },	// 100.0 ...  999.9
	{ range: 6, fValue: 1000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.WATT },	// 1000 ...  9999
	{ range: -1, fValue: 9999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.WATT },	// >9999
];

const activePowerWaveRanges: RangeDef[] = [ // Display range from <-999.9 to >999.9
	{ range: 0, fValue: -999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.WATT },	// <-9999 ... -1000
	{ range: -1, fValue: 999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.WATT },	// >9999
];

const reactivePowerRanges: RangeDef[] = [
	{ range: 0, fValue: -9999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VAR },	// <-9999 ... -1000
	{ range: 1, fValue: -1000.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VAR },	// -999.9 ... -100.0
	{ range: 2, fValue: -100.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VAR },	// -99.99 ... -10.00
	{ range: 3, fValue: -10.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VAR },	// -9.999 ...  9.999
	{ range: 4, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VAR },	// 10.00 ...  99.99
	{ range: 5, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VAR },	// 100.0 ...  999.9
	{ range: 6, fValue: 1000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VAR },	// 1000 ...  9999
	{ range: -1, fValue: 9999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VAR },	// >9999
];

const reactivePowerWaveRanges: RangeDef[] = [
	{ range: 0, fValue: -999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.VAR },	// <-9999 ... -1000
	{ range: -1, fValue: 999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.VAR },	// >9999
];

const apparentPowerRanges: RangeDef[] = [
	{ range: 0, fValue: 0.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VA },		// 0.000 ... 9.999
	{ range: 1, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VA },		// 10.00 ... 99.99
	{ range: 2, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VA },		// 100.0 ... 999.9
	{ range: 3, fValue: 1000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VA },	// 1000 ...  9999
	{ range: -1, fValue: 999.9, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VA },	// >9999
];

const apparentPowerWaveRanges: RangeDef[] = [
	{ range: 0, fValue: -999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.VA }, // 0.000 ... 9.999
	{ range: -1, fValue: 999999.99, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.VA }, // >9999
];

const thdRanges: RangeDef[] = [
	{ range: 0, fValue: 0.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.PERCENT },
	{ range: 1, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.PERCENT },
	{ range: -1, fValue: 999.9, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.PERCENT },
];

const unbalanceRanges: RangeDef[] = [
	{ range: 0, fValue: 0.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.PERCENT },
	{ range: 1, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.PERCENT },
	{ range: -1, fValue: 999.9, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.PERCENT },
];

const dpfRanges: RangeDef[] = [
	{ range: 0, fValue: -1.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 1.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
];

const factorRanges: RangeDef[] = [
	{ range: 0, fValue: 0.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 999.99, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
];

const pfRanges: RangeDef[] = [
	{ range: 0, fValue: -1.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 1.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
];

const tangentRanges: RangeDef[] = [
	{ range: 0, fValue: -999.9, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 1, fValue: -100.0, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 2, fValue: -10.00, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 3, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 4, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 999.9, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
];

const activeEnergyRanges: RangeDef[] = [
	{ range: 0, fValue: 0.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.WATTH },
	{ range: 1, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.WATTH },
	{ range: 2, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.WATTH },
	{ range: 3, fValue: 10000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.WATTH },
	{ range: -1, fValue: 999999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.WATTH },
];

const reactiveEnergyRanges: RangeDef[] = [
	{ range: 0, fValue: 0.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VARH },
	{ range: 1, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VARH },
	{ range: 2, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VARH },
	{ range: 3, fValue: 10000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VARH },
	{ range: -1, fValue: 999999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VARH },
];

const apparentEnergyRanges: RangeDef[] = [
	{ range: 0, fValue: 0.000, multiplier: Multiplier.N3, prefix: Prefix.KILO, unit: Unit.VAH },
	{ range: 1, fValue: 10.00, multiplier: Multiplier.N2, prefix: Prefix.KILO, unit: Unit.VAH },
	{ range: 2, fValue: 100.0, multiplier: Multiplier.N1, prefix: Prefix.KILO, unit: Unit.VAH },
	{ range: 3, fValue: 10000, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VAH },
	{ range: -1, fValue: 999999, multiplier: Multiplier.NONE, prefix: Prefix.KILO, unit: Unit.VAH },
];

const calibrationRanges: RangeDef[] = [
	{ range: 0, fValue: -99999, multiplier: Multiplier.NONE, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 1, fValue: -1000.0, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 2, fValue: -100.00, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 3, fValue: -10.000, multiplier: Multiplier.N4, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 4, fValue: -1.0000, multiplier: Multiplier.N5, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 5, fValue: 1.0000, multiplier: Multiplier.N4, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 6, fValue: 10.000, multiplier: Multiplier.N3, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 7, fValue: 100.00, multiplier: Multiplier.N2, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: 8, fValue: 1000.0, multiplier: Multiplier.N1, prefix: Prefix.NONE, unit: Unit.NONE },
	{ range: -1, fValue: 99999, multiplier: Multiplier.NONE, prefix: Prefix.NONE, unit: Unit.NONE },
];

const MAX_RANGE = 127;
const EMPTY_STRING = "---";
const _STRING_MAX_LEN = 15;

const prefixValues: number[] = [
	1, 1e-18, 1e-15, 1e-12, 1e-9, 1e-6, 1e-3, 1e-2, 1e-1, 1e1, 1e2, 1e3, 1e6, 1e9, 1e12, 1e15,
];

const multiplierValues: number[] = [
	1e9, 1e8, 1e7, 1e6, 1e5, 1e4, 1e3, 1e2, 1e1, 1, 1e-1, 1e-2, 1e-3, 1e-4, 1e-5, 1e-6,
];

interface Flags {
	flag_empty: boolean;
	flag_greater: boolean;
	flag_smaller: boolean;
}

interface FormattedData {
	lValue: number;
	prefix: Prefix;
	multiplier: Multiplier;
	unit: Unit;
	flags?: Flags;
}

export const formatValueToDeciSON = (value: Nullable<number>, category: MeasurementCategory): Nullable<string> => {

	if (value === undefined || value === null) return null;
	let rangeDefs: RangeDef[] = matchRangeDef(category);

	return toString(make(value, rangeDefs));
};

export const formatValueWithoutChanges = (value: Nullable<number>, unit: Nullable<string>): Nullable<string> => {

	if (value === undefined || value === null) return undefined;
	if (unit == undefined || unit == null) unit = "";
	let valueStr = value.toFixed(2);
	// let valueStr = value.toString();

	return valueStr + " " + unit;
};

export const formatValueToFullDeciSON = (value: Nullable<number>, unit: Nullable<string>, category: MeasurementCategory): Nullable<string> => {

	if (value === undefined || value === null) return null;
	let rangeDefs: RangeDef[] = matchRangeDef(category);

	let valueStr = toString(make(value, rangeDefs));
	let prefixAndUnit = getPrefixToUnit(value, unit, category);

	if (prefixAndUnit == null || prefixAndUnit == undefined) return valueStr;

	return valueStr + " " + prefixAndUnit;
};

export const formatValueToDeciSONlValue = (value: Nullable<number>, category: MeasurementCategory): Nullable<number> => {

	if (value === undefined || value === null) return null;
	let rangeDefs: RangeDef[] = matchRangeDef(category);

	let dsValue: FormattedData = make(value, rangeDefs);

	if (dsValue.multiplier != Multiplier.NONE) {
		if (dsValue.multiplier < Multiplier.NONE) {
			return dsValue.lValue / 10 ** (Multiplier.NONE - dsValue.multiplier);
		} else {
			return dsValue.lValue * 10 ** (dsValue.multiplier - Multiplier.NONE);
		}
	}

	return dsValue.lValue;
};

export const getPrefixToUnit = (value: Nullable<number>, unit: Nullable<string>, category: MeasurementCategory): Nullable<string> => {

	let rangeDefs: RangeDef[] = matchRangeDef(category);
	let prefixStr: string;
	let unitWithPrefix: string;

	if (unit === undefined || unit === null) return "";
	if (value === undefined || value === null) return "";
	let dsValue: FormattedData = make(value, rangeDefs);
	let prefix: Prefix = dsValue.prefix;

	if (prefix == Prefix.NONE) {
		prefixStr = "";
	} else if (prefix == Prefix.ATTO) {
		prefixStr = "a";
	} else if (prefix == Prefix.FEMTO) {
		prefixStr = "f";
	} else if (prefix == Prefix.PICO) {
		prefixStr = "p";
	} else if (prefix == Prefix.NANO) {
		prefixStr = "n";
	} else if (prefix == Prefix.MICRO) {
		prefixStr = "µ";
	} else if (prefix == Prefix.MILLI) {
		prefixStr = "m";
	} else if (prefix == Prefix.CENTI) {
		prefixStr = "c";
	} else if (prefix == Prefix.DECI) {
		prefixStr = "d";
	} else if (prefix == Prefix.DECA) {
		prefixStr = "da";
	} else if (prefix == Prefix.HECTO) {
		prefixStr = "h";
	} else if (prefix == Prefix.KILO) {
		prefixStr = "k";
	} else if (prefix == Prefix.MEGA) {
		prefixStr = "M";
	} else if (prefix == Prefix.GIGA) {
		prefixStr = "G";
	} else if (prefix == Prefix.TERA) {
		prefixStr = "T";
	} else if (prefix == Prefix.PETA) {
		prefixStr = "P";
	} else {
		prefixStr = "";
	}

	if (unit == "%" || unit == "°") {
		prefixStr = "";
	}

	unitWithPrefix = prefixStr + unit;

	return unitWithPrefix;
};

function matchRangeDef(category: MeasurementCategory): RangeDef[] {
	let rangeDefs: RangeDef[];

	if (category === MeasurementCategory.VOLTAGES) {
		rangeDefs = voltageRanges;
	} else if (category === MeasurementCategory.VOLTAGES_DC) {
		rangeDefs = voltageDcRanges;
	} else if (category === MeasurementCategory.CURRENTS) {
		rangeDefs = currentRanges;
	} else if (category === MeasurementCategory.ACTIVE_POWERS) {
		rangeDefs = activePowerRanges;
	} else if (category === MeasurementCategory.REACTIVE_POWERS) {
		rangeDefs = reactivePowerRanges;
	} else if (category === MeasurementCategory.APPARENT_POWERS) {
		rangeDefs = apparentPowerRanges;
	} else if (category === MeasurementCategory.FREQUENCIES) {
		rangeDefs = frequencyRanges;
	} else if (category === MeasurementCategory.HARMONICS) {
		rangeDefs = thdRanges;
	} else if (category === MeasurementCategory.INTERHARMONICS) {
		rangeDefs = thdRanges;
	} else if (category === MeasurementCategory.ACTIVE_ENERGY) {
		rangeDefs = activeEnergyRanges;
	} else if (category === MeasurementCategory.REACTIVE_ENERGY) {
		rangeDefs = reactiveEnergyRanges;
	} else if (category === MeasurementCategory.APPARENT_ENERGY) {
		rangeDefs = apparentEnergyRanges;
	} else if (category === MeasurementCategory.TANGENT) {
		rangeDefs = tangentRanges;
	} else if (category === MeasurementCategory.POWER_FACTOR) {
		rangeDefs = pfRanges;
	} else if (category === MeasurementCategory.FLICKER) {
		rangeDefs = flickerRanges;
	} else if (category === MeasurementCategory.FACTOR) {
		rangeDefs = factorRanges;
	} else if (category === MeasurementCategory.PHASORS) {
		rangeDefs = unbalanceRanges;
	} else if (category === MeasurementCategory.VOLTAGES_WAVE) {
		rangeDefs = voltageWaveRanges;
	} else if (category === MeasurementCategory.CURRENTS_WAVE) {
		rangeDefs = currentWaveRanges;
	} else if (category === MeasurementCategory.FREQUENCIES_WAVE) {
		rangeDefs = frequencyWaveRanges;
	} else if (category === MeasurementCategory.ACTIVE_POWERS_WAVE) {
		rangeDefs = activePowerWaveRanges;
	} else if (category === MeasurementCategory.REACTIVE_POWERS_WAVE) {
		rangeDefs = reactivePowerWaveRanges;
	} else if (category === MeasurementCategory.APPARENT_POWERS_WAVE) {
		rangeDefs = apparentPowerWaveRanges;
	} else if (category === MeasurementCategory.ANGLES) {
		rangeDefs = angleRanges;
	} else if (category === MeasurementCategory.PERCENT) {
		rangeDefs = percentRanges;
	} else {
		rangeDefs = defaultRanges;
	}

	return rangeDefs;
}

function make(fVal: number, rangeDefs: RangeDef[]): FormattedData {
	let range = 0;
	let uOverrangeIndex;
	let lValue;
	let prefix;
	let multiplier;
	let unit;
	let flags: Flags = {
		flag_empty: false,
		flag_greater: false,
		flag_smaller: false,
	};

	if (isNaN(fVal)) {
		lValue = 0;
		prefix = rangeDefs[ range ].prefix;
		multiplier = rangeDefs[ range ].multiplier;
		unit = rangeDefs[ range ].unit;
		flags.flag_empty = true;
	} else {
		while (rangeDefs[ range ].range !== -1 && range < MAX_RANGE) range++;
		if (range >= MAX_RANGE) throw new Error("Error in range");

		uOverrangeIndex = range + 1;

		for (range = 0 ; range <= uOverrangeIndex ; range++) {
			if (rangeDefs[ range ].fValue !== 0) {
				let fRangeThreshold = rangeDefs[ range ].fValue * prefixValues[ rangeDefs[ range ].prefix ];
				let fRound = (range === 0 || fVal < 0.0) ?
					prefixValues[ rangeDefs[ range ].prefix ] / multiplierValues[ rangeDefs[ range ].multiplier ] * 0.5 :
					prefixValues[ rangeDefs[ range - 1 ].prefix ] / multiplierValues[ rangeDefs[ range - 1 ].multiplier ] * 0.5;

				fRangeThreshold += (fVal < 0.0 || rangeDefs[ range ].range === -1) ? fRound : -fRound;

				if ((fVal < 0.0 && fVal <= fRangeThreshold) || (fVal >= 0.0 && fVal < fRangeThreshold)) {
					if (range) {
						range--;
					} else {
						flags.flag_smaller = true;
					}
					break;
				} else if (rangeDefs[ range ].range === -1) {
					flags.flag_greater = true;
					break;
				}
			}
		}

		if (rangeDefs[ range ].range === -1 && !flags.flag_greater) throw new Error("Error in range");

		if (flags.flag_greater || flags.flag_smaller) {
			lValue = rangeDefs[ range ].fValue * multiplierValues[ rangeDefs[ range ].multiplier ];
		} else {
			let fdS = fVal * multiplierValues[ rangeDefs[ range ].multiplier ] / prefixValues[ rangeDefs[ range ].prefix ];
			if (fdS < rangeDefs[ range ].fValue * multiplierValues[ rangeDefs[ range ].multiplier ]) {
				fdS = rangeDefs[ range ].fValue * multiplierValues[ rangeDefs[ range ].multiplier ];
			}
			lValue = Math.round(fdS);
		}

		prefix = rangeDefs[ range ].prefix;
		multiplier = rangeDefs[ range ].multiplier;
		unit = rangeDefs[ range ].unit;
	}

	return { lValue, prefix, multiplier, unit, flags };
}

function toString(data: FormattedData): string {
	let result = "";
	let { lValue, prefix, multiplier, unit, flags } = data;

	if (flags?.flag_empty) {
		return EMPTY_STRING;
	}

	if (flags?.flag_greater) {
		result += ">";
	} else if (flags?.flag_smaller) {
		result += "<";
	}

	if (lValue < 0) {
		result += "-";
		lValue = -lValue;
	}

	let tempStr = lValue.toString();
	let dSmultiplier = multiplier;

	if (Multiplier.NONE - dSmultiplier >= tempStr.length) {
		result += "0.";
		for (let i = 0 ; i < Multiplier.NONE - dSmultiplier - tempStr.length ; i++) {
			result += "0";
		}
		result += tempStr;
	} else if (dSmultiplier < Multiplier.NONE) {
		for (let i = 0 ; i < tempStr.length ; i++) {
			if (tempStr.length + dSmultiplier - Multiplier.NONE === i) {
				result += ".";
			}
			result += tempStr[ i ];
		}
	} else {
		for (let i = 0 ; i < tempStr.length + dSmultiplier - Multiplier.NONE ; i++) {
			if (i >= tempStr.length) {
				result += "0";
			} else {
				result += tempStr[ i ];
			}
		}
	}

	if (result.length > _STRING_MAX_LEN) {
		result = result.substring(0, _STRING_MAX_LEN);
	}

	return result;
}
