import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, handleBasicActionsForArray, initialStateReducer } from "src/app/utils/redux";
import { ArrayStateReducer, DataState, StateReducer } from "src/app/types/redux.types";
import { EventWaveform, RmsWaveform, Summary } from "src/app/types/api/dashboard.types";
import { fetchNewStandardEventsAsync, fetchNewUserEventsAsync, fetchStandardEventByIdAsync, fetchStandardEventsAsync, fetchStandardEventsWithOffsetAsync, fetchStandardEventWaveformAsync, fetchStandardRmsAsync, fetchSummaryAsync, fetchUserEventByIdAsync, fetchUserEventsAsync, fetchUserEventsWithOffsetAsync, fetchUserEventWaveformAsync, fetchUserRmsAsync } from "src/app/store/features/dashboard/dashboard.actions";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { SingleEvent, StandardEvents, UserEvents } from "src/app/types/api/event.types";

export const EVENTS_PACKAGE_AMOUNT = 100;
export const EVENTS_MAX_PACKAGES = 10;

const reducer = combineReducers({
	summary: createReducer(initialStateReducer as StateReducer<Summary>)
		.handleAction([ fetchSummaryAsync.request, fetchSummaryAsync.success, fetchSummaryAsync.failure ], handleBasicActions(fetchSummaryAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	standardEvents: createReducer(initialStateReducer as StateReducer<StandardEvents>)
		.handleAction([ fetchStandardEventsAsync.request, fetchStandardEventsAsync.success, fetchStandardEventsAsync.failure ], handleBasicActions(fetchStandardEventsAsync))
		.handleAction([ fetchStandardEventsWithOffsetAsync.success, fetchNewStandardEventsAsync.success ], (state, action) => {
			if (state.dataState !== DataState.PRESENT) return state;

			const existingIds = state.data.events.event.map(event => event.id);
			const newEvents = action.payload.data.events.event
									.filter(event =>
										!existingIds.includes(event.id),
									);
			return {
				...state,
				data: {
					events: {
						...action.payload.data.events,
						event: [
							...state.data.events.event,
							...newEvents,
						].sort((a, b) => (b.timestampStart ?? 0) - (a.timestampStart ?? 0)),
					},
				},
			};
		})
		.handleAction([ forgetSession ], () => initialStateReducer),
	hasMoreStandardEvents: createReducer(false)
		.handleAction([ fetchStandardEventsAsync.request ], () => true)
		.handleAction([ fetchStandardEventsAsync.success, fetchStandardEventsWithOffsetAsync.success ], (state, action) => state ? action.payload.data.events.event.length === EVENTS_PACKAGE_AMOUNT : state)
		.handleAction([ forgetSession ], () => false),
	userEvents: createReducer(initialStateReducer as StateReducer<UserEvents>)
		.handleAction([ fetchUserEventsAsync.request, fetchUserEventsAsync.success, fetchUserEventsAsync.failure ], handleBasicActions(fetchUserEventsAsync))
		.handleAction([ fetchUserEventsWithOffsetAsync.success, fetchNewUserEventsAsync.success ], (state, action) => {
			if (state.dataState !== DataState.PRESENT) return state;

			const existingIds = state.data.events.event.map(event => event.id);
			const newEvents = action.payload.data.events.event
									.filter(event =>
										!existingIds.includes(event.id),
									);
			return {
				...state,
				data: {
					events: {
						...action.payload.data.events,
						event: [
							...state.data.events.event,
							...newEvents,
						].sort((a, b) => (b.timestampStart ?? 0) - (a.timestampStart ?? 0)),
					},
				},
			};
		})
		.handleAction([ forgetSession ], () => initialStateReducer),
	hasMoreUserEvents: createReducer(false)
		.handleAction([ fetchUserEventsAsync.request ], () => true)
		.handleAction([ fetchUserEventsAsync.success, fetchUserEventsWithOffsetAsync.success ], (state, action) => state ? action.payload.data.events.event.length === EVENTS_PACKAGE_AMOUNT : state)
		.handleAction([ forgetSession ], () => false),
	singleStandardEvents: createReducer([] as ArrayStateReducer<SingleEvent>)
		.handleAction([ fetchStandardEventByIdAsync.request, fetchStandardEventByIdAsync.success, fetchStandardEventByIdAsync.failure ], handleBasicActionsForArray(fetchStandardEventByIdAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
	singleUserEvents: createReducer([] as ArrayStateReducer<SingleEvent>)
		.handleAction([ fetchUserEventByIdAsync.request, fetchUserEventByIdAsync.success, fetchUserEventByIdAsync.failure ], handleBasicActionsForArray(fetchUserEventByIdAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
	standardEventsWaveform: createReducer([] as ArrayStateReducer<EventWaveform>)
		.handleAction([ fetchStandardEventWaveformAsync.request, fetchStandardEventWaveformAsync.success, fetchStandardEventWaveformAsync.failure ], handleBasicActionsForArray(fetchStandardEventWaveformAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
	userEventsWaveform: createReducer([] as ArrayStateReducer<EventWaveform>)
		.handleAction([ fetchUserEventWaveformAsync.request, fetchUserEventWaveformAsync.success, fetchUserEventWaveformAsync.failure ], handleBasicActionsForArray(fetchUserEventWaveformAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
	standardEventRmsWaveform: createReducer([] as ArrayStateReducer<RmsWaveform>)
		.handleAction([ fetchStandardRmsAsync.request, fetchStandardRmsAsync.success, fetchStandardRmsAsync.failure ], handleBasicActionsForArray(fetchStandardRmsAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
	userEventRmsWaveform: createReducer([] as ArrayStateReducer<RmsWaveform>)
		.handleAction([ fetchUserRmsAsync.request, fetchUserRmsAsync.success, fetchUserRmsAsync.failure ], handleBasicActionsForArray(fetchUserRmsAsync, request => request.event_id))
		.handleAction([ forgetSession ], () => []),
});

export default reducer;
