import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "src/app/translations/en.json";
import translationPL from "src/app/translations/pl.json";
import translationES from "src/app/translations/es.json";
import translationDE from "src/app/translations/de.json";
import { LanguageCode } from "src/app/translations/utils";

i18n
	.use(initReactI18next)
	.init({
		resources: {
			[ LanguageCode.EN ]: { translation: translationEN },
			[ LanguageCode.PL ]: { translation: translationPL },
			[ LanguageCode.ES ]: { translation: translationES },
			[ LanguageCode.DE ]: { translation: translationDE },
		},
		lng: LanguageCode.EN,
		fallbackLng: LanguageCode.EN,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
