import { DataWaveformsLive } from "src/app/types/api/ws.types";
import { Nullable } from "src/app/types/util.types";
import { useEffect, useRef, useState } from "react";
import { ElementSize, LayoutVariant } from "src/app/types/ui/layout.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Button, Card } from "flowbite-react";
import classNames from "classnames";
import { MdOutlineFileUpload } from "react-icons/md";
import WaveformChart, { WaveformChartData } from "src/app/components/Chart/WaveformChart.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import moment from "moment";
import { saveStockchart } from "src/app/utils/helpers";
import { formatValueToDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import { layoutVariantDictionary, measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import Table from "src/app/components/Utils/Table.component";
import { MeasurementCategory } from "src/app/types/misc.types";
import ChartFilter, { ChartFilterColor } from "src/app/components/Utils/ChartFilter.component";
import { useTranslation } from "react-i18next";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		lastRecord: Nullable<DataWaveformsLive>
	};

export enum WaveformLine {
	U1 = "U1",
	U2 = "U2",
	U3 = "U3",
	U12 = "U12",
	U23 = "U23",
	U31 = "U31",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3",
	I4 = "I4",
	I5 = "I5",
}

export type WaveformConfigState = {
	[K in WaveformLine]: boolean
}

const MAIN_PADDING = 32;

const BUTTON_GROUP_HEIGHT = 38;
const SPACING = 16;
const CARD_PADDING = 24 + 24 + 24 + 24;
const CARD_BORDER = 2 + 1;
const CARD_TITLE_HEIGHT = 40;
const CARD_SPACING = 16;
const CHART_FILTERS_HEIGHT = 34;

type TableRecord = {
	name: string | ReactNode
	value: Nullable<string>
	unit: Nullable<string>
	category: MeasurementCategory
}

function WaveformContainer(props: Props) {

	const { t } = useTranslation();

	const {
		lastRecord,
		isSidebarOpen,
		mainSize,
	} = props;

	const cardRef = useRef<HTMLDivElement>(null);

	const [ cardSize, setCardSize ] = useState<ElementSize>({
		width: 0,
		height: 600,
	});
	const [ variant, setVariant ] = useState(LayoutVariant.ALL);

	useEffect(() => {
		const chartHeight = mainSize.height - BUTTON_GROUP_HEIGHT - SPACING - CARD_PADDING - CARD_BORDER - CARD_TITLE_HEIGHT - CARD_SPACING - CHART_FILTERS_HEIGHT;

		setCardSize(prevState => {
			if (isNotNull(cardRef.current)) {
				return {
					width: cardRef.current.getBoundingClientRect().width,
					height: chartHeight > 0 ? chartHeight : prevState.height,
				};
			}

			return {
				...prevState,
				height: chartHeight > 0 ? chartHeight : prevState.height,
			};
		});
	}, [ mainSize.width, mainSize.height, isSidebarOpen, variant ]);

	const [ waveforms, setWaveForms ] = useState<WaveformConfigState>({
		[ WaveformLine.U1 ]: isNotNull(lastRecord?.waveforms?.U1N),
		[ WaveformLine.U2 ]: isNotNull(lastRecord?.waveforms?.U2N),
		[ WaveformLine.U3 ]: isNotNull(lastRecord?.waveforms?.U3N),
		[ WaveformLine.U12 ]: isNotNull(lastRecord?.waveforms?.U12),
		[ WaveformLine.U23 ]: isNotNull(lastRecord?.waveforms?.U23),
		[ WaveformLine.U31 ]: isNotNull(lastRecord?.waveforms?.U31),
		[ WaveformLine.I1 ]: isNotNull(lastRecord?.waveforms?.I1),
		[ WaveformLine.I2 ]: isNotNull(lastRecord?.waveforms?.I2),
		[ WaveformLine.I3 ]: isNotNull(lastRecord?.waveforms?.I3),
		[ WaveformLine.I4 ]: isNotNull(lastRecord?.waveforms?.I4),
		[ WaveformLine.I5 ]: isNotNull(lastRecord?.waveforms?.I5),
	});

	const [ isInitialSetupSet, toggleIsInitialSetupSet ] = useState(false);

	const isLastRecord = isNotNull(lastRecord);
	useEffect(() => {
		if (isNotNull(lastRecord) && !isInitialSetupSet) {
			setWaveForms({
				[ WaveformLine.U1 ]: isNotNull(lastRecord?.waveforms?.U1N),
				[ WaveformLine.U2 ]: isNotNull(lastRecord?.waveforms?.U2N),
				[ WaveformLine.U3 ]: isNotNull(lastRecord?.waveforms?.U3N),
				[ WaveformLine.U12 ]: isNotNull(lastRecord?.waveforms?.U12),
				[ WaveformLine.U23 ]: isNotNull(lastRecord?.waveforms?.U23),
				[ WaveformLine.U31 ]: isNotNull(lastRecord?.waveforms?.U31),
				[ WaveformLine.I1 ]: isNotNull(lastRecord?.waveforms?.I1),
				[ WaveformLine.I2 ]: isNotNull(lastRecord?.waveforms?.I2),
				[ WaveformLine.I3 ]: isNotNull(lastRecord?.waveforms?.I3),
				[ WaveformLine.I4 ]: isNotNull(lastRecord?.waveforms?.I4),
				[ WaveformLine.I5 ]: isNotNull(lastRecord?.waveforms?.I5),
			});
			toggleIsInitialSetupSet(true);
		}
	}, [ isLastRecord ]);

	const main = document.querySelector("main");

	const _getData = (): WaveformChartData[] => {
		if (isNull(lastRecord)) return [];

		const minLength = Math.min(...[
			isNotNull(lastRecord?.waveforms?.U1N) ? lastRecord.waveforms.U1N?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.U2N) ? lastRecord.waveforms.U2N?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.U3N) ? lastRecord.waveforms.U3N?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.U12) ? lastRecord.waveforms.U12?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.U23) ? lastRecord.waveforms.U23?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.U31) ? lastRecord.waveforms.U31?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.I1) ? lastRecord.waveforms.I1?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.I2) ? lastRecord.waveforms.I2?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.I3) ? lastRecord.waveforms.I3?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.I4) ? lastRecord.waveforms.I4?.value?.length : undefined,
			isNotNull(lastRecord?.waveforms?.I5) ? lastRecord.waveforms.I5?.value?.length : undefined,
		].filter(isNotNull));
		const data: WaveformChartData[] = [];

		for (let i = 0 ; i < minLength ; i++) {
			data.push({
				U1: lastRecord.waveforms.U1N?.value?.[ i ],
				U2: lastRecord.waveforms.U2N?.value?.[ i ],
				U3: lastRecord.waveforms.U3N?.value?.[ i ],
				U12: lastRecord.waveforms.U12?.value?.[ i ],
				U23: lastRecord.waveforms.U23?.value?.[ i ],
				U31: lastRecord.waveforms.U31?.value?.[ i ],
				I1: lastRecord.waveforms.I1?.value?.[ i ],
				I2: lastRecord.waveforms.I2?.value?.[ i ],
				I3: lastRecord.waveforms.I3?.value?.[ i ],
				I4: lastRecord.waveforms.I4?.value?.[ i ],
				I5: lastRecord.waveforms.I5?.value?.[ i ],
				index: i,
			});
		}
		return data;
	};

	const data: TableRecord[] = [
		isNotNull(lastRecord?.basic?.frequency200ms) ? {
			name: <Translate i18nKey="UTILS.frequency"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.frequency200ms?.value, MeasurementCategory.FREQUENCIES),
			unit: getPrefixToUnit(lastRecord?.basic?.frequency200ms?.value, lastRecord?.basic?.frequency200ms?.unit, MeasurementCategory.FREQUENCIES),
			category: MeasurementCategory.FREQUENCIES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U1n_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U1N"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U1n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U1n_rms?.value, lastRecord?.basic?.U1n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U2n_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U2N"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U2n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U2n_rms?.value, lastRecord?.basic?.U2n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U3n_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U3N"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U3n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U2n_rms?.value, lastRecord?.basic?.U3n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U12_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U12"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U12_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U12_rms?.value, lastRecord?.basic?.U12_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U23_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U23"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U23_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U23_rms?.value, lastRecord?.basic?.U23_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.U31_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.U31"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.U31_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(lastRecord?.basic?.U31_rms?.value, lastRecord?.basic?.U31_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isNotNull(lastRecord?.basic?.I1_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.I1"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.I1_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(lastRecord?.basic?.I1_rms?.value, lastRecord?.basic?.I1_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isNotNull(lastRecord?.basic?.I2_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.I2"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.I2_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(lastRecord?.basic?.I2_rms?.value, lastRecord?.basic?.I2_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isNotNull(lastRecord?.basic?.I3_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.I3"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.I3_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(lastRecord?.basic?.I3_rms?.value, lastRecord?.basic?.I3_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isNotNull(lastRecord?.basic?.In_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.I4"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.In_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(lastRecord?.basic?.In_rms?.value, lastRecord?.basic?.In_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isNotNull(lastRecord?.basic?.Ie_rms) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.I5"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.Ie_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(lastRecord?.basic?.Ie_rms?.value, lastRecord?.basic?.Ie_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L1_P) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.PL1"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L1_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L1_P?.value, lastRecord?.basic?.L1_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L2_P) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.PL2"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L2_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L2_P?.value, lastRecord?.basic?.L2_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L3_P) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.PL3"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L3_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L3_P?.value, lastRecord?.basic?.L3_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L1_Q) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.QL1"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L1_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L1_Q?.value, lastRecord?.basic?.L1_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L2_Q) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.QL2"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L2_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L2_Q?.value, lastRecord?.basic?.L2_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L3_Q) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.QL3"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L3_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L3_Q?.value, lastRecord?.basic?.L3_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L1_S) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.SL1"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L1_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L1_S?.value, lastRecord?.basic?.L1_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L2_S) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.SL2"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L2_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L2_S?.value, lastRecord?.basic?.L2_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
		isNotNull(lastRecord?.basic?.L3_S) ? {
			name: <Translate className="whitespace-nowrap" i18nKey="TABLES.SL3"/>,
			value: formatValueToDeciSON(lastRecord?.basic?.L3_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(lastRecord?.basic?.L3_S?.value, lastRecord?.basic?.L3_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
	].filter(isNotNull);

	const _exportToCsv = () => {
		const data = _getData();
		const rows = [
			[
				t("TABLE.index") + "[ms]",
				waveforms.U1 ? `U1[${ lastRecord?.waveforms?.U1N?.unit ?? "V" }]` : null,
				waveforms.U2 ? `U2[${ lastRecord?.waveforms?.U2N?.unit ?? "V" }]` : null,
				waveforms.U3 ? `U3[${ lastRecord?.waveforms?.U3N?.unit ?? "V" }]` : null,
				waveforms.U12 ? `U12[${ lastRecord?.waveforms?.U12?.unit ?? "V" }]` : null,
				waveforms.U23 ? `U23[${ lastRecord?.waveforms?.U23?.unit ?? "V" }]` : null,
				waveforms.U31 ? `U31[${ lastRecord?.waveforms?.U31?.unit ?? "V" }]` : null,
				waveforms.I1 ? `I1[${ lastRecord?.waveforms?.I1?.unit ?? "A" }]` : null,
				waveforms.I2 ? `I2[${ lastRecord?.waveforms?.I2?.unit ?? "A" }]` : null,
				waveforms.I3 ? `I3[${ lastRecord?.waveforms?.I3?.unit ?? "A" }]` : null,
				waveforms.I4 ? `I4[${ lastRecord?.waveforms?.I4?.unit ?? "A" }]` : null,
				waveforms.I5 ? `I5[${ lastRecord?.waveforms?.I5?.unit ?? "A" }]` : null,
			].filter(isNotNull),
			...data
				.map(dataItem =>
					[
						`"${ (dataItem.index * (lastRecord?.waveforms?.resolution?.value ?? 0)).toFixed(6).replace(".", ",") }"`,
						waveforms.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.U12 ? `"${ (dataItem.U12 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.U23 ? `"${ (dataItem.U23 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.U31 ? `"${ (dataItem.U31 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.I4 ? `"${ (dataItem.I4 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						waveforms.I5 ? `"${ (dataItem.I5 ?? 0).toFixed(6).replace(".", ",") }"` : null,
					]
						.filter(isNotNull),
				),
		];

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `waveform_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const isTableLoading = isNull(lastRecord);

	return (
		<div className="flex flex-col gap-4">
			<Button.Group>
				{
					Object.values(LayoutVariant).map(layoutVariant =>
						<Button
							key={ layoutVariant }
							color={ variant === layoutVariant ? "primary" : "gray" }
							onClick={ () => setVariant(layoutVariant) }
							pill
							size="sm"
						>
							{ layoutVariantDictionary()[ layoutVariant ] }
						</Button>,
					)
				}
			</Button.Group>
			<div className="grid grid-cols-3 gap-4">
				<div
					className={
						classNames(
							{ "col-span-2": variant === LayoutVariant.ALL },
							{ "col-span-3": variant === LayoutVariant.CHART },
						)
					}
				>
					{
						variant !== LayoutVariant.TABLE &&
                        <Card className="[&>div]:p-0 [&>div]:gap-0">
                            <div className="p-3 sm:p-6 flex justify-between items-center gap-2 pb-4 border-b border-gray-200">
                                <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
									{ t("SIDEBAR.waveforms") }
                                </h5>
                                <Dropdown
                                    size="sm"
                                    color="transparent"
                                    label={
										<>
											<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
											{ t("UTILS.export") }
										</>
									}
                                >
                                    <DropdownItem
                                        onClick={ () => {
											const name = `PQM750_waveform_${ moment().format("MM-DD-HH-mm-ss") }`;
											saveStockchart(name, cardRef.current);
										} }
                                    >
										{ t("UTILS.png") }
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={ _exportToCsv }
                                    >
										{ t("UTILS.csv") }
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <div className="p-3 sm:p-6 flex flex-col gap-4">
								{
									isInitialSetupSet &&
                                    <div className="flex items-center justify-center gap-x-8 gap-y-2.5 flex-wrap">
										{
											(
												isNotNull(lastRecord?.waveforms?.U1N) ||
												isNotNull(lastRecord?.waveforms?.U2N) ||
												isNotNull(lastRecord?.waveforms?.U3N) ||
												isNotNull(lastRecord?.waveforms?.U12) ||
												isNotNull(lastRecord?.waveforms?.U23) ||
												isNotNull(lastRecord?.waveforms?.U31)
											) &&
                                            <div className="flex gap-2.5 items-center">
                                                <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
												{
													isNotNull(lastRecord?.waveforms?.U1N) &&
                                                    <ChartFilter
                                                        text="U1"
                                                        color={ ChartFilterColor.U1 }
                                                        active={ waveforms.U1 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U1 ]: !prevState.U1,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.U2N) &&
                                                    <ChartFilter
                                                        text="U2"
                                                        color={ ChartFilterColor.U2 }
                                                        active={ waveforms.U2 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U2 ]: !prevState.U2,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.U3N) &&
                                                    <ChartFilter
                                                        text="U3"
                                                        color={ ChartFilterColor.U3 }
                                                        active={ waveforms.U3 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U3 ]: !prevState.U3,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.U12) &&
                                                    <ChartFilter
                                                        text="U12"
                                                        color={ ChartFilterColor.U12 }
                                                        active={ waveforms.U12 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U12 ]: !prevState.U12,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.U23) &&
                                                    <ChartFilter
                                                        text="U23"
                                                        color={ ChartFilterColor.U23 }
                                                        active={ waveforms.U23 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U23 ]: !prevState.U23,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.U31) &&
                                                    <ChartFilter
                                                        text="U31"
                                                        color={ ChartFilterColor.U31 }
                                                        active={ waveforms.U31 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.U31 ]: !prevState.U31,
															}))
														}
                                                    />
												}
                                            </div>
										}
										{
											(
												isNotNull(lastRecord?.waveforms?.I1) ||
												isNotNull(lastRecord?.waveforms?.I2) ||
												isNotNull(lastRecord?.waveforms?.I3) ||
												isNotNull(lastRecord?.waveforms?.I4) ||
												isNotNull(lastRecord?.waveforms?.I5)
											) &&
                                            <div className="flex gap-2.5 items-center">
                                                <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
												{
													isNotNull(lastRecord?.waveforms?.I1) &&
                                                    <ChartFilter
                                                        text="I1"
                                                        color={ ChartFilterColor.I1 }
                                                        active={ waveforms.I1 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.I1 ]: !prevState.I1,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.I2) &&
                                                    <ChartFilter
                                                        text="I2"
                                                        color={ ChartFilterColor.I2 }
                                                        active={ waveforms.I2 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.I2 ]: !prevState.I2,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.I3) &&
                                                    <ChartFilter
                                                        text="I3"
                                                        color={ ChartFilterColor.I3 }
                                                        active={ waveforms.I3 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.I3 ]: !prevState.I3,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.I4) &&
                                                    <ChartFilter
                                                        text="I4"
                                                        color={ ChartFilterColor.In }
                                                        active={ waveforms.I4 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.I4 ]: !prevState.I4,
															}))
														}
                                                    />
												}
												{
													isNotNull(lastRecord?.waveforms?.I5) &&
                                                    <ChartFilter
                                                        text="I5"
                                                        color={ ChartFilterColor.Ie }
                                                        active={ waveforms.I5 }
                                                        onClick={ () =>
															setWaveForms(prevState => ({
																...prevState,
																[ WaveformLine.I5 ]: !prevState.I5,
															}))
														}
                                                    />
												}
                                            </div>
										}
                                    </div>
								}
                                <div ref={ cardRef }>
                                    <div
                                        onMouseEnter={ () => {
											if (isNotNull(main)) {
												const scrollWidth = main.offsetWidth - main.clientWidth;
												main.style.overflow = "hidden";
												main.style.paddingRight = `${ scrollWidth + MAIN_PADDING }px`;
											}
										} }
                                        onMouseLeave={ () => {
											if (isNotNull(main)) {
												main.style.overflow = "hidden auto";
												main.style.paddingRight = `${ MAIN_PADDING }px`;
											}
										} }
                                        style={ {
											position: "relative",
											width: cardSize.width,
											height: cardSize.height,
										} }
                                    >
                                        <WaveformChart
                                            key={ cardSize.width }
                                            width={ cardSize.width }
                                            height={ cardSize.height }
                                            config={ waveforms }
                                            data={ _getData() }
                                            resolutionSeconds={ lastRecord?.waveforms?.resolution?.value ?? 1 }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
					}
				</div>
				<div
					className={
						classNames(
							{ "col-span-3": variant === LayoutVariant.TABLE },
							{ "hidden": variant === LayoutVariant.CHART },
						)
					}
				>
					<Table
						className="[&>div]:border-b-0"
						title={ t("TABLE.actual values") }
						options={ {
							filter: false,
							search: false,
							pagination: false,
							setRowProps: (_, dataIndex) => {
								if (isTableLoading) return {};
								const category = data[ dataIndex ]?.category;
								if (isNull(category)) return {};
								return {
									className: measurementCategoryRowClassNameDictionary()[ category ],
								};
							},
						} }
						isLoading={ isTableLoading }
						cellPadding={ 0 }
						columns={ [
							{
								name: "Parameter",
								label: t("TABLE.parameter").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) =>
										<div>
											<div className={ `!p-2 ${ measurementCategoryCellClassNameDictionary()[ parameter.category ] }` }>
												{ parameter.name }
											</div>
										</div>,
									sort: false,
									filter: false,
								},
							}, {
								name: "Value",
								label: t("TABLE.value").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => (
										<span>
											{ parameter.value !== null && parameter.value !== undefined ? parameter.value : "---" }
										</span>
									),
									sort: false,
									filter: false,
								},
							}, {
								name: "Unit",
								label: t("TABLE.unit").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => parameter.unit ?? "---",
									sort: false,
									filter: false,
								},
							},
						] }
						data={ data }
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	mainSize: state.ui.layout.mainSize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(WaveformContainer);
