import { EnumDictionary, SVG } from "src/app/types/util.types";
import { ErrorCodeName } from "src/app/types/api/api.types";
import { AggregationPeriodUser, DigitalInputState, DigitalOutput, DigitalOutputMode, FtpConnectionType, FtpMode, MapRelay, MemoryModel, ModbusBaudrate, ModbusEndianness, ModbusParity, NominalFrequency, PhaseRotation, PowerCalculationMethod, RecordingOption, StandardType, SystemType, TimeSyncMode, Timezone, WaveformFormat } from "src/app/types/api/settings.types";
import { LayoutVariant } from "src/app/types/ui/layout.types";
import { ValidRouteObject, ValidRoutes } from "src/app/types/ui/router.types";
import { FaGear, FaUsers } from "react-icons/fa6";
import { BsFillLightningChargeFill, BsRecordCircle } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";
import { AiOutlineRadarChart } from "react-icons/ai";
import { MeasurementCategory } from "src/app/types/misc.types";
import { EventChannel, EventFlag, EventOrigin, EventType } from "src/app/types/api/event.types";
import { LuFileCog2 } from "react-icons/lu";
import { HiKey } from "react-icons/hi";
import { PiChartLineLight, PiNumberCircleFiveBold, PiNumberCircleFourBold, PiNumberCircleOneBold, PiNumberCircleSixBold, PiNumberCircleThreeBold, PiNumberCircleTwoBold, PiWaveformBold } from "react-icons/pi";
import i18n from "src/app/translations/i18n";
import { AddMessageContent } from "src/app/types/ui/message.types";
import Translate from "src/app/translations/Translate.component";

export const errorCodeNameDictionary: EnumDictionary<ErrorCodeName, string> = (lng) => ({
	[ ErrorCodeName.GENERIC ]: i18n.t("DICTIONARIES.GENERIC", { lng }),
	[ ErrorCodeName.GENERIC_NOT_FOUND ]: i18n.t("DICTIONARIES.GENERIC_NOT_FOUND", { lng }),
	[ ErrorCodeName.GENERIC_NOT_SUPPORTED ]: i18n.t("DICTIONARIES.GENERIC_NOT_SUPPORTED", { lng }),
	[ ErrorCodeName.GENERIC_NOT_IMPLEMENTED ]: i18n.t("DICTIONARIES.GENERIC_NOT_IMPLEMENTED", { lng }),
	[ ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS ]: i18n.t("DICTIONARIES.GENERIC_THROTTLE_TOO_MANY_REQUESTS", { lng }),
	[ ErrorCodeName.UNAUTHORIZED_SIGNATURE_INVALID ]: i18n.t("DICTIONARIES.UNAUTHORIZED_SIGNATURE_INVALID", { lng }),
	[ ErrorCodeName.GENERIC_METHOD_NOT_ALLOWED ]: i18n.t("DICTIONARIES.GENERIC_METHOD_NOT_ALLOWED", { lng }),
	[ ErrorCodeName.GENERIC_HEALTHCHECK_FAILED ]: i18n.t("DICTIONARIES.GENERIC_HEALTHCHECK_FAILED", { lng }),
	[ ErrorCodeName.GENERIC_UNAUTHENTICATED ]: i18n.t("DICTIONARIES.GENERIC_UNAUTHENTICATED", { lng }),
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_CREDENTIALS ]: i18n.t("DICTIONARIES.UNAUTHENTICATED_INVALID_CREDENTIALS", { lng }),
	[ ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP ]: i18n.t("DICTIONARIES.UNAUTHENTICATED_MISSING_2FA_OTP", { lng }),
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_2FA_OTP ]: i18n.t("DICTIONARIES.UNAUTHENTICATED_INVALID_2FA_OTP", { lng }),
	[ ErrorCodeName.UNAUTHENTICATED_RESTRICTED_ACCESS ]: i18n.t("DICTIONARIES.UNAUTHENTICATED_RESTRICTED_ACCESS", { lng }),
	[ ErrorCodeName.UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED ]: i18n.t("DICTIONARIES.UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED", { lng }),
	[ ErrorCodeName.GENERIC_VALIDATION_FAIL ]: i18n.t("DICTIONARIES.GENERIC_VALIDATION_FAIL", { lng }),
	[ ErrorCodeName.GENERIC_UNAUTHORIZED ]: i18n.t("DICTIONARIES.GENERIC_UNAUTHORIZED", { lng }),
	[ ErrorCodeName.GENERIC_INVALID_OPERATION ]: i18n.t("DICTIONARIES.GENERIC_INVALID_OPERATION", { lng }),
});

// Dashboard summary
export const systemTypeDictionary: EnumDictionary<SystemType, string> = (lng) => ({
	[ SystemType.ST_1PH ]: i18n.t("ANALYZER.st 1ph", { lng }),
	[ SystemType.ST_2PH ]: i18n.t("ANALYZER.st 2ph", { lng }),
	[ SystemType.ST_3PH_4W ]: i18n.t("ANALYZER.st 3ph 4w", { lng }),
	[ SystemType.ST_3PH_3W ]: i18n.t("ANALYZER.st 3ph 3w", { lng }),
	[ SystemType.ST_3PH_3W_ARON ]: i18n.t("ANALYZER.st 3ph 3w aron", { lng }),
	// [ SystemType.ST_CALIBRATION ]: "Calibration",
});

export const nominalFrequencyDictionary: EnumDictionary<NominalFrequency, string> = (lng) => ({
	[ NominalFrequency.FREQ_50HZ ]: "50",
	[ NominalFrequency.FREQ_60HZ ]: "60",
	[ NominalFrequency.FREQ_400HZ ]: "400",
});

export const nominalFrequencyNumberDictionary: EnumDictionary<NominalFrequency, number> = (lng) => ({
	[ NominalFrequency.FREQ_50HZ ]: 50,
	[ NominalFrequency.FREQ_60HZ ]: 60,
	[ NominalFrequency.FREQ_400HZ ]: 400,
});

export const layoutVariantDictionary: EnumDictionary<LayoutVariant, string> = (lng) => ({
	[ LayoutVariant.ALL ]: i18n.t("UTILS.view config all", { lng }),
	[ LayoutVariant.CHART ]: i18n.t("UTILS.view config chart", { lng }),
	[ LayoutVariant.TABLE ]: i18n.t("UTILS.view config table", { lng }),
});

export const waveformFormatDictionary: EnumDictionary<WaveformFormat, string> = (lng) => ({
	[ WaveformFormat.WAVEFORM_FORMAT_8BIT_10K ]: i18n.t("RECORDINGS.format 8b 10khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_16BIT_10K ]: i18n.t("RECORDINGS.format 16b 10khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_8BIT_20K ]: i18n.t("RECORDINGS.format 8b 20khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_16BIT_20K ]: i18n.t("RECORDINGS.format 16b 20khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_8BIT_40K ]: i18n.t("RECORDINGS.format 8b 40khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_16BIT_40K ]: i18n.t("RECORDINGS.format 16b 40khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_8BIT_80K ]: i18n.t("RECORDINGS.format 8b 80khz", { lng }),
	[ WaveformFormat.WAVEFORM_FORMAT_16BIT_80K ]: i18n.t("RECORDINGS.format 16b 80khz", { lng }),
});

export const phaseRotationDictionary: EnumDictionary<PhaseRotation, string> = (lng) => ({
	[ PhaseRotation.PHASE_ROTATION_CLOCKWISE ]: i18n.t("ANALYZER.clockwise L1 L2 L3", { lng }),
	[ PhaseRotation.PHASE_ROTATION_COUNTER_CLOCKWISE ]: i18n.t("ANALYZER.counterclockwise L1 L3 L2", { lng }),
});

export const ftpUploadTimeDictionary: EnumDictionary<number, string> = (lng) => ({
	0: "UTC 00:00",
	1: "UTC 01:00",
	2: "UTC 02:00",
	3: "UTC 03:00",
	4: "UTC 04:00",
	5: "UTC 05:00",
	6: "UTC 06:00",
	7: "UTC 07:00",
	8: "UTC 08:00",
	9: "UTC 09:00",
	10: "UTC 10:00",
	11: "UTC 11:00",
	12: "UTC 12:00",
});

export const validRoutesDictionary: EnumDictionary<ValidRoutes, ValidRouteObject> = (lng) => ({
	[ ValidRoutes.ANALYZER ]: {
		name: i18n.t("SIDEBAR.analyzer", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.ANALYZER,
	},
	[ ValidRoutes.RECORDINGS ]: {
		name: i18n.t("SIDEBAR.recordings", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.RECORDINGS,
	},
	[ ValidRoutes.COMMUNICATIONS ]: {
		name: i18n.t("SIDEBAR.communications", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.COMMUNICATIONS,
	},
	[ ValidRoutes.INPUT_OUTPUT ]: {
		name: i18n.t("SIDEBAR.input output", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.INPUT_OUTPUT,
	},
	[ ValidRoutes.MEMORY ]: {
		name: i18n.t("SIDEBAR.memory", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.MEMORY,
	},
	[ ValidRoutes.FTP_CLIENT ]: {
		name: i18n.t("SIDEBAR.ftp", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.FTP_CLIENT,
	},
	[ ValidRoutes.TIME ]: {
		name: i18n.t("SIDEBAR.time", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.TIME,
	},
	[ ValidRoutes.MEASUREMENTS_CONFIG ]: {
		name: i18n.t("SIDEBAR.measurements", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.MEASUREMENTS_CONFIG,
	},
	[ ValidRoutes.EXPORT_IMPORT ]: {
		name: i18n.t("SIDEBAR.export import", { lng }),
		icon: FaGear,
		pathname: ValidRoutes.EXPORT_IMPORT,
	},
	[ ValidRoutes.FIRMWARE ]: {
		name: i18n.t("SIDEBAR.firmware upgrade", { lng }),
		icon: LuFileCog2,
		pathname: ValidRoutes.FIRMWARE,
	},
	[ ValidRoutes.CREDENTIALS ]: {
		name: i18n.t("SIDEBAR.password", { lng }),
		icon: HiKey,
		pathname: ValidRoutes.CREDENTIALS,
	},
	[ ValidRoutes.MEASUREMENTS ]: {
		name: i18n.t("SIDEBAR.measurements", { lng }),
		icon: BsFillLightningChargeFill,
		pathname: ValidRoutes.MEASUREMENTS,
	},
	[ ValidRoutes.WAVEFORMS ]: {
		name: i18n.t("SIDEBAR.waveforms", { lng }),
		icon: BsFillLightningChargeFill,
		pathname: ValidRoutes.WAVEFORMS,
	},
	[ ValidRoutes.TIMEPLOT ]: {
		name: i18n.t("SIDEBAR.timeplot", { lng }),
		icon: BsFillLightningChargeFill,
		pathname: ValidRoutes.TIMEPLOT,
	},
	[ ValidRoutes.PHASORS ]: {
		name: i18n.t("SIDEBAR.phasors", { lng }),
		icon: AiOutlineRadarChart,
		pathname: ValidRoutes.PHASORS,
	},
	[ ValidRoutes.HARMONICS ]: {
		name: i18n.t("SIDEBAR.harmonics", { lng }),
		icon: FaChartBar,
		pathname: ValidRoutes.HARMONICS,
	},
	[ ValidRoutes.INTERHARMONICS ]: {
		name: i18n.t("SIDEBAR.interharmonics", { lng }),
		icon: FaChartBar,
		pathname: ValidRoutes.INTERHARMONICS,
	},
	[ ValidRoutes.STANDARD_EVENTS ]: {
		name: i18n.t("SIDEBAR.events standard", { lng }),
		icon: BsRecordCircle,
		pathname: ValidRoutes.STANDARD_EVENTS,
	},
	[ ValidRoutes.USER_EVENTS ]: {
		name: i18n.t("SIDEBAR.events user", { lng }),
		icon: BsRecordCircle,
		pathname: ValidRoutes.USER_EVENTS,
	},
	[ ValidRoutes.USERS ]: {
		name: i18n.t("SIDEBAR.users", { lng }),
		icon: FaUsers,
		pathname: ValidRoutes.USERS,
	},
});

export const measurementCategoryRowClassNameDictionary: EnumDictionary<MeasurementCategory, string> = (lng) => ({
	[ MeasurementCategory.VOLTAGES ]: "bg-categories-voltages-100",
	[ MeasurementCategory.VOLTAGES_DC ]: "bg-categories-voltages-100",
	[ MeasurementCategory.CURRENTS ]: "bg-categories-currents-100",
	[ MeasurementCategory.VOLTAGES_WAVE ]: "bg-categories-voltagesWave-100",
	[ MeasurementCategory.CURRENTS_WAVE ]: "bg-categories-currentsWave-100",
	[ MeasurementCategory.ACTIVE_POWERS ]: "bg-categories-activePowers-100",
	[ MeasurementCategory.ACTIVE_POWERS_WAVE ]: "bg-categories-activePowersWave-100",
	[ MeasurementCategory.REACTIVE_POWERS ]: "bg-categories-reactivePowers-100",
	[ MeasurementCategory.REACTIVE_POWERS_WAVE ]: "bg-categories-reactivePowersWave-100",
	[ MeasurementCategory.APPARENT_POWERS ]: "bg-categories-apparentPowers-100",
	[ MeasurementCategory.APPARENT_POWERS_WAVE ]: "bg-categories-apparentPowersWave-100",
	[ MeasurementCategory.FREQUENCIES ]: "bg-categories-frequencies-100",
	[ MeasurementCategory.FREQUENCIES_WAVE ]: "bg-categories-frequenciesWave-100",
	[ MeasurementCategory.ENERGY ]: "bg-categories-energies-100",
	[ MeasurementCategory.ACTIVE_ENERGY ]: "bg-categories-activeEnergies-100",
	[ MeasurementCategory.REACTIVE_ENERGY ]: "bg-categories-reactiveEnergies-100",
	[ MeasurementCategory.APPARENT_ENERGY ]: "bg-categories-apparentEnergies-100",
	[ MeasurementCategory.FACTOR ]: "bg-categories-factors-100",
	[ MeasurementCategory.FLICKER ]: "bg-categories-factors-100",
	[ MeasurementCategory.POWER_FACTOR ]: "bg-categories-powerFactors-100",
	[ MeasurementCategory.PHASORS ]: "bg-categories-phasors-100",
	[ MeasurementCategory.HARMONICS ]: "bg-categories-harmonics-100",
	[ MeasurementCategory.INTERHARMONICS ]: "bg-categories-interharmonics-100",
	[ MeasurementCategory.TANGENT ]: "bg-categories-tangents-100",
	[ MeasurementCategory.ANGLES ]: "bg-categories-phasors-100",
	[ MeasurementCategory.PERCENT ]: "bg-categories-phasors-100",
	[ MeasurementCategory.DEFAULT ]: "bg-categories-phasors-100",
});

export const measurementCategoryCellClassNameDictionary: EnumDictionary<MeasurementCategory, string> = (lng) => ({
	[ MeasurementCategory.VOLTAGES ]: "border-l-2 border-categories-voltages-600",
	[ MeasurementCategory.VOLTAGES_DC ]: "border-l-2 border-categories-voltages-600",
	[ MeasurementCategory.CURRENTS ]: "border-l-2 border-categories-currents-600",
	[ MeasurementCategory.VOLTAGES_WAVE ]: "border-l-2 border-categories-voltagesWave-600",
	[ MeasurementCategory.CURRENTS_WAVE ]: "border-l-2 border-categories-currentsWave-600",
	[ MeasurementCategory.ACTIVE_POWERS ]: "border-l-2 border-categories-activePowers-800",
	[ MeasurementCategory.ACTIVE_POWERS_WAVE ]: "border-l-2 border-categories-activePowersWave-800",
	[ MeasurementCategory.REACTIVE_POWERS ]: "border-l-2 border-categories-reactivePowers-600",
	[ MeasurementCategory.REACTIVE_POWERS_WAVE ]: "border-l-2 border-categories-reactivePowersWave-600",
	[ MeasurementCategory.APPARENT_POWERS ]: "border-l-2 border-categories-apparentPowers-600",
	[ MeasurementCategory.APPARENT_POWERS_WAVE ]: "border-l-2 border-categories-apparentPowersWave-600",
	[ MeasurementCategory.FREQUENCIES ]: "border-l-2 border-categories-frequencies-600",
	[ MeasurementCategory.FREQUENCIES_WAVE ]: "border-l-2 border-categories-frequenciesWave-600",
	[ MeasurementCategory.ENERGY ]: "border-l-2 border-categories-energies-600",
	[ MeasurementCategory.ACTIVE_ENERGY ]: "border-l-2 border-categories-activeEnergies-600",
	[ MeasurementCategory.REACTIVE_ENERGY ]: "border-l-2 border-categories-reactiveEnergies-600",
	[ MeasurementCategory.APPARENT_ENERGY ]: "border-l-2 border-categories-apparentEnergies-600",
	[ MeasurementCategory.FACTOR ]: "border-l-2 border-categories-factors-600",
	[ MeasurementCategory.FLICKER ]: "border-l-2 border-categories-factors-600",
	[ MeasurementCategory.POWER_FACTOR ]: "border-l-2 border-categories-powerFactors-600",
	[ MeasurementCategory.PHASORS ]: "border-l-2 border-categories-phasors-600",
	[ MeasurementCategory.HARMONICS ]: "border-l-2 border-categories-harmonics-600",
	[ MeasurementCategory.INTERHARMONICS ]: "border-l-2 border-categories-interharmonics-600",
	[ MeasurementCategory.TANGENT ]: "border-l-2 border-categories-tangents-600",
	[ MeasurementCategory.ANGLES ]: "border-l-2 border-categories-phasors-600",
	[ MeasurementCategory.PERCENT ]: "border-l-2 border-categories-phasors-600",
	[ MeasurementCategory.DEFAULT ]: "border-l-2 border-categories-phasors-600",
});

export const eventTypeDictionary: EnumDictionary<EventType, string> = (lng) => ({
	[ EventType.EVENT_UNSPEC ]: i18n.t("UTILS.unspecified", { lng, context: "he" }).toUpperCase(),
	[ EventType.EVENT_DIP ]: i18n.t("EVENTS.dip", { lng }).toUpperCase(),
	[ EventType.EVENT_SWELL ]: i18n.t("EVENTS.swell", { lng }).toUpperCase(),
	[ EventType.EVENT_INTERRUPT ]: i18n.t("EVENTS.interrupt", { lng }).toUpperCase(),
	[ EventType.EVENT_MIN ]: i18n.t("UTILS.min", { lng }).toUpperCase(),
	[ EventType.EVENT_MAX ]: i18n.t("UTILS.max", { lng }).toUpperCase(),
});

export const eventChannelDictionary: EnumDictionary<EventChannel, string> = (lng) => ({
	[ EventChannel.CH_L1 ]: "L1".toUpperCase(),
	[ EventChannel.CH_L2 ]: "L2".toUpperCase(),
	[ EventChannel.CH_L3 ]: "L3".toUpperCase(),
	[ EventChannel.CH_N ]: "N".toUpperCase(),
	[ EventChannel.CH_E ]: "E".toUpperCase(),
	[ EventChannel.CH_TOTAL ]: i18n.t("TABLE.total", { lng }).toUpperCase(),
});

export const eventFlagDictionary: EnumDictionary<EventFlag, { icon: SVG, name: string }> = (lng) => ({
	[ EventFlag.FLAGGED_61000_4_30 ]: {
		icon: PiNumberCircleOneBold,
		name: i18n.t("DICTIONARIES.FLAGGED 61000 4 30", { lng }),
	},
	[ EventFlag.PLL_OUT_OF_SYNC ]: {
		icon: PiNumberCircleTwoBold,
		name: i18n.t("DICTIONARIES.PLL OUT OF SYNC", { lng }),
	},
	[ EventFlag.ADC_OVERFLOW ]: {
		icon: PiNumberCircleThreeBold,
		name: i18n.t("DICTIONARIES.ADC OVERFLOW", { lng }),
	},
	[ EventFlag.GPS_OUT_OF_SYNC ]: {
		icon: PiNumberCircleFourBold,
		name: i18n.t("DICTIONARIES.GPS OUT OF SYNC", { lng }),
	},
	[ EventFlag.TIME_RESYNC ]: {
		icon: PiNumberCircleFiveBold,
		name: i18n.t("DICTIONARIES.TIME RESYNC", { lng }),
	},
	[ EventFlag.WAVEFORM_CHART ]: {
		icon: PiWaveformBold,
		name: i18n.t("EVENTS.waveform", { lng }),
	},
	[ EventFlag.RMS12_CHART ]: {
		icon: PiChartLineLight,
		name: i18n.t("EVENTS.rms", { lng }),
	},
	[ EventFlag.PQDIF_FILE ]: {
		icon: PiNumberCircleSixBold,
		name: "PQDIF",
	},
});

export const eventOriginNodeDictionary: EnumDictionary<EventOrigin, ReactNode> = (lng) => ({
	[ EventOrigin.UNKNOWN ]: <span className="uppercase"><Translate i18nKey="EVENTS.unknown"/></span>,
	[ EventOrigin.VOLTAGE ]: <span className="uppercase"><Translate i18nKey="EVENTS.voltage"/></span>,
	[ EventOrigin.CURRENT ]: <span className="uppercase"><Translate i18nKey="EVENTS.current"/></span>,
	[ EventOrigin.FREQUENCY ]: <span className="uppercase"><Translate i18nKey="EVENTS.frequency"/></span>,
	[ EventOrigin.POWER_ACTIVE ]: <span className="uppercase"><Translate i18nKey="EVENTS.active power"/></span>,
	[ EventOrigin.POWER_ACTIVE_PLUS ]: <span className="uppercase"><Translate i18nKey="EVENTS.active power plus"/></span>,
	[ EventOrigin.POWER_ACTIVE_MINUS ]: <span className="uppercase"><Translate i18nKey="EVENTS.active power minus"/></span>,
	[ EventOrigin.POWER_REACTIVE ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive power"/></span>,
	[ EventOrigin.POWER_REACTIVE_FUNDAMENTAL ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive power fundamental"/></span>,
	[ EventOrigin.POWER_APPARENT ]: <span className="uppercase"><Translate i18nKey="EVENTS.apparent power"/></span>,
	[ EventOrigin.POWER_APPARENT_FUNDAMENTAL ]: <span className="uppercase"><Translate i18nKey="EVENTS.apparent power fundamental"/></span>,
	[ EventOrigin.DPF ]: <span className="uppercase"><Translate i18nKey="EVENTS.displacement power factor"/></span>,
	[ EventOrigin.POWER_FACTOR ]: <span className="uppercase"><Translate i18nKey="EVENTS.power factor"/></span>,
	[ EventOrigin.ENERGY_ACTIVE_PLUS ]: <span className="uppercase"><Translate i18nKey="EVENTS.active energy plus"/></span>,
	[ EventOrigin.ENERGY_ACTIVE_MINUS ]: <span className="uppercase"><Translate i18nKey="EVENTS.active energy minus"/></span>,
	[ EventOrigin.ENERGY_REACTIVE_Q1 ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive energy Q1"/></span>,
	[ EventOrigin.ENERGY_REACTIVE_Q2 ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive energy Q2"/></span>,
	[ EventOrigin.ENERGY_REACTIVE_Q3 ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive energy Q3"/></span>,
	[ EventOrigin.ENERGY_REACTIVE_Q4 ]: <span className="uppercase"><Translate i18nKey="EVENTS.reactive energy Q4"/></span>,
	[ EventOrigin.VOLTAGE_THDF ]: <span className="uppercase"><Translate i18nKey="EVENTS.voltage thdf"/></span>,
	[ EventOrigin.CURRENT_THDF ]: <span className="uppercase"><Translate i18nKey="EVENTS.current thdf"/></span>,
	[ EventOrigin.UNBALANCE_VOLTAGE_NEGATIVE ]: <span className="uppercase"><Translate i18nKey="EVENTS.unbalance voltage negative"/></span>,
	[ EventOrigin.FLICKER_PST ]: <span className="uppercase"><Translate i18nKey="EVENTS.flicker pst"/></span>,
	[ EventOrigin.FLICKER_PLT ]: <span className="uppercase"><Translate i18nKey="EVENTS.flicker plt"/></span>,
	[ EventOrigin.WAVESHAPE ]: <span className="uppercase"><Translate i18nKey="EVENTS.waveshape"/></span>,
	[ EventOrigin.PHASE_JUMPS ]: <span className="uppercase"><Translate i18nKey="EVENTS.phase jumps"/></span>,
	[ EventOrigin.INPUT_DIGITAL_INTERNAL_1 ]: <span className="uppercase"><Translate i18nKey="EVENTS.input digital internal 1"/></span>,
	[ EventOrigin.INPUT_DIGITAL_INTERNAL_2 ]: <span className="uppercase"><Translate i18nKey="EVENTS.input digital internal 2"/></span>,
});

export const eventOriginToMeasurementCategoryDictionary: EnumDictionary<EventOrigin, MeasurementCategory> = (lng) => ({
		[ EventOrigin.UNKNOWN ]: MeasurementCategory.DEFAULT,
		[ EventOrigin.VOLTAGE ]: MeasurementCategory.VOLTAGES,
		[ EventOrigin.CURRENT ]: MeasurementCategory.CURRENTS,
		[ EventOrigin.FREQUENCY ]: MeasurementCategory.FREQUENCIES,
		[ EventOrigin.POWER_ACTIVE ]: MeasurementCategory.ACTIVE_POWERS,
		[ EventOrigin.POWER_ACTIVE_PLUS ]: MeasurementCategory.ACTIVE_POWERS,
		[ EventOrigin.POWER_ACTIVE_MINUS ]: MeasurementCategory.ACTIVE_POWERS,
		[ EventOrigin.POWER_REACTIVE ]: MeasurementCategory.REACTIVE_POWERS,
		[ EventOrigin.POWER_REACTIVE_FUNDAMENTAL ]: MeasurementCategory.REACTIVE_POWERS,
		[ EventOrigin.POWER_APPARENT ]: MeasurementCategory.APPARENT_POWERS,
		[ EventOrigin.POWER_APPARENT_FUNDAMENTAL ]: MeasurementCategory.APPARENT_POWERS,
		[ EventOrigin.DPF ]: MeasurementCategory.POWER_FACTOR,
		[ EventOrigin.POWER_FACTOR ]: MeasurementCategory.POWER_FACTOR,
		[ EventOrigin.ENERGY_ACTIVE_PLUS ]: MeasurementCategory.ACTIVE_ENERGY,
		[ EventOrigin.ENERGY_ACTIVE_MINUS ]: MeasurementCategory.ACTIVE_ENERGY,
		[ EventOrigin.ENERGY_REACTIVE_Q1 ]: MeasurementCategory.REACTIVE_ENERGY,
		[ EventOrigin.ENERGY_REACTIVE_Q2 ]: MeasurementCategory.REACTIVE_ENERGY,
		[ EventOrigin.ENERGY_REACTIVE_Q3 ]: MeasurementCategory.REACTIVE_ENERGY,
		[ EventOrigin.ENERGY_REACTIVE_Q4 ]: MeasurementCategory.REACTIVE_ENERGY,
		[ EventOrigin.VOLTAGE_THDF ]: MeasurementCategory.HARMONICS,
		[ EventOrigin.CURRENT_THDF ]: MeasurementCategory.HARMONICS,
		[ EventOrigin.UNBALANCE_VOLTAGE_NEGATIVE ]: MeasurementCategory.PHASORS,
		[ EventOrigin.FLICKER_PST ]: MeasurementCategory.FLICKER,
		[ EventOrigin.FLICKER_PLT ]: MeasurementCategory.FLICKER,
		[ EventOrigin.WAVESHAPE ]: MeasurementCategory.PERCENT,
		[ EventOrigin.PHASE_JUMPS ]: MeasurementCategory.ANGLES,
		[ EventOrigin.INPUT_DIGITAL_INTERNAL_1 ]: MeasurementCategory.DEFAULT,
		[ EventOrigin.INPUT_DIGITAL_INTERNAL_2 ]: MeasurementCategory.DEFAULT,
	})
;

export const eventOriginDictionary: EnumDictionary<EventOrigin, string> = (lng) => ({
	[ EventOrigin.UNKNOWN ]: i18n.t("EVENTS.unknown", { lng }),
	[ EventOrigin.VOLTAGE ]: i18n.t("EVENTS.voltage", { lng }),
	[ EventOrigin.CURRENT ]: i18n.t("EVENTS.current", { lng }),
	[ EventOrigin.FREQUENCY ]: i18n.t("EVENTS.frequency", { lng }),
	[ EventOrigin.POWER_ACTIVE ]: i18n.t("EVENTS.active power", { lng }),
	[ EventOrigin.POWER_ACTIVE_PLUS ]: i18n.t("EVENTS.active power plus", { lng }),
	[ EventOrigin.POWER_ACTIVE_MINUS ]: i18n.t("EVENTS.active power minus", { lng }),
	[ EventOrigin.POWER_REACTIVE ]: i18n.t("EVENTS.reactive power", { lng }),
	[ EventOrigin.POWER_REACTIVE_FUNDAMENTAL ]: i18n.t("EVENTS.reactive power fundamental", { lng }),
	[ EventOrigin.POWER_APPARENT ]: i18n.t("EVENTS.apparent power", { lng }),
	[ EventOrigin.POWER_APPARENT_FUNDAMENTAL ]: i18n.t("EVENTS.apparent power fundamental", { lng }),
	[ EventOrigin.DPF ]: i18n.t("EVENTS.displacement power factor", { lng }),
	[ EventOrigin.POWER_FACTOR ]: i18n.t("EVENTS.power factor", { lng }),
	[ EventOrigin.ENERGY_ACTIVE_PLUS ]: i18n.t("EVENTS.active energy plus", { lng }),
	[ EventOrigin.ENERGY_ACTIVE_MINUS ]: i18n.t("EVENTS.active energy minus", { lng }),
	[ EventOrigin.ENERGY_REACTIVE_Q1 ]: i18n.t("EVENTS.reactive energy Q1", { lng }),
	[ EventOrigin.ENERGY_REACTIVE_Q2 ]: i18n.t("EVENTS.reactive energy Q2", { lng }),
	[ EventOrigin.ENERGY_REACTIVE_Q3 ]: i18n.t("EVENTS.reactive energy Q3", { lng }),
	[ EventOrigin.ENERGY_REACTIVE_Q4 ]: i18n.t("EVENTS.reactive energy Q4", { lng }),
	[ EventOrigin.VOLTAGE_THDF ]: i18n.t("EVENTS.voltage thdf", { lng }),
	[ EventOrigin.CURRENT_THDF ]: i18n.t("EVENTS.current thdf", { lng }),
	[ EventOrigin.UNBALANCE_VOLTAGE_NEGATIVE ]: i18n.t("EVENTS.unbalance voltage negative", { lng }),
	[ EventOrigin.FLICKER_PST ]: i18n.t("EVENTS.flicker pst", { lng }),
	[ EventOrigin.FLICKER_PLT ]: i18n.t("EVENTS.flicker plt", { lng }),
	[ EventOrigin.WAVESHAPE ]: i18n.t("EVENTS.waveshape", { lng }),
	[ EventOrigin.PHASE_JUMPS ]: i18n.t("EVENTS.phase jumps", { lng }),
	[ EventOrigin.INPUT_DIGITAL_INTERNAL_1 ]: i18n.t("EVENTS.input digital internal 1", { lng }),
	[ EventOrigin.INPUT_DIGITAL_INTERNAL_2 ]: i18n.t("EVENTS.input digital internal 2", { lng }),
});

export const modbusBaudrateDictionary: EnumDictionary<ModbusBaudrate, string> = (lng) => ({
	[ ModbusBaudrate.BAUDRATE_57600 ]: "57600",
	[ ModbusBaudrate.BAUDRATE_115200 ]: "115200",
	[ ModbusBaudrate.BAUDRATE_128000 ]: "128000",
	[ ModbusBaudrate.BAUDRATE_230400 ]: "230400",
	[ ModbusBaudrate.BAUDRATE_256000 ]: "256000",
	[ ModbusBaudrate.BAUDRATE_460800 ]: "460800",
	[ ModbusBaudrate.BAUDRATE_921600 ]: "921600",
});

export const modbusParityDictionary: EnumDictionary<ModbusParity, string> = (lng) => ({
	[ ModbusParity.MODBUS_PARITY_EVEN ]: i18n.t("COMMUNICATIONS.even", { lng }),
	[ ModbusParity.MODBUS_PARITY_NONE ]: i18n.t("COMMUNICATIONS.none", { lng }),
	[ ModbusParity.MODBUS_PARITY_ODD ]: i18n.t("COMMUNICATIONS.odd", { lng }),
});

export const modbusEndiannessDictionary: EnumDictionary<ModbusEndianness, string> = (lng) => ({
	[ ModbusEndianness.ENDIANNESS_LITTLE_ENDIAN ]: i18n.t("COMMUNICATIONS.little endian", { lng }),
	[ ModbusEndianness.ENDIANNESS_BIG_ENDIAN ]: i18n.t("COMMUNICATIONS.big endian", { lng }),
});

export const memoryModelDictionary: EnumDictionary<MemoryModel, string> = (lng) => ({
	[ MemoryModel.MEMORY_MODEL_CIRCULAR ]: i18n.t("MEMORY.circular", { lng }),
	[ MemoryModel.MEMORY_MODEL_LINEAR ]: i18n.t("MEMORY.linear", { lng }),
});

export const timeSyncModeDictionary: EnumDictionary<TimeSyncMode, string> = (lng) => ({
	[ TimeSyncMode.TIME_SYNC_AUTO ]: i18n.t("TIME.auto GPS IRIG-B NTP", { lng }),
	[ TimeSyncMode.TIME_SYNC_RTC ]: i18n.t("TIME.RTC only", { lng }),
});

export const ftpConnectionTypeDictionary: EnumDictionary<FtpConnectionType, string> = (lng) => ({
	[ FtpConnectionType.CONN_TYPE_FTP ]: i18n.t("FTP.no", { lng }),
	[ FtpConnectionType.CONN_TYPE_SFTP ]: i18n.t("FTP.yes", { lng }),
});

export const ftpModeDictionary: EnumDictionary<FtpMode, string> = (lng) => ({
	[ FtpMode.FTP_MODE_ACTIVE ]: i18n.t("FTP.active", { lng }),
	[ FtpMode.FTP_MODE_PASSIVE ]: i18n.t("FTP.passive", { lng }),
});

export const timezoneDictionary: EnumDictionary<Timezone, string> = (lng) => ({
	[ Timezone.UTC_MINUS_12 ]: "UTC-12",
	[ Timezone.UTC_MINUS_11 ]: "UTC-11",
	[ Timezone.UTC_MINUS_10 ]: "UTC-10",
	[ Timezone.UTC_MINUS_0930 ]: "UTC-09:30",
	[ Timezone.UTC_MINUS_09 ]: "UTC-09",
	[ Timezone.UTC_MINUS_08 ]: "UTC-08",
	[ Timezone.UTC_MINUS_07 ]: "UTC-07",
	[ Timezone.UTC_MINUS_06 ]: "UTC-06",
	[ Timezone.UTC_MINUS_05 ]: "UTC-05",
	[ Timezone.UTC_MINUS_04 ]: "UTC-04",
	[ Timezone.UTC_MINUS_0330 ]: "UTC-03:30",
	[ Timezone.UTC_MINUS_03 ]: "UTC-03",
	[ Timezone.UTC_MINUS_02 ]: "UTC-02",
	[ Timezone.UTC_MINUS_01 ]: "UTC-01",
	[ Timezone.UTC_00 ]: "UTC±00",
	[ Timezone.UTC_01 ]: "UTC+01",
	[ Timezone.UTC_02 ]: "UTC+02",
	[ Timezone.UTC_03 ]: "UTC+03",
	[ Timezone.UTC_0330 ]: "UTC+03:30",
	[ Timezone.UTC_04 ]: "UTC+04",
	[ Timezone.UTC_0430 ]: "UTC+04:30",
	[ Timezone.UTC_05 ]: "UTC+05",
	[ Timezone.UTC_0530 ]: "UTC+05:30",
	[ Timezone.UTC_0545 ]: "UTC+05:45",
	[ Timezone.UTC_06 ]: "UTC+06",
	[ Timezone.UTC_0630 ]: "UTC+06:30",
	[ Timezone.UTC_07 ]: "UTC+07",
	[ Timezone.UTC_08 ]: "UTC+08",
	[ Timezone.UTC_0845 ]: "UTC+08:45",
	[ Timezone.UTC_09 ]: "UTC+09",
	[ Timezone.UTC_0930 ]: "UTC+09:30",
	[ Timezone.UTC_10 ]: "UTC+10",
	[ Timezone.UTC_1030 ]: "UTC+10:30",
	[ Timezone.UTC_11 ]: "UTC+11",
	[ Timezone.UTC_12 ]: "UTC+12",
	[ Timezone.UTC_1245 ]: "UTC+12:45",
	[ Timezone.UTC_13 ]: "UTC+13",
	[ Timezone.UTC_14 ]: "UTC+14",
});

export const timezoneNumberDictionary: EnumDictionary<Timezone, number> = (lng) => ({
	[ Timezone.UTC_MINUS_12 ]: -12 * 60,
	[ Timezone.UTC_MINUS_11 ]: -11 * 60,
	[ Timezone.UTC_MINUS_10 ]: -10 * 60,
	[ Timezone.UTC_MINUS_0930 ]: -9.5 * 60,
	[ Timezone.UTC_MINUS_09 ]: -9 * 60,
	[ Timezone.UTC_MINUS_08 ]: -8 * 60,
	[ Timezone.UTC_MINUS_07 ]: -7 * 60,
	[ Timezone.UTC_MINUS_06 ]: -6 * 60,
	[ Timezone.UTC_MINUS_05 ]: -5 * 60,
	[ Timezone.UTC_MINUS_04 ]: -4 * 60,
	[ Timezone.UTC_MINUS_0330 ]: -3.5 * 60,
	[ Timezone.UTC_MINUS_03 ]: -3 * 60,
	[ Timezone.UTC_MINUS_02 ]: -2 * 60,
	[ Timezone.UTC_MINUS_01 ]: -60,
	[ Timezone.UTC_00 ]: 0,
	[ Timezone.UTC_01 ]: 60,
	[ Timezone.UTC_02 ]: 2 * 60,
	[ Timezone.UTC_03 ]: 3 * 60,
	[ Timezone.UTC_0330 ]: 3.5 * 60,
	[ Timezone.UTC_04 ]: 4 * 60,
	[ Timezone.UTC_0430 ]: 4.5 * 60,
	[ Timezone.UTC_05 ]: 5 * 60,
	[ Timezone.UTC_0530 ]: 5.5 * 60,
	[ Timezone.UTC_0545 ]: 5.75 * 60,
	[ Timezone.UTC_06 ]: 6 * 60,
	[ Timezone.UTC_0630 ]: 6.5 * 60,
	[ Timezone.UTC_07 ]: 7 * 60,
	[ Timezone.UTC_08 ]: 8 * 60,
	[ Timezone.UTC_0845 ]: 8.75 * 60,
	[ Timezone.UTC_09 ]: 9 * 60,
	[ Timezone.UTC_0930 ]: 9.5 * 60,
	[ Timezone.UTC_10 ]: 10 * 60,
	[ Timezone.UTC_1030 ]: 10.5 * 60,
	[ Timezone.UTC_11 ]: 11 * 60,
	[ Timezone.UTC_12 ]: 12 * 60,
	[ Timezone.UTC_1245 ]: 12.75 * 60,
	[ Timezone.UTC_13 ]: 13 * 60,
	[ Timezone.UTC_14 ]: 14 * 60,
});

export const aggregationPeriodUserDictionary: EnumDictionary<AggregationPeriodUser, string> = (lng) => ({
	[ AggregationPeriodUser.AGGR_NOT_DEFINED ]: i18n.t("MEASUREMENTS.not defined", { lng }),
	[ AggregationPeriodUser.AGGR_HALF_PERIOD ]: i18n.t("MEASUREMENTS.half period", { lng }),
	[ AggregationPeriodUser.AGGR_PERIOD ]: i18n.t("MEASUREMENTS.period", { lng }),
	[ AggregationPeriodUser.AGGR_200MS ]: i18n.t("MEASUREMENTS.200 ms", { lng }),
	[ AggregationPeriodUser.AGGR_1S ]: i18n.t("MEASUREMENTS.1 s", { lng }),
	[ AggregationPeriodUser.AGGR_3S ]: i18n.t("MEASUREMENTS.3 s", { lng }),
	[ AggregationPeriodUser.AGGR_5S ]: i18n.t("MEASUREMENTS.5 s", { lng }),
	[ AggregationPeriodUser.AGGR_10S ]: i18n.t("MEASUREMENTS.10 s", { lng }),
	[ AggregationPeriodUser.AGGR_15S ]: i18n.t("MEASUREMENTS.15 s", { lng }),
	[ AggregationPeriodUser.AGGR_30S ]: i18n.t("MEASUREMENTS.30 s", { lng }),
	[ AggregationPeriodUser.AGGR_1MIN ]: i18n.t("MEASUREMENTS.1 min", { lng }),
	[ AggregationPeriodUser.AGGR_3MIN ]: i18n.t("MEASUREMENTS.3 min", { lng }),
	[ AggregationPeriodUser.AGGR_5MIN ]: i18n.t("MEASUREMENTS.5 min", { lng }),
	[ AggregationPeriodUser.AGGR_10MIN ]: i18n.t("MEASUREMENTS.10 min", { lng }),
	[ AggregationPeriodUser.AGGR_15MIN ]: i18n.t("MEASUREMENTS.15 min", { lng }),
	[ AggregationPeriodUser.AGGR_30MIN ]: i18n.t("MEASUREMENTS.30 min", { lng }),
	[ AggregationPeriodUser.AGGR_60MIN ]: i18n.t("MEASUREMENTS.1 h", { lng }),
	[ AggregationPeriodUser.AGGR_120MIN ]: i18n.t("MEASUREMENTS.2 h", { lng }),
});

export const powerCalculationMethodDictionary: EnumDictionary<PowerCalculationMethod, string> = (lng) => ({
	[ PowerCalculationMethod.POWER_METHOD_IEEE1459 ]: i18n.t("DICTIONARIES.POWER_METHOD_IEEE1459", { lng }),
	[ PowerCalculationMethod.POWER_METHOD_BUDEANU ]: i18n.t("DICTIONARIES.POWER_METHOD_BUDEANU", { lng }),
});

export const recordingOptionDictionary: EnumDictionary<RecordingOption, string> = (lng) => ({
	[ RecordingOption.SET_REC_OPT_AVG ]: i18n.t("MEASUREMENTS.average", { lng }),
	[ RecordingOption.SET_REC_OPT_MIN ]: i18n.t("MEASUREMENTS.minimum", { lng }),
	[ RecordingOption.SET_REC_OPT_MAX ]: i18n.t("MEASUREMENTS.maximum", { lng }),
	[ RecordingOption.SET_REC_OPT_INST ]: i18n.t("MEASUREMENTS.log", { lng }),
	[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ]: "",
	[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ]: i18n.t("MEASUREMENTS.enable events logging", { lng }),
	[ RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE ]: i18n.t("MEASUREMENTS.waveforms", { lng }),
	[ RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE ]: i18n.t("MEASUREMENTS.RMS 1/2", { lng }),
});

export const recordingOptionSortingOrder: EnumDictionary<RecordingOption, number> = (lng) => ({
	[ RecordingOption.SET_REC_OPT_AVG ]: 1,
	[ RecordingOption.SET_REC_OPT_MIN ]: 0,
	[ RecordingOption.SET_REC_OPT_MAX ]: 2,
	[ RecordingOption.SET_REC_OPT_INST ]: 3,
	[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ]: 4,
	[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ]: 5,
	[ RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE ]: 6,
	[ RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE ]: 7,
});

export const recordingOptionDefaultMasks: EnumDictionary<RecordingOption, number> = (lng) => ({
	[ RecordingOption.SET_REC_OPT_AVG ]: 1,
	[ RecordingOption.SET_REC_OPT_MIN ]: 2,
	[ RecordingOption.SET_REC_OPT_MAX ]: 4,
	[ RecordingOption.SET_REC_OPT_INST ]: 8,
	[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ]: 16,
	[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ]: 32,
	[ RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE ]: 64,
	[ RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE ]: 128,
});

export const mapRelayDictionary: EnumDictionary<MapRelay, string> = (lng) => ({
	[ MapRelay.MAP_RELAY1 ]: i18n.t("INPUTOUTPUT.relay 1", { lng }),
	[ MapRelay.MAP_RELAY2 ]: i18n.t("INPUTOUTPUT.relay 2", { lng }),
});

export const standardTypeDictionary: EnumDictionary<StandardType, string> = (lng) => ({
	[ StandardType.STANDARD_TYPE_EN50160_LV ]: i18n.t("MEASUREMENTS.EN 50160 low voltage", { lng }),
	[ StandardType.STANDARD_TYPE_EN50160_MV ]: i18n.t("MEASUREMENTS.EN 50160 medium voltage", { lng }),
	[ StandardType.STANDARD_TYPE_EN50160_HV ]: i18n.t("MEASUREMENTS.EN 50160 high voltage", { lng }),
});

export const digitalInputStateDictionary: EnumDictionary<DigitalInputState, string> = (lng) => ({
	[ DigitalInputState.DIGITAL_INPUT_STATE_LOW ]: i18n.t("INPUTOUTPUT.low", { lng }),
	[ DigitalInputState.DIGITAL_INPUT_STATE_HIGH ]: i18n.t("INPUTOUTPUT.high", { lng }),
});

export const digitalOutputDictionary: EnumDictionary<DigitalOutput, string> = (lng) => ({
	[ DigitalOutput.DIGITAL_OUT_ACTIVE_LOW ]: i18n.t("DICTIONARIES.DIGITAL_OUT_ACTIVE_LOW", { lng }),
	[ DigitalOutput.DIGITAL_OUT_ACTIVE_HIGH ]: i18n.t("DICTIONARIES.DIGITAL_OUT_ACTIVE_HIGH", { lng }),
});

export const digitalOutputModeDictionary: EnumDictionary<DigitalOutputMode, string> = (lng) => ({
	[ DigitalOutputMode.DIGITAL_OUT_MODE_ALARM ]: i18n.t("DICTIONARIES.DIGITAL_OUT_MODE_ALARM", { lng }),
	[ DigitalOutputMode.DIGITAL_OUT_MODE_PULSE ]: i18n.t("DICTIONARIES.DIGITAL_OUT_MODE_PULSE", { lng }),
});

export const addMessageContentDictionary: EnumDictionary<AddMessageContent, string> = (lng) => ({
	[ AddMessageContent.LOGIN_INCORRECT_CREDENTIALS ]: i18n.t("RESPONSES.API.incorrect login", { lng }),
	[ AddMessageContent.LOGIN_SESSION_EXPIRED ]: i18n.t("RESPONSES.API.session expired", { lng }),
	[ AddMessageContent.LOGIN_FIRMWARE_UPGRADE_SUCCESS ]: i18n.t("RESPONSES.FIRMWARE.upgrade success", { lng }),
	[ AddMessageContent.LOGIN_FIRMWARE_UPGRADE_FAILURE ]: i18n.t("RESPONSES.FIRMWARE.upgrade failure", { lng }),
	[ AddMessageContent.FTP_TEST_SUCCESS ]: i18n.t("RESPONSES.SETTINGS.ftp test success", { lng }),
	[ AddMessageContent.FTP_TEST_FAILURE ]: i18n.t("RESPONSES.SETTINGS.ftp test failure", { lng }),
});