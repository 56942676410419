import { Nullable } from "src/app/types/util.types";
import { DataInterharmonicsLive } from "src/app/types/api/ws.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { LayoutVariant } from "src/app/types/ui/layout.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import HarmonicChart, { HarmonicChartData } from "src/app/components/Chart/Harmonic/HarmonicChart.component";
import { Button, Card } from "flowbite-react";
import { layoutVariantDictionary, measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import classNames from "classnames";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import { MdOutlineFileUpload } from "react-icons/md";
import { formatValueToDeciSON, formatValueToFullDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import moment from "moment";
import { saveStockchart } from "src/app/utils/helpers";
import Table from "src/app/components/Utils/Table.component";
import ChartFilter, { ChartFilterColor } from "src/app/components/Utils/ChartFilter.component";
import { MeasurementCategory } from "src/app/types/misc.types";
import { useTranslation } from "react-i18next";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		lastRecord: Nullable<DataInterharmonicsLive>
	};

export enum InterharmonicLine {
	U1 = "U1",
	U2 = "U2",
	U3 = "U3",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3"
}

export type InterharmonicConfigState = {
	[K in InterharmonicLine]: boolean
}

type TableRecord = {
	name: string
	value: Nullable<string>
	unit: Nullable<string>
}

type ChartBaseConfigState = {
	showOdd: boolean
}

function InterharmonicContainer(props: Props) {

	const { t } = useTranslation();

	const {
		lastRecord,
		isSidebarOpen,
		bodySize,
	} = props;

	const cardRef = useRef<HTMLDivElement>(null);
	const [ cardWidth, setCardWidth ] = useState(0);
	const [ variant, setVariant ] = useState(LayoutVariant.ALL);

	const [ displayedRange, setDisplayedRange ] = useState<[ fromIndex: number, toIndex: number ]>([ 0, 50 ]);
	const [ chartConfig, setChartConfig ] = useState<ChartBaseConfigState>({
		showOdd: false,
	});

	const isInterharmonic = isNotNull(lastRecord);

	const isU1Interharmonic = isNotNull(lastRecord?.interharmonics?.U1_interharmonic);
	const isU2Interharmonic = isNotNull(lastRecord?.interharmonics?.U2_interharmonic);
	const isU3Interharmonic = isNotNull(lastRecord?.interharmonics?.U3_interharmonic);
	const isI1Interharmonic = isNotNull(lastRecord?.interharmonics?.I1_interharmonic);
	const isI2Interharmonic = isNotNull(lastRecord?.interharmonics?.I2_interharmonic);
	const isI3Interharmonic = isNotNull(lastRecord?.interharmonics?.I3_interharmonic);

	const [ isConfigSet, toggleConfigSet ] = useState(false);

	const [ interharmonics, setInterharmonics ] = useState<InterharmonicConfigState>({
		[ InterharmonicLine.U1 ]: isU1Interharmonic,
		[ InterharmonicLine.U2 ]: isU2Interharmonic,
		[ InterharmonicLine.U3 ]: isU3Interharmonic,
		[ InterharmonicLine.I1 ]: isI1Interharmonic,
		[ InterharmonicLine.I2 ]: isI2Interharmonic,
		[ InterharmonicLine.I3 ]: isI3Interharmonic,
	});

	useEffect(() => {
		if (isInterharmonic && !isConfigSet) {
			setInterharmonics({
				[ InterharmonicLine.U1 ]: isU1Interharmonic,
				[ InterharmonicLine.U2 ]: isU2Interharmonic,
				[ InterharmonicLine.U3 ]: isU3Interharmonic,
				[ InterharmonicLine.I1 ]: isI1Interharmonic,
				[ InterharmonicLine.I2 ]: isI2Interharmonic,
				[ InterharmonicLine.I3 ]: isI3Interharmonic,
			});
			toggleConfigSet(true);
		}
	}, [ isInterharmonic ]);

	useEffect(() => {
		if (isNotNull(cardRef.current)) {
			setCardWidth(cardRef.current.getBoundingClientRect().width);
		}
	}, [ bodySize.width, isSidebarOpen, variant ]);

	const _getData = (displayedRange: [ fromIndex: number, toIndex: number ], showOdd: boolean): HarmonicChartData[] => {
		if (isNull(lastRecord)) return [];

		const data: HarmonicChartData[] = [];
		for (let i = displayedRange[ 0 ] ; i < displayedRange[ 0 ] + Math.abs(displayedRange[ 1 ] - displayedRange[ 0 ]) ; i++) {
			if (showOdd && i % 2 === 0) continue;

			data.push({
				U1: lastRecord.interharmonics.U1_interharmonic?.value?.[ i ],
				U2: lastRecord.interharmonics.U2_interharmonic?.value?.[ i ],
				U3: lastRecord.interharmonics.U3_interharmonic?.value?.[ i ],
				I1: lastRecord.interharmonics.I1_interharmonic?.value?.[ i ],
				I2: lastRecord.interharmonics.I2_interharmonic?.value?.[ i ],
				I3: lastRecord.interharmonics.I3_interharmonic?.value?.[ i ],
				index: i,
				date: new Date(i),
			});
		}

		if (displayedRange[ 0 ] !== 0) return data;

		return data;
	};

	const _exportToCsv = () => {
		const range: [ number, number ] = [ 0, 256 ];
		const interharmonicData = _getData(range, false);
		const rows = [
			[
				t("TABLE.index"),
				interharmonics.U1 ? `U1[${ lastRecord?.interharmonics?.U1_interharmonic?.unit ?? "V" }]` : null,
				interharmonics.U2 ? `U2[${ lastRecord?.interharmonics?.U2_interharmonic?.unit ?? "V" }]` : null,
				interharmonics.U3 ? `U3[${ lastRecord?.interharmonics?.U3_interharmonic?.unit ?? "V" }]` : null,
				interharmonics.I1 ? `I1[${ lastRecord?.interharmonics?.I1_interharmonic?.unit ?? "A" }]` : null,
				interharmonics.I2 ? `I2[${ lastRecord?.interharmonics?.I2_interharmonic?.unit ?? "A" }]` : null,
				interharmonics.I3 ? `I3[${ lastRecord?.interharmonics?.I3_interharmonic?.unit ?? "A" }]` : null,
			].filter(isNotNull),
			...interharmonicData
				.map(dataItem =>
					[
						`"${ dataItem.index.toFixed().replace(".", ",") }"`,
						interharmonics.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						interharmonics.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						interharmonics.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						interharmonics.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						interharmonics.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						interharmonics.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
					].filter(isNotNull),
				),
		];

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `interharmonic_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	return (
		<div className="flex flex-col gap-4">
			<Button.Group>
				{
					Object.values(LayoutVariant).map(layoutVariant =>
						<Button
							key={ layoutVariant }
							color={ variant === layoutVariant ? "primary" : "gray" }
							onClick={ () => setVariant(layoutVariant) }
							pill
							size="sm"
						>
							{ layoutVariantDictionary()[ layoutVariant ] }
						</Button>,
					)
				}
			</Button.Group>
			<div className="grid grid-cols-3 gap-4">
				<div
					className={
						classNames(
							{ "col-span-2": variant === LayoutVariant.ALL },
							{ "col-span-3": variant === LayoutVariant.CHART },
						)
					}
				>
					{
						variant !== LayoutVariant.TABLE &&
                        <Card className="[&>div]:p-0 [&>div]:gap-0">
                            <div className="p-3 sm:p-6 flex justify-between items-center gap-2 pb-4 border-b border-gray-200">
                                <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
									{ t("SIDEBAR.interharmonics") }
                                </h5>
                                <Dropdown
                                    size="sm"
                                    color="transparent"
                                    label={
										<>
											<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
											{ t("UTILS.export") }
										</>
									}
                                >
                                    <DropdownItem
                                        onClick={ () => {
											const name = `PQM750_interharmonics_from${ displayedRange[ 0 ] }to${ displayedRange[ 1 ] }_${ moment(lastRecord?.interharmonics.timestamp).format("MM-DD-HH-mm-ss") }`;
											saveStockchart(name, cardRef.current);
										} }
                                    >
										{ t("UTILS.png") }
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={ _exportToCsv }
                                    >
										{ t("UTILS.csv") }
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <div className="p-3 sm:p-6 flex flex-col gap-4">
								{
									isConfigSet &&
                                    <div className="flex items-center justify-between flex-col gap-2.5">
                                        <div className="flex items-center justify-center gap-2.5 flex-col">
                                            <div className="flex gap-2.5 items-center">
                                                <span className="text-sm text-gray-500 font-medium">{ t("HARMONICS.configuration") }: </span>
												{/*<ChartFilter
                                                    text={t('HARMONICS.with fund')}
                                                    color={ ChartFilterColor.default }
                                                    active={ chartConfig.withFirst }
                                                    onClick={ () =>
														setChartConfig(prevState => ({
															...prevState,
															withFirst: !prevState.withFirst,
														}))
													}
                                                />*/ }
                                                <ChartFilter
                                                    text={ t("HARMONICS.only odd") }
                                                    color={ ChartFilterColor.default }
                                                    active={ chartConfig.showOdd }
                                                    onClick={ () =>
														setChartConfig(prevState => ({
															...prevState,
															showOdd: !prevState.showOdd,
														}))
													}
                                                />
                                            </div>
                                            <div className="flex gap-x-8 gap-y-2.5 flex-wrap justify-center items-center">
												{
													(
														isU1Interharmonic ||
														isU2Interharmonic ||
														isU3Interharmonic
													) &&
                                                    <div className="flex gap-2.5 items-center">
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
														{
															isU1Interharmonic &&
                                                            <ChartFilter
                                                                text="U1"
                                                                color={ ChartFilterColor.U1 }
                                                                active={ interharmonics.U1 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.U1 ]: !prevState.U1,
																	}))
																}
                                                            />
														}
														{
															isU2Interharmonic &&
                                                            <ChartFilter
                                                                text="U2"
                                                                color={ ChartFilterColor.U2 }
                                                                active={ interharmonics.U2 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.U2 ]: !prevState.U2,
																	}))
																}
                                                            />
														}
														{
															isU3Interharmonic &&
                                                            <ChartFilter
                                                                text="U3"
                                                                color={ ChartFilterColor.U3 }
                                                                active={ interharmonics.U3 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.U3 ]: !prevState.U3,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													(
														isI1Interharmonic ||
														isI2Interharmonic ||
														isI3Interharmonic
													) &&
                                                    <div className="flex gap-2.5 items-center">
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
														{
															isI1Interharmonic &&
                                                            <ChartFilter
                                                                text="I1"
                                                                color={ ChartFilterColor.I1 }
                                                                active={ interharmonics.I1 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.I1 ]: !prevState.I1,
																	}))
																}
                                                            />
														}
														{
															isI2Interharmonic &&
                                                            <ChartFilter
                                                                text="I2"
                                                                color={ ChartFilterColor.I2 }
                                                                active={ interharmonics.I2 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.I2 ]: !prevState.I2,
																	}))
																}
                                                            />
														}
														{
															isI3Interharmonic &&
                                                            <ChartFilter
                                                                text="I3"
                                                                color={ ChartFilterColor.I3 }
                                                                active={ interharmonics.I3 }
                                                                onClick={ () =>
																	setInterharmonics(prevState => ({
																		...prevState,
																		[ InterharmonicLine.I3 ]: !prevState.I3,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
                                            </div>
                                        </div>
                                    </div>
								}
                                <div ref={ cardRef } style={ { height: 600 } }>
                                    <HarmonicChart
                                        key={ cardWidth }
                                        seriesName="interharmonic"
                                        width={ cardWidth }
                                        height={ 600 }
                                        data={ _getData(displayedRange, chartConfig.showOdd) }
                                        config={ interharmonics }
                                        units={ {
											U: lastRecord?.interharmonics?.U1_interharmonic?.unit ?? "V",
											I: lastRecord?.interharmonics?.I1_interharmonic?.unit ?? "A",
										} }
                                        setDisplayedRange={ setDisplayedRange }
                                        getTooltipLabels={
											(config, currentItem) => [
												(config.U1 && isNotNull(currentItem.U1)) ? {
													label: "U1",
													value: formatValueToFullDeciSON(currentItem.U1, lastRecord?.interharmonics?.U1_interharmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
												} : null,
												(config.U2 && isNotNull(currentItem.U2)) ? {
													label: "U2",
													value: formatValueToFullDeciSON(currentItem.U2, lastRecord?.interharmonics?.U2_interharmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
												} : null,
												(config.U3 && isNotNull(currentItem.U3)) ? {
													label: "U3",
													value: formatValueToFullDeciSON(currentItem.U3, lastRecord?.interharmonics?.U3_interharmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
												} : null,
												(config.I1 && isNotNull(currentItem.I1)) ? {
													label: "I1",
													value: formatValueToFullDeciSON(currentItem.I1, lastRecord?.interharmonics?.I1_interharmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
												} : null,
												(config.I2 && isNotNull(currentItem.I2)) ? {
													label: "I2",
													value: formatValueToFullDeciSON(currentItem.I2, lastRecord?.interharmonics?.I2_interharmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
												} : null,
												(config.I3 && isNotNull(currentItem.I3)) ? {
													label: "I3",
													value: formatValueToFullDeciSON(currentItem.I3, lastRecord?.interharmonics?.I3_interharmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
												} : null,
											].filter(isNotNull)
										}
                                    />
                                </div>
                            </div>
                        </Card>
					}
				</div>
				<div
					className={
						classNames(
							{ "col-span-3": variant === LayoutVariant.TABLE },
							{ "hidden": variant === LayoutVariant.CHART },
						)
					}
				>
					<Table
						className="[&>div]:border-b-0"
						title={ t("TABLE.actual values") }
						isLoading={ isNull(lastRecord) }
						options={ {
							filter: false,
							search: false,
							pagination: false,
							setRowProps: (_, dataIndex) => {
								if (isNull(lastRecord)) return {};
								return {
									className: measurementCategoryRowClassNameDictionary()[ MeasurementCategory.INTERHARMONICS ],
								};
							},
						} }
						cellPadding={ 0 }
						columns={ [
							{
								name: "Parameter",
								label: t("TABLE.parameter").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) =>
										<div>
											<div className={ `!p-2 ${ measurementCategoryCellClassNameDictionary()[ MeasurementCategory.INTERHARMONICS ] }` }>
												{ parameter.name }
											</div>
										</div>,
									sort: false,
									filter: false,
								},
							}, {
								name: "Value",
								label: t("TABLE.value").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => (
										<span>
											{ parameter.value !== null && parameter.value !== undefined ? parameter.value : "---" }
										</span>
									),
									sort: false,
									filter: false,
								},
							}, {
								name: "Unit",
								label: t("TABLE.unit").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => parameter.unit ?? "---",
									sort: false,
									filter: false,
								},
							},
						] }
						data={
							isNotNull(lastRecord) ? [
								isNotNull(lastRecord.interharmonics.U1n_tidf) ? {
									name: <Translate i18nKey="TABLES.U1N TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.U1n_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.U1n_tidf?.value, lastRecord.interharmonics.U1n_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.U2n_tidf) ? {
									name: <Translate i18nKey="TABLES.U2N TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.U2n_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.U2n_tidf?.value, lastRecord.interharmonics.U2n_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.U3n_tidf) ? {
									name: <Translate i18nKey="TABLES.U3N TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.U3n_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.U3n_tidf?.value, lastRecord.interharmonics.U3n_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.I1_tidf) ? {
									name: <Translate i18nKey="TABLES.I1 TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.I1_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.I1_tidf?.value, lastRecord.interharmonics.I1_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.I2_tidf) ? {
									name: <Translate i18nKey="TABLES.I2 TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.I2_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.I2_tidf?.value, lastRecord.interharmonics.I2_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.I3_tidf) ? {
									name: <Translate i18nKey="TABLES.I3 TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.I3_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.I3_tidf?.value, lastRecord.interharmonics.I3_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.In_tidf) ? {
									name: <Translate i18nKey="TABLES.IN TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.In_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.In_tidf?.value, lastRecord.interharmonics.In_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
								isNotNull(lastRecord.interharmonics.Ie_tidf) ? {
									name: <Translate i18nKey="TABLES.IE TIDF" className="whitespace-nowrap"/>,
									value: formatValueToDeciSON(lastRecord.interharmonics.Ie_tidf?.value, MeasurementCategory.INTERHARMONICS),
									unit: getPrefixToUnit(lastRecord.interharmonics.Ie_tidf?.value, lastRecord.interharmonics.Ie_tidf?.unit, MeasurementCategory.INTERHARMONICS),
								} : undefined,
							].filter(isNotNull) : []
						}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(InterharmonicContainer);
