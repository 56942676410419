import { Button, Modal } from "flowbite-react";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { Fragment, useContext } from "react";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import {
	aggregationPeriodUserDictionary,
	digitalInputStateDictionary,
	digitalOutputDictionary,
	ftpConnectionTypeDictionary,
	ftpModeDictionary,
	ftpUploadTimeDictionary,
	mapRelayDictionary,
	memoryModelDictionary,
	modbusBaudrateDictionary,
	modbusEndiannessDictionary,
	modbusParityDictionary,
	nominalFrequencyDictionary,
	phaseRotationDictionary,
	powerCalculationMethodDictionary,
	recordingOptionDictionary,
	standardTypeDictionary,
	systemTypeDictionary,
	timeSyncModeDictionary,
	timezoneDictionary,
	waveformFormatDictionary,
} from "src/app/utils/constants/dictionaries";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { isOptionSelected, translateEnumValue } from "src/app/utils/helpers";
import { Enum, EnumDictionary, EnumOptionsSetting, Nullable } from "src/app/types/util.types";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { connect, useSelector } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { LoadableType } from "src/app/types/ui/loading.types";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { FormItem } from "src/app/types/ui/form.types";
import { MapRelay, PowerCalculationMethod, RecordingOption } from "src/app/types/api/settings.types";
import { isNotNull } from "src/app/utils/typeguards";
import { Trans, useTranslation } from "react-i18next";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		onConfirm: () => void
		handleClose: () => void
	};

function ConfirmConfigurationModal(props: Props) {

	const { t } = useTranslation();

	const {
		isOpen,
		onConfirm,
		handleClose,
		isSaving,
	} = props;

	const {
		form,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const {
		recording,
		modbus,
		ftp,
		time,
		memory,
		measurementsUser,
		measurementsStandard,
		iec,
		io,
	} = useContext(SettingsContext);

	const reactivePowerName = (
		measurementsUser.measurementUser?.cfgPowerCalculationMethod?.enum?.find(enumOption => enumOption.value === form.cfgPowerCalculationMethod.value)?.text === PowerCalculationMethod.POWER_METHOD_IEEE1459
			?
			t("POWER.reactive power Q1")
			:
			t("POWER.reactive power QB")
	);

	const phaseVoltageName = (
		isNotNull(measurementsUser.cfgPhaseVoltageRms) ? t("MEASUREMENTS.phase voltage") : (
			isNotNull(measurementsUser.cfgPhaseToPhaseVoltageRms) ? t("MEASUREMENTS.phase to phase voltage") : t("COMMUNICATIONS.unknown")
		)
	);

	return (
		<Modal
			show={ isOpen || isSaving }
			onClose={ handleClose }
			size="4xl"
			root={ document.body }
			key={ (isOpen || isSaving) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ t("SETTINGS.save configuration") }
			</Modal.Header>
			<Modal.Body className="pt-2 px-5">
				<div className="space-y-2">
					<SnackbarComponent
						type={ SnackbarMessageType.WARNING }
						message={ t("SETTINGS.meter will be restarted info") }
					/>
					<DiffLabelValue
						label={ `${ t("SUMMARY.device info") } ➞ ${ t("SUMMARY.localization") }` }
						formItem={ form.localization }
					/>
					<DiffLabelValue
						label={ `${ t("SUMMARY.device info") } ➞ ${ t("SUMMARY.user info") }` }
						formItem={ form.userInfo }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("SUMMARY.system type") }` }
						formItem={ form.type }
						transformValue={ systemType => translateEnumValue(recording.recording?.cfgSystemType?.enum ?? [], systemType, systemTypeDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I4") }` }
						formItem={ form.ch4Enabled }
						transformValue={ ch4Enabled => ch4Enabled ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I5") }` }
						formItem={ form.ch5Enabled }
						transformValue={ ch5Enabled => ch5Enabled ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.nominal frequency") } [${ recording.recording?.cfgNominalFrequency?.unit ?? "---" }]` }
						formItem={ form.nominalFrequency }
						transformValue={ nominalFrequency => translateEnumValue(recording.recording?.cfgNominalFrequency?.enum ?? [], nominalFrequency, nominalFrequencyDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.nominal voltage") } [${ recording.recording?.cfgNominalVoltage?.unit ?? "---" }]` }
						formItem={ form.nominalVoltage }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.unpe channel enable") }` }
						formItem={ form.unpeChannelEnable }
						transformValue={ unpeChannelEnable => unpeChannelEnable ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.voltage transducer ratio") }` }
						formItem={ form.voltageTransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I1 transducer ratio") }` }
						formItem={ form.currentCh1TransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I2 transducer ratio") }` }
						formItem={ form.currentCh2TransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I3 transducer ratio") }` }
						formItem={ form.currentCh3TransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I4 transducer ratio") }` }
						formItem={ form.currentCh4TransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.current I5 transducer ratio") }` }
						formItem={ form.currentCh5TransducerRatio }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.analyzer") } ➞ ${ t("ANALYZER.phase rotation") }` }
						formItem={ form.phaseRotation }
						transformValue={ phaseRotation => translateEnumValue(recording.recording?.cfgPhaseRotation?.enum ?? [], phaseRotation, phaseRotationDictionary) }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.hysteresis") } [%]` }
						formItem={ form.eventsHysteresis }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.waveform format") }` }
						formItem={ form.waveformFormat }
						transformValue={ waveformFormat => translateEnumValue(recording.recording?.cfgWaveformFormat?.enum ?? [], waveformFormat, waveformFormatDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.recording duration") } [${ recording.recording?.cfgWaveformRecDuration?.unit ?? "---" }]` }
						formItem={ form.waveformRecDuration }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.pretrigger duration") } [${ recording.recording?.cfgWaveformPretriggerDuration?.unit ?? "---" }]` }
						formItem={ form.waveformPretriggerDuration }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.RMS 1/2 recording time") } [${ recording.recording?.cfgRms12RecTime?.unit ?? "---" }]` }
						formItem={ form.rms12RecTime }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.recordings") } ➞ ${ t("RECORDINGS.RMS 1/2 pretrigger time") } [${ recording.recording?.cfgRms12PretriggerTime?.unit ?? "---" }]` }
						formItem={ form.rms12PretriggerTime }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.user recording label %") }` }
						formItem={ form.userRecordingPerc }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.user data recording method") }` }
						formItem={ form.userDataModel }
						transformValue={ dataModel => translateEnumValue(memory.memory?.cfgUserDataMemoryModel?.enum ?? [], dataModel, memoryModelDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.standard recording label %") }` }
						formItem={ form.stdRecordingPerc }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.standard data recording method") }` }
						formItem={ form.stdDataModel }
						transformValue={ dataModel => translateEnumValue(memory.memory?.cfgStdDataMemoryModel?.enum ?? [], dataModel, memoryModelDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.counters label %") }` }
						formItem={ form.energyCountersPerc }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.memory") } ➞ ${ t("MEMORY.energy data recording method") }` }
						formItem={ form.energyDataModel }
						transformValue={ dataModel => translateEnumValue(memory.memory?.cfgEnergyDataMemoryModel?.enum ?? [], dataModel, memoryModelDictionary) }
					/>

					<DiffLabelValue
						label={ t("COMMUNICATIONS.modbus tcp") }
						formItem={ form.tcpEnabled }
						transformValue={ tcpEnabled => tcpEnabled ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus tcp") } ➞ ${ t("COMMUNICATIONS.word order") }` }
						formItem={ form.tcpEndianness }
						transformValue={ tcpEndianness => translateEnumValue(modbus.communications?.cfgModbusTcpEndianness?.enum ?? [], tcpEndianness, modbusEndiannessDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus tcp") } ➞ ${ t("COMMUNICATIONS.slave address") }` }
						formItem={ form.tcpSlaveAddress }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus tcp") } ➞ ${ t("COMMUNICATIONS.port") }` }
						formItem={ form.tcpPort }
					/>

					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus rtu") }` }
						formItem={ form.rtuEnabled }
						transformValue={ rtuEnabled => rtuEnabled ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus rtu") } ➞ ${ t("COMMUNICATIONS.word order") }` }
						formItem={ form.rtuEndianness }
						transformValue={ rtuEndianness => translateEnumValue(modbus.communications?.cfgModbusRtuEndianness?.enum ?? [], rtuEndianness, modbusEndiannessDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus rtu") } ➞ ${ t("COMMUNICATIONS.slave address") }` }
						formItem={ form.rtuSlaveAddress }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus rtu") } ➞ ${ t("COMMUNICATIONS.parity") }` }
						formItem={ form.rtuParity }
						transformValue={ rtuParity => translateEnumValue(modbus.communications?.cfgModbusRtuParity?.enum ?? [], rtuParity, modbusParityDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("COMMUNICATIONS.modbus rtu") } ➞ ${ t("COMMUNICATIONS.baudrate") }` }
						formItem={ form.rtuBaudrate }
						transformValue={ rtuBaudrate => translateEnumValue(modbus.communications?.cfgModbusRtuBaudrate?.enum ?? [], rtuBaudrate, modbusBaudrateDictionary) }
					/>

					<DiffLabelValue
						label={ `${ t("FTP.FTP client") }` }
						formItem={ form.ftpEnable }
						transformValue={ ftpEnable => ftpEnable ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.encryption") }` }
						formItem={ form.ftpConnectionType }
						transformValue={ ftpConnectionType => translateEnumValue(ftp.communications?.cfgFtpConnectionType?.enum ?? [], ftpConnectionType, ftpConnectionTypeDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.operating mode") }` }
						formItem={ form.ftpMode }
						transformValue={ ftpMode => translateEnumValue(ftp.communications?.cfgFtpMode?.enum ?? [], ftpMode, ftpModeDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.server ip") }` }
						formItem={ form.ftpServerIp }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.server port") }` }
						formItem={ form.ftpServerPort }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.login") }` }
						formItem={ form.ftpUserName }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.password") }` }
						formItem={ form.ftpPassword }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.destination directory") }` }
						formItem={ form.ftpDestDirectory }
					/>
					<DiffLabelValue
						label={ `${ t("FTP.FTP client") } ➞ ${ t("FTP.automatic upload time") }` }
						formItem={ form.ftpUploadTime }
						transformValue={ ftpUploadTime => ftpUploadTimeDictionary()[ ftpUploadTime ] }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.RTC time") }` }
						formItem={ form.rtcUtcTimestamp }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.time synchronisation") }` }
						formItem={ form.timeSyncMode }
						transformValue={ timeSyncMode => translateEnumValue(time.time?.cfgTimeSyncMode?.enum ?? [], timeSyncMode, timeSyncModeDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.timezone") }` }
						formItem={ form.timezone }
						transformValue={ timezone => timezoneDictionary()[ timezone ] }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.daylight saving time") }` }
						formItem={ form.daylightSavingsTime }
						transformValue={ daylightSavingsTime => daylightSavingsTime ? t("COMMUNICATIONS.enabled_he") : t("COMMUNICATIONS.disabled_he") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.NTP enable") }` }
						formItem={ form.NTPEnable }
						transformValue={ NTPEnable => NTPEnable ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.NTP server 1") }` }
						formItem={ form.NTPServer1Name }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.NTP server 2") }` }
						formItem={ form.NTPServer2Name }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.NTP server 3") }` }
						formItem={ form.NTPServer3Name }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.time") } ➞ ${ t("SIDEBAR.NTP server 4") }` }
						formItem={ form.NTPServer4Name }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") }` }
						formItem={ form.cfgUserEnable }
						transformValue={ value => value ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.averaging period") }` }
						formItem={ form.cfgAggregationPeriodUser }
						transformValue={ value => translateEnumValue(measurementsUser.measurementUser?.cfgAggregationPeriodUser?.enum ?? [], value, aggregationPeriodUserDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.log waveforms and RMS 1/2") }` }
						formItem={ form.cfgUserAggregationWaveformEnable }
						transformValue={ cfgUserAggregationWaveformEnable => cfgUserAggregationWaveformEnable ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.reactive power") }` }
						formItem={ form.cfgPowerCalculationMethod }
						transformValue={ value => translateEnumValue(measurementsUser.measurementUser?.cfgPowerCalculationMethod?.enum ?? [], value, powerCalculationMethodDictionary) }
					/>

					<RecOptDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName }` }
						formItem={ form.cfgVoltageRmsRecOpt }
						options={ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsRecOpt?.options ?? [] }
						unit="V"
						unitPercent="% Un"
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.dips") } [${ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserDip?.unit ?? "V" }]` }
						formItem={ form.cfgVoltageRmsEventThresholdUserDip }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.dips") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgDipChannels }
						keysWithTranslations={ {
							cfgRelayVoltageDip1RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch1 ?? "Channel 1",
							cfgRelayVoltageDip2RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch2 ?? "Channel 2",
							cfgRelayVoltageDip3RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch3 ?? "Channel 3",
							cfgRelayVoltageDip4RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch4 ?? "Channel 4",
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.dips") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgDipActions }
						options={ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgRelayVoltageDip1Map?.options ?? [] }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.swells") } [${ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserSwell?.unit ?? "V" }]` }
						formItem={ form.cfgVoltageRmsEventThresholdUserSwell }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.swells") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgSwellChannels }
						keysWithTranslations={ {
							cfgRelayVoltageSwell1RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch1 ?? "Channel 1",
							cfgRelayVoltageSwell2RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch2 ?? "Channel 2",
							cfgRelayVoltageSwell3RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch3 ?? "Channel 3",
							cfgRelayVoltageSwell4RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch4 ?? "Channel 4",
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.swells") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgSwellActions }
						options={ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgRelayVolatgeSwell1Map?.options ?? [] }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.interruptions") } [${ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserInterruption?.unit ?? "V" }]` }
						formItem={ form.cfgVoltageRmsEventThresholdUserInterruption }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.interruptions") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgInterruptChannels }
						keysWithTranslations={ {
							cfgRelayVoltageInterrupt1RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch1 ?? "Channel 1",
							cfgRelayVoltageInterrupt2RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch2 ?? "Channel 2",
							cfgRelayVoltageInterrupt3RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch3 ?? "Channel 3",
							cfgRelayVoltageInterrupt4RecOpt: (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.relayChannelLabel?.ch4 ?? "Channel 4",
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.interruptions") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgInterruptActions }
						options={ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgRelayVoltageInterrupt1Map?.options ?? [] }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.N-E voltage over limit") } [${ (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdMaxCh4?.unit ?? "V" }]` }
						formItem={ form.cfgVoltageRmsEventThresholdMaxCh4 }
					/>
					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ` }<Translate i18nKey="MEASUREMENTS.U dc component"/></span> }
						formItem={ form.cfgVoltageDcRecOpt }
						options={ measurementsUser.cfgVoltageDc?.cfgVoltageDcRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.waveshape variations") }` }
						formItem={ form.cfgWaveshapeVariationsRecOpt }
						options={ measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.waveshape variations") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgWaveshapeVariationsChannels }
						keysWithTranslations={ {
							cfgRelayWaveshape1RecOpt: measurementsUser?.cfgWaveshapeVariations?.relayChannelLabel?.ch1,
							cfgRelayWaveshape2RecOpt: measurementsUser?.cfgWaveshapeVariations?.relayChannelLabel?.ch2,
							cfgRelayWaveshape3RecOpt: measurementsUser?.cfgWaveshapeVariations?.relayChannelLabel?.ch3,
							cfgRelayWaveshape4RecOpt: measurementsUser?.cfgWaveshapeVariations?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.waveshape variations") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgWaveshapeVariationsActions }
						options={ measurementsUser?.cfgWaveshapeVariations?.cfgRelayWaveshape1Map?.options ?? [] }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.waveshape variations") } [${ measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.unit ?? "---" }]` }
						formItem={ form.cfgWaveshapeVariationsThreshold }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.waveshape variations") } ➞ ${ t("MEASUREMENTS.hold off time") } [${ measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.unit ?? "---" }]` }
						formItem={ form.cfgWaveshapeVariationsDeadtime }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.phase jumps") }` }
						formItem={ form.cfgPhaseJumpsRecOpt }
						options={ measurementsUser.cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.phase jumps") } [${ measurementsUser.cfgPhaseJumps?.cfgPhaseJumpsThreshold?.unit ?? "---" }]` }
						formItem={ form.cfgPhaseJumpsThreshold }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.phase jumps") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgPhaseJumpsChannels }
						keysWithTranslations={ {
							cfgRelayPhaseJump1RecOpt: measurementsUser?.cfgPhaseJumps?.relayChannelLabel?.ch1,
							cfgRelayPhaseJump2RecOpt: measurementsUser?.cfgPhaseJumps?.relayChannelLabel?.ch2,
							cfgRelayPhaseJump3RecOpt: measurementsUser?.cfgPhaseJumps?.relayChannelLabel?.ch3,
							cfgRelayPhaseJump4RecOpt: measurementsUser?.cfgPhaseJumps?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ phaseVoltageName } ➞ ${ t("MEASUREMENTS.phase jumps") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgPhaseJumpsActions }
						options={ measurementsUser?.cfgPhaseJumps?.cfgRelayPhaseJump1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("UTILS.frequency") }` }
						formItem={ form.cfgFrequencyRecOpt }
						options={ measurementsUser.cfgFrequency?.cfgFrequencyRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("UTILS.frequency") } ➞ ${ t("MEASUREMENTS.min") } [${ isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, measurementsUser?.cfgFrequency?.cfgFrequencyRecOpt?.options ?? []) ? "% " : "" }${ measurementsUser.cfgFrequency?.cfgFrequencyEventThresholdMin?.unit ?? "---" }]` }
						formItem={ form.cfgFrequencyEventThresholdMin }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("UTILS.frequency") } ➞ ${ t("MEASUREMENTS.max") } [${ isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, measurementsUser?.cfgFrequency?.cfgFrequencyRecOpt?.options ?? []) ? "% " : "" }${ measurementsUser.cfgFrequency?.cfgFrequencyEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgFrequencyEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("UTILS.frequency") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgFrequencyChannels }
						keysWithTranslations={ {
							cfgRelayFrequencyRecOpt: measurementsUser?.cfgFrequency?.relayChannelLabel?.ch1,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("UTILS.frequency") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgFrequencyActions }
						options={ measurementsUser?.cfgFrequency?.cfgRelayFrequencyMap?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.crest factor") }` }
						formItem={ form.cfgVoltageCrestFactorRecOpt }
						options={ measurementsUser.cfgVoltageCrestFactor?.cfgVoltageCrestFactorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.unbalance and symmetrical components") }` }
						formItem={ form.cfgVoltageUnbalanceRecOpt }
						options={ measurementsUser.cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.unbalance and symmetrical components") } ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgVoltageUnbalanceThresholdMax }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.short term flicker severity pst") }` }
						formItem={ form.cfgFlickerPstRecOpt }
						options={ measurementsUser.cfgFlickerPst?.cfgFlickerPstRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.short term flicker severity pst") } ➞ ${ t("MEASUREMENTS.max") }` }
						formItem={ form.cfgFlickerPstThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.short term flicker severity pst") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgFlickerPstChannels }
						keysWithTranslations={ {
							cfgRelayFlickerPst1RecOpt: measurementsUser?.cfgFlickerPst?.relayChannelLabel?.ch1,
							cfgRelayFlickerPst2RecOpt: measurementsUser?.cfgFlickerPst?.relayChannelLabel?.ch2,
							cfgRelayFlickerPst3RecOpt: measurementsUser?.cfgFlickerPst?.relayChannelLabel?.ch3,
							cfgRelayFlickerPst4RecOpt: measurementsUser?.cfgFlickerPst?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.short term flicker severity pst") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgFlickerPstActions }
						options={ measurementsUser?.cfgFlickerPst?.cfgRelayFlickerPst1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.long term flicker severity plt") }` }
						formItem={ form.cfgFlickerPltRecOpt }
						options={ measurementsUser.cfgFlickerPlt?.cfgFlickerPltRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.long term flicker severity plt") } ➞ ${ t("MEASUREMENTS.max") }` }
						formItem={ form.cfgFlickerPltThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.long term flicker severity plt") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgFlickerPltChannels }
						keysWithTranslations={ {
							cfgRelayFlickerPlt1RecOpt: measurementsUser?.cfgFlickerPlt?.relayChannelLabel?.ch1,
							cfgRelayFlickerPlt2RecOpt: measurementsUser?.cfgFlickerPlt?.relayChannelLabel?.ch2,
							cfgRelayFlickerPlt3RecOpt: measurementsUser?.cfgFlickerPlt?.relayChannelLabel?.ch3,
							cfgRelayFlickerPlt4RecOpt: measurementsUser?.cfgFlickerPlt?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("UTILS.voltages") } ➞ ${ t("MEASUREMENTS.long term flicker severity plt") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgFlickerPltActions }
						options={ measurementsUser?.cfgFlickerPlt?.cfgRelayFlickerPlt1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") }` }
						formItem={ form.cfgCurrentRmsRecOpt }
						options={ measurementsUser.cfgCurrentRms?.cfgCurrentRmsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") } ➞ ${ t("MEASUREMENTS.max L") } [${ measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgCurrentRmsEventThresholdMax }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") } ➞ ${ t("MEASUREMENTS.max N") } [${ measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.unit ?? "---" }]` }
						formItem={ form.cfgCurrentRmsEventThresholdMaxCh4 }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") } ➞ ${ t("MEASUREMENTS.max E") } [${ measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.unit ?? "---" }]` }
						formItem={ form.cfgCurrentRmsEventThresholdMaxCh5 }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgCurrentChannels }
						keysWithTranslations={ {
							cfgRelayCurrent1RecOpt: measurementsUser?.cfgCurrentRms?.relayChannelLabel?.ch1,
							cfgRelayCurrent2RecOpt: measurementsUser?.cfgCurrentRms?.relayChannelLabel?.ch2,
							cfgRelayCurrent3RecOpt: measurementsUser?.cfgCurrentRms?.relayChannelLabel?.ch3,
							cfgRelayCurrent4RecOpt: measurementsUser?.cfgCurrentRms?.relayChannelLabel?.ch4,
							cfgRelayCurrent5RecOpt: measurementsUser?.cfgCurrentRms?.relayChannelLabel?.ch5,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.phase current") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgCurrentActions }
						options={ measurementsUser?.cfgCurrentRms?.cfgRelayCurrent1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.crest factor") }` }
						formItem={ form.cfgCurrentCrestFactorRecOpt }
						options={ measurementsUser.cfgCurrentCrestFactor?.cfgCurrentCrestFactorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS.current") } ➞ ${ t("MEASUREMENTS.unbalance and symmetrical components") }` }
						formItem={ form.cfgCurrentUnbalanceRecOpt }
						options={ measurementsUser.cfgCurrentUnbalance?.cfgCurrentUnbalanceRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power P") }` }
						formItem={ form.cfgActivePowerRecOpt }
						options={ measurementsUser.cfgActivePower?.cfgActivePowerRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power P") } ➞ ${ t("MEASUREMENTS.max") }` }
						formItem={ form.cfgActivePowerEventThresholdMax }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power plus") }` }
						formItem={ form.cfgActivePowerPlusRecOpt }
						options={ measurementsUser.cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power plus") } ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgActivePowerPlusEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power plus") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgActivePowerPlusChannels }
						keysWithTranslations={ {
							cfgRelayActivePowerPlus1RecOpt: measurementsUser?.cfgActivePowerPlus?.relayChannelLabel?.ch1,
							cfgRelayActivePowerPlus2RecOpt: measurementsUser?.cfgActivePowerPlus?.relayChannelLabel?.ch2,
							cfgRelayActivePowerPlus3RecOpt: measurementsUser?.cfgActivePowerPlus?.relayChannelLabel?.ch3,
							cfgRelayActivePowerPlus4RecOpt: measurementsUser?.cfgActivePowerPlus?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power plus") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgActivePowerPlusActions }
						options={ measurementsUser?.cfgActivePowerPlus?.cfgRelayActivePowerPlus1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power minus") }` }
						formItem={ form.cfgActivePowerMinusRecOpt }
						options={ measurementsUser.cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power minus") } ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgActivePowerMinusEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power minus") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgActivePowerMinusChannels }
						keysWithTranslations={ {
							cfgRelayActivePowerMinus1RecOpt: measurementsUser?.cfgActivePowerMinus?.relayChannelLabel?.ch1,
							cfgRelayActivePowerMinus2RecOpt: measurementsUser?.cfgActivePowerMinus?.relayChannelLabel?.ch2,
							cfgRelayActivePowerMinus3RecOpt: measurementsUser?.cfgActivePowerMinus?.relayChannelLabel?.ch3,
							cfgRelayActivePowerMinus4RecOpt: measurementsUser?.cfgActivePowerMinus?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power minus") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgActivePowerMinusActions }
						options={ measurementsUser?.cfgActivePowerMinus?.cfgRelayActivePowerMinus1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.active power P1") }` }
						formItem={ form.cfgActivePowerFundamentalRecOpt }
						options={ measurementsUser.cfgActivePowerFundamental?.cfgActivePowerFundamentalRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.apparent power S") }` }
						formItem={ form.cfgApparentPowerRecOpt }
						options={ measurementsUser.cfgApparentPower?.cfgApparentPowerRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.apparent power S") } ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgApparentPower?.cfgApparentPowerEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgApparentPowerEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.apparent power S") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgApparentPowerChannels }
						keysWithTranslations={ {
							cfgRelayApparentPower1RecOpt: measurementsUser?.cfgApparentPower?.relayChannelLabel?.ch1,
							cfgRelayApparentPower2RecOpt: measurementsUser?.cfgApparentPower?.relayChannelLabel?.ch2,
							cfgRelayApparentPower3RecOpt: measurementsUser?.cfgApparentPower?.relayChannelLabel?.ch3,
							cfgRelayApparentPower4RecOpt: measurementsUser?.cfgApparentPower?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.apparent power S") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgApparentPowerActions }
						options={ measurementsUser?.cfgApparentPower?.cfgRelayApparentPower1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.apparent power S1") }` }
						formItem={ form.cfgApparentPowerFundamentalRecOpt }
						options={ measurementsUser.cfgApparentPowerFundamental?.cfgApparentPowerFundamentalRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.distortion power SN") }` }
						formItem={ form.cfgApparentPowerNonfundamentalRecOpt }
						options={ measurementsUser.cfgApparentPowerNonfundamental?.cfgApparentPowerNonfundamentalRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.distortion power D") }` }
						formItem={ form.cfgDistortionPowerBudeanuRecOpt }
						options={ measurementsUser.cfgDistortionPowerBudeanu?.cfgDistortionPowerBudeanuRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ reactivePowerName }` }
						formItem={ form.cfgReactivePowerRecOpt }
						options={ measurementsUser.cfgReactivePower?.cfgReactivePowerRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ reactivePowerName } ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgReactivePower?.cfgReactivePowerEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgReactivePowerEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ reactivePowerName } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgReactivePowerChannels }
						keysWithTranslations={ {
							cfgRelayReactivePower1RecOpt: measurementsUser?.cfgReactivePower?.relayChannelLabel?.ch1,
							cfgRelayReactivePower2RecOpt: measurementsUser?.cfgReactivePower?.relayChannelLabel?.ch2,
							cfgRelayReactivePower3RecOpt: measurementsUser?.cfgReactivePower?.relayChannelLabel?.ch3,
							cfgRelayReactivePower4RecOpt: measurementsUser?.cfgReactivePower?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ reactivePowerName } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgReactivePowerActions }
						options={ measurementsUser?.cfgReactivePower?.cfgRelayReactivePower1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.power factor") }` }
						formItem={ form.cfgPowerFactorRecOpt }
						options={ measurementsUser.cfgPowerFactor?.cfgPowerFactorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.power factor") } ➞ ${ t("MEASUREMENTS.max") }` }
						formItem={ form.cfgPowerFactorEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.power factor") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgPowerFactorChannels }
						keysWithTranslations={ {
							cfgRelayPowerFactor1RecOpt: measurementsUser?.cfgPowerFactor?.relayChannelLabel?.ch1,
							cfgRelayPowerFactor2RecOpt: measurementsUser?.cfgPowerFactor?.relayChannelLabel?.ch2,
							cfgRelayPowerFactor3RecOpt: measurementsUser?.cfgPowerFactor?.relayChannelLabel?.ch3,
							cfgRelayPowerFactor4RecOpt: measurementsUser?.cfgPowerFactor?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.power factor") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgPowerFactorActions }
						options={ measurementsUser?.cfgPowerFactor?.cfgRelayPowerFactor1Map?.options ?? [] }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.power factor PF1") }` }
						formItem={ form.cfgPowerFactorFundamentalRecOpt }
						options={ measurementsUser.cfgPowerFactorFundamental?.cfgPowerFactorFundamentalRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.cos") }` }
						formItem={ form.cfgDisplacementPowerFactorRecOpt }
						options={ measurementsUser.cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.cos") } ➞ ${ t("MEASUREMENTS.max") }` }
						formItem={ form.cfgDisplacementPowerFactorEventThresholdMax }
					/>
					<ChannelsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.cos") } ➞ ${ t("MEASUREMENTS.relay channels") }` }
						formItem={ form.cfgDisplacementPowerFactorChannels }
						keysWithTranslations={ {
							cfgRelayDisplacementPowerFactor1RecOpt: measurementsUser?.cfgDisplacementPowerFactor?.relayChannelLabel?.ch1,
							cfgRelayDisplacementPowerFactor2RecOpt: measurementsUser?.cfgDisplacementPowerFactor?.relayChannelLabel?.ch2,
							cfgRelayDisplacementPowerFactor3RecOpt: measurementsUser?.cfgDisplacementPowerFactor?.relayChannelLabel?.ch3,
							cfgRelayDisplacementPowerFactor4RecOpt: measurementsUser?.cfgDisplacementPowerFactor?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.cos") } ➞ ${ t("MEASUREMENTS.relay action") }` }
						formItem={ form.cfgDisplacementPowerFactorActions }
						options={ measurementsUser?.cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("POWER.power") } ➞ ${ t("POWER.tangent") }` }
						formItem={ form.cfgTangent4QRecOpt }
						options={ measurementsUser.cfgTangent4Q?.cfgTangent4QRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					{/*<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.min") } tan(φ) L+"
						formItem={ form.cfgTangentQuadrant1EventThresholdMin }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.max") } tan(φ) L+"
						formItem={ form.cfgTangentQuadrant1EventThresholdMax }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.min") } tan(φ) C-"
						formItem={ form.cfgTangentQuadrant2EventThresholdMin }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.max") } tan(φ) C-"
						formItem={ form.cfgTangentQuadrant2EventThresholdMax }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.min") } tan(φ) L-"
						formItem={ form.cfgTangentQuadrant3EventThresholdMin }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.max") } tan(φ) L-"
						formItem={ form.cfgTangentQuadrant3EventThresholdMax }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.min") } tan(φ) C+"
						formItem={ form.cfgTangentQuadrant4EventThresholdMin }
					/>
					<DiffLabelValue
						label="tan(φ) ➞ ${ t("MEASUREMENTS.max") } tan(φ) C+"
						formItem={ form.cfgTangentQuadrant4EventThresholdMax }
					/>*/ }

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.active energy"/></span> }
						formItem={ form.cfgActiveEnergyRecOpt }
						options={ measurementsUser.cfgActiveEnergy?.cfgActiveEnergyRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.active energy"/>{ ` ➞ ` } <Translate i18nKey="ENERGY.max active energy +"/>{ ` [${ measurementsUser.cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgActiveEnergyPlusEventThresholdMax }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.active energy"/>{ ` ➞ ` } <Translate i18nKey="ENERGY.max active energy -"/>{ ` [${ measurementsUser.cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgActiveEnergyMinusEventThresholdMax }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ${ t("ENERGY.apparent energy Es") }` }
						formItem={ form.cfgApparentEnergyRecOpt }
						options={ measurementsUser.cfgApparentEnergy?.cfgApparentEnergyRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					{/*<DiffLabelValue
						label={ `Apparent energy Es ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.unit ?? "---" }]` }
						formItem={ form.cfgApparentEnergyEventThresholdMax }
					/>*/ }

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.reactive energy"/></span> }
						formItem={ form.cfgReactiveEnergy4QRecOpt }
						options={ measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.reactive energy"/>{ ` ➞ ` }<Translate i18nKey="ENERGY.reactive energy q1"/>{ ` [${ measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgReactiveEnergyQuadrant1EventThresholdMax }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.reactive energy"/>{ ` ➞ ` }<Translate i18nKey="ENERGY.reactive energy q2"/>{ ` [${ measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgReactiveEnergyQuadrant2EventThresholdMax }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.reactive energy"/>{ ` ➞ ` }<Translate i18nKey="ENERGY.reactive energy q3"/>{ ` [${ measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgReactiveEnergyQuadrant3EventThresholdMax }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ENERGY.energy") } ➞ ` }<Translate i18nKey="ENERGY.reactive energy"/>{ ` ➞ ` }<Translate i18nKey="ENERGY.reactive energy q4"/>{ ` [${ measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgReactiveEnergyQuadrant4EventThresholdMax }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDF"/></span> }
						formItem={ form.cfgVoltageThdfRecOpt }
						options={ measurementsUser.cfgVoltageThdf?.cfgVoltageThdfRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDF"/>{ ` ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgVoltageThdfThresholdMax }
					/>
					<ChannelsDiff
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDF"/>{ ` ➞ ${ t("MEASUREMENTS.relay channels") }` }</span> }
						formItem={ form.cfgVoltageThdfChannels }
						keysWithTranslations={ {
							cfgRelayVoltageThdf1RecOpt: measurementsUser?.cfgVoltageThdf?.relayChannelLabel?.ch1,
							cfgRelayVoltageThdf2RecOpt: measurementsUser?.cfgVoltageThdf?.relayChannelLabel?.ch2,
							cfgRelayVoltageThdf3RecOpt: measurementsUser?.cfgVoltageThdf?.relayChannelLabel?.ch3,
							cfgRelayVoltageThdf4RecOpt: measurementsUser?.cfgVoltageThdf?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDF"/>{ ` ➞ ${ t("MEASUREMENTS.relay action") }` }</span> }
						formItem={ form.cfgVoltageThdfActions }
						options={ measurementsUser?.cfgVoltageThdf?.cfgRelayVoltageThdf1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage THDR"/></span> }
						formItem={ form.cfgVoltageThdrRecOpt }
						options={ measurementsUser.cfgVoltageThdr?.cfgVoltageThdrRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS.voltage harmonics amplitudes") }` }
						formItem={ form.cfgVoltageHarmonicsRecOpt }
						options={ measurementsUser.cfgVoltageHarmonics?.cfgVoltageHarmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current THDF"/></span> }
						formItem={ form.cfgCurrentThdfRecOpt }
						options={ measurementsUser.cfgCurrentThdf?.cfgCurrentThdfRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current THDF"/>{ ` ➞ ${ t("MEASUREMENTS.max") } [${ measurementsUser.cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgCurrentThdfThresholdMax }
					/>

					<ChannelsDiff
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current THDF"/>{ ` ➞ ${ t("MEASUREMENTS.relay channels") }` }</span> }
						formItem={ form.cfgCurrentThdfChannels }
						keysWithTranslations={ {
							cfgRelayCurrentThdf1RecOpt: measurementsUser?.cfgCurrentThdf?.relayChannelLabel?.ch1,
							cfgRelayCurrentThdf2RecOpt: measurementsUser?.cfgCurrentThdf?.relayChannelLabel?.ch2,
							cfgRelayCurrentThdf3RecOpt: measurementsUser?.cfgCurrentThdf?.relayChannelLabel?.ch3,
							cfgRelayCurrentThdf4RecOpt: measurementsUser?.cfgCurrentThdf?.relayChannelLabel?.ch4,
						} }
					/>
					<ActionsDiff
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current THDF"/>{ ` ➞ ${ t("MEASUREMENTS.relay action") }` }</span> }
						formItem={ form.cfgCurrentThdfActions }
						options={ measurementsUser?.cfgCurrentThdf?.cfgRelayCurrentThdf1Map?.options ?? [] }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current THDR"/></span> }
						formItem={ form.cfgCurrentThdrRecOpt }
						options={ measurementsUser.cfgCurrentThdr?.cfgCurrentThdrRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.current harmonics amplitudes") }` }
						formItem={ form.cfgCurrentHarmonicsRecOpt }
						options={ measurementsUser.cfgCurrentHarmonics?.cfgCurrentHarmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics active power") }` }
						formItem={ form.cfgActivePowerHarmonicsRecOpt }
						options={ measurementsUser.cfgActivePowerHarmonics?.cfgActivePowerHarmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.phase angles between vol and curr") }` }
						formItem={ form.cfgHarmonicsAnglesUiRecOpt }
						options={ measurementsUser.cfgHarmonicsAnglesUi?.cfgHarmonicsAnglesUiRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics reactive power") }` }
						formItem={ form.cfgReactivePowerHarmonicsRecOpt }
						options={ measurementsUser.cfgReactivePowerHarmonics?.cfgReactivePowerHarmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage TIDF"/></span> }
						formItem={ form.cfgVoltageTidfRecOpt }
						options={ measurementsUser.cfgVoltageTidf?.cfgVoltageTidfRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.voltage TIDR"/></span> }
						formItem={ form.cfgVoltageTidrRecOpt }
						options={ measurementsUser.cfgVoltageTidr?.cfgVoltageTidrRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current TIDF"/></span> }
						formItem={ form.cfgCurrentTidfRecOpt }
						options={ measurementsUser.cfgCurrentTidf?.cfgCurrentTidfRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ` }<Translate i18nKey="MEASUREMENTS HARMONICS.current TIDR"/></span> }
						formItem={ form.cfgCurrentTidrRecOpt }
						options={ measurementsUser.cfgCurrentTidr?.cfgCurrentTidrRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.voltage interharmonics amplitudes") }` }
						formItem={ form.cfgVoltageInterharmonicsRecOpt }
						options={ measurementsUser.cfgVoltageInterharmonics?.cfgVoltageInterharmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.current interharmonics amplitudes") }` }
						formItem={ form.cfgCurrentInterharmonicsRecOpt }
						options={ measurementsUser.cfgCurrentInterharmonics?.cfgCurrentInterharmonicsRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.k factor") }` }
						formItem={ form.cfgKfactorRecOpt }
						options={ measurementsUser.cfgKfactor?.cfgKfactorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("ADDITIONALS.additionals") } ➞ ${ t("ADDITIONALS.log phasors after each averaging period") }` }
						formItem={ form.cfgPhasorRecOpt }
						options={ measurementsUser.measurementUser?.cfgPhasorRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.ripple control signal UR1") }` }
						formItem={ form.cfgMainsSignallingUr1RecOpt }
						options={ measurementsUser.cfgMainsSignallingUr1?.cfgMainsSignallingUr1RecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.ripple control signal UR1") } ➞ ${ t("MEASUREMENTS HARMONICS.frequency") }` }
						formItem={ form.cfgMainsSignallingUr1Frequency }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.ripple control signal UR2") }` }
						formItem={ form.cfgMainsSignallingUr2RecOpt }
						options={ measurementsUser.cfgMainsSignallingUr2?.cfgMainsSignallingUr2RecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.ripple control signal UR2") } ➞ ${ t("MEASUREMENTS HARMONICS.frequency") }` }
						formItem={ form.cfgMainsSignallingUr2Frequency }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.2 9 kHz monitoring") }` }
						formItem={ form.cfgVoltage29KRecOpt }
						options={ measurementsUser.cfgVoltage29K?.cfgVoltage29KRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.user") } ➞ ${ t("MEASUREMENTS HARMONICS.harmonics") } ➞ ${ t("MEASUREMENTS HARMONICS.8 150 kHz monitoring") }` }
						formItem={ form.cfgVoltage8150KRecOpt }
						options={ measurementsUser.cfgVoltage8150K?.cfgVoltage8150KRecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.standard") }` }
						formItem={ form.cfgStdEnable }
						transformValue={ value => value ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.standard") } ➞ ${ t("MEASUREMENTS.logging according to") }` }
						formItem={ form.cfgStdType }
						transformValue={ value => translateEnumValue(measurementsStandard.measurementStandard?.cfgStdType?.enum ?? [], value, standardTypeDictionary) }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.measurements") } ➞ ${ t("MEASUREMENTS.standard") } ➞ ${ t("MEASUREMENTS.log waveforms and RMS 1/2") }` }
						formItem={ form.cfgStdAggregationWaveformEnable }
						transformValue={ cfgStdAggregationWaveformEnable => cfgStdAggregationWaveformEnable ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") }` }
						formItem={ form.cfgIec61850Enable }
						transformValue={ cfgIec61850Enable => cfgIec61850Enable ? t("COMMUNICATIONS.enabled_it") : t("COMMUNICATIONS.disabled_it") }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.IED name") }` }
						formItem={ form.cfgIec61850IedName }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850PhaseVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850PhaseVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850PhaseVoltageDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase to phase voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850PhaseToPhaseVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase to phase voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850PhaseToPhaseVoltageDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.voltage neutral earth") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UneVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UneVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.voltage neutral earth") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UneVoltageDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase currents") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850PhaseCurrentDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850PhaseCurrentDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.phase currents") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850PhaseCurrentDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.neutral current") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850NCurrentDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850NCurrentDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.neutral current") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850NCurrentDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.earth current") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ECurrentDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ECurrentDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.earth current") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ECurrentDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total active power") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ActivePowerTotalDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ActivePowerTotalDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total active power") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ActivePowerTotalDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total reactive power") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ReactivePowerTotalDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ReactivePowerTotalDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total reactive power") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ReactivePowerTotalDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total apparent power") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ApparentPowerTotalDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ApparentPowerTotalDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.total apparent power") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ApparentPowerTotalDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.active power phase") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ActivePowerPhaseDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ActivePowerPhaseDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.active power phase") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ActivePowerPhaseDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.reactive power phase") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ReactivePowerPhaseDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.reactive power phase") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ReactivePowerPhaseDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.apparent power phase") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850ApparentPowerPhaseDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.apparent power phase") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850ApparentPowerPhaseDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.frequency") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850FrequencyDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850FrequencyDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.frequency") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850FrequencyDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.COMMUNICATIONS.harmonics U") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850VoltageHarmonicsDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850VoltageHarmonicsDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.COMMUNICATIONS.harmonics U") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850VoltageHarmonicsDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics I") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850CurrentHarmonicsDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850CurrentHarmonicsDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics I") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850CurrentHarmonicsDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.interharmonics U") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850VoltageInterharmonicsDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.interharmonics U") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850VoltageInterharmonicsDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.interharmonics I") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850CurrentInterharmonicsDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.interharmonics I") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850CurrentInterharmonicsDb }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.voltage THD"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850VoltageThdDbRef?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgIec61850VoltageThdDbRef }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.voltage THD"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }</span> }
						formItem={ form.cfgIec61850VoltageThdDb }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.current THD"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850CurrentThdDbRef?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgIec61850CurrentThdDbRef }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.current THD"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }</span> }
						formItem={ form.cfgIec61850CurrentThdDb }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.voltage TID"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850VoltageTidDbRef?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgIec61850VoltageTidDbRef }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.voltage TID"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }</span> }
						formItem={ form.cfgIec61850VoltageTidDb }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.current TID"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850CurrentTidDbRef?.unit ?? "---" }]` }</span> }
						formItem={ form.cfgIec61850CurrentTidDbRef }
					/>
					<DiffLabelValue
						label={ <span>{ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ` }<Translate i18nKey="COMMUNICATIONS.current TID"/>{ ` ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }</span> }
						formItem={ form.cfgIec61850CurrentTidDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.positive sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.positive sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceVoltagePositiveSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.negative sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.negative sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceVoltageNegativeSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.zero sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.zero sequence voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceVoltageZeroSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.unbalance U") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceVoltageNegativeDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.unbalance U") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceVoltageNegativeDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.positive sequence current") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.positive sequence current") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceCurrentPositiveSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.negative sequence current") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.negative sequence current") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceCurrentNegativeSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.zero sequence current") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.zero sequence current") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceCurrentZeroSequenceDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.unbalance I") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850UnbalanceCurrentNegativeDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.unbalance I") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850UnbalanceCurrentNegativeDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.flicker short") } ➞ ${ t("COMMUNICATIONS.dead band ref") }` }
						formItem={ form.cfgIec61850FlickerPstDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.flicker short") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850FlickerPstDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.flicker long") } ➞ ${ t("COMMUNICATIONS.dead band ref") }` }
						formItem={ form.cfgIec61850FlickerPltDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.flicker long") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850FlickerPltDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics in the freq 2-9kHz") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec6185029KhzVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec6185029KhzVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics in the freq 2-9kHz") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec6185029KhzVoltageDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 2-9kHz") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec6185029KhzVoltageMaxDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 2-9kHz") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec6185029KhzVoltageMaxDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics in the frequency 9-150kHz") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec618509150KhzVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec618509150KhzVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.harmonics in the frequency 9-150kHz") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec618509150KhzVoltageDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 9-150kHz") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec618509150KhzVoltageMaxDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 9-150kHz") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec618509150KhzVoltageMaxDb }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.external battery DC voltage") } ➞ ${ t("COMMUNICATIONS.dead band ref") } [${ iec.communications?.cfgIec61850BatteryVoltageDbRef?.unit ?? "---" }]` }
						formItem={ form.cfgIec61850BatteryVoltageDbRef }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.communications") } ➞ ${ t("COMMUNICATIONS.iec 61850") } ➞ ${ t("COMMUNICATIONS.external battery DC voltage") } ➞ ${ t("COMMUNICATIONS.dead band") } [%]` }
						formItem={ form.cfgIec61850BatteryVoltageDb }
					/>

					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.digital inputs") } ➞ ${ t("INPUTOUTPUT.digital input 1") }` }
						formItem={ form.cfgDigitalInputInternal1RecOpt }
						options={ io.io?.cfgDigitalInputInternal1RecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.digital inputs") } ➞ ${ t("INPUTOUTPUT.digital input 1 state") }` }
						formItem={ form.cfgDigitalInputInternal1ActiveState }
						transformValue={ cfgDigitalInputInternal1ActiveState => translateEnumValue(io.io?.cfgDigitalInputInternal1ActiveState?.enum ?? [], cfgDigitalInputInternal1ActiveState, digitalInputStateDictionary) }
					/>
					<EnumDiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.digital inputs") } ➞ ${ t("INPUTOUTPUT.digital input 2") }` }
						formItem={ form.cfgDigitalInputInternal2RecOpt }
						options={ io.io?.cfgDigitalInputInternal2RecOpt?.options ?? [] }
						dictionary={ recordingOptionDictionary }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.digital inputs") } ➞ ${ t("INPUTOUTPUT.digital input 2 state") }` }
						formItem={ form.cfgDigitalInputInternal2ActiveState }
						transformValue={ cfgDigitalInputInternal2ActiveState => translateEnumValue(io.io?.cfgDigitalInputInternal2ActiveState?.enum ?? [], cfgDigitalInputInternal2ActiveState, digitalInputStateDictionary) }
					/>

					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 1") } ➞ ${ t("INPUTOUTPUT.action") }` }
						formItem={ form.cfgRelay1ActiveState }
						transformValue={ cfgRelay1ActiveState => translateEnumValue(io.io?.cfgRelay1ActiveState?.enum ?? [], cfgRelay1ActiveState, digitalOutputDictionary) }
					/>
					{/*<DiffLabelValue
						label={ `Relay 1 mode` }
						formItem={ form.cfgRelay1Mode }
						transformValue={ cfgRelay1Mode => translateEnumValue(io.io?.cfgRelay1Mode?.enum ?? [], cfgRelay1Mode, digitalOutputModeDictionary) }
					/>*/ }
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 1") } ➞ ${ t("INPUTOUTPUT.active time") } [${ io.io?.cfgRelay1ActiveTime?.unit ?? "---" }]` }
						formItem={ form.cfgRelay1ActiveTime }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 1") } ➞ ${ t("INPUTOUTPUT.hold off time") } [${ io.io?.cfgRelay1DeadTime?.unit ?? "---" }]` }
						formItem={ form.cfgRelay1DeadTime }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 2") } ➞ ${ t("INPUTOUTPUT.action") }` }
						formItem={ form.cfgRelay2ActiveState }
						transformValue={ cfgRelay2ActiveState => translateEnumValue(io.io?.cfgRelay2ActiveState?.enum ?? [], cfgRelay2ActiveState, digitalOutputDictionary) }
					/>
					{/*<DiffLabelValue
						label={ `Relay 2 mode` }
						formItem={ form.cfgRelay2Mode }
						transformValue={ cfgRelay2Mode => translateEnumValue(io.io?.cfgRelay2Mode?.enum ?? [], cfgRelay2Mode, digitalOutputModeDictionary) }
					/>*/ }
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 2") } ➞ ${ t("INPUTOUTPUT.active time") } [${ io.io?.cfgRelay2ActiveTime?.unit ?? "---" }]` }
						formItem={ form.cfgRelay2ActiveTime }
					/>
					<DiffLabelValue
						label={ `${ t("SIDEBAR.input output") } ➞ ${ t("INPUTOUTPUT.relays") } ➞ ${ t("INPUTOUTPUT.relay 2") } ➞ ${ t("INPUTOUTPUT.hold off time") } [${ io.io?.cfgRelay2DeadTime?.unit ?? "---" }]` }
						formItem={ form.cfgRelay2DeadTime }
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className="flex justify-end border-none pt-0">
				<Button onClick={ handleClose } color="gray">
					{ t("SETTINGS.cancel") }
				</Button>
				<Button
					color="primary"
					isProcessing={ isSaving }
					onClick={ onConfirm }
				>
					{ t("SETTINGS.save and restart") }
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

type DiffLabelProps<T> = {
	label: ReactNode
	formItem: FormItem<T>
	transformValue?: (value: T) => string
}

function DiffLabelValue<T>(props: DiffLabelProps<T>) {
	const {
		label,
		formItem,
		transformValue = (value: any) => value.toString(),
	} = props;

	if (formItem.value === formItem.initialValue) return null;

	return (
		<LabelValue
			label={ label }
			value={
				<div className="flex gap-1.5">
					<span className="line-through text-red-500">{ transformValue(formItem.initialValue) }</span>
					<span className="text-green-500">{ transformValue(formItem.value) }</span>
				</div>
			}
		/>
	);
}

type EnumOptionsDiff<T extends Enum> = {
	label: ReactNode
	formItem: FormItem<number>
	options: EnumOptionsSetting<T>["options"]
	dictionary: EnumDictionary<T, string>
}

function EnumDiffLabelValue<T extends Enum>(props: EnumOptionsDiff<T>) {

	const {
		label,
		formItem,
		options,
		dictionary,
	} = props;

	const language = useSelector((state: RootState) => state.misc.language);

	if (formItem.value === formItem.initialValue) return null;

	const changedOptions = options.filter(option => (formItem.value & option.mask) !== (formItem.initialValue & option.mask));

	if (changedOptions.length === 0) return null;

	return (
		<LabelValue
			label={ label }
			value={
				<div className="flex flex-col">
					{
						changedOptions
							.map(option =>
								(formItem.value & option.mask) > (formItem.initialValue & option.mask) // added
									?
									<span key={ option.mask } className="text-green-500">{ dictionary(language)[ option.text ] }</span>
									:
									<span key={ option.mask } className="line-through text-red-500">{ dictionary(language)[ option.text ] }</span>,
							)
					}
				</div>
			}
		/>
	);
}

type RecOptDiffLabelValueProps = {
	label: ReactNode
	formItem: FormItem<number>
	options: EnumOptionsSetting<RecordingOption>["options"]
	unit?: string
	unitPercent?: string
}

function RecOptDiffLabelValue(props: RecOptDiffLabelValueProps) {

	const {
		label,
		formItem,
		options,
		unit = "",
		unitPercent = "",
	} = props;

	const language = useSelector((state: RootState) => state.misc.language);

	if (formItem.value === formItem.initialValue) return null;

	const changedOptions = options
		.filter(option => option.text !== RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE)
		.filter(option => (formItem.value & option.mask) !== (formItem.initialValue & option.mask));

	const changedRelativeValues = options
		.filter(option => option.text === RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE)
		.filter(option => (formItem.value & option.mask) !== (formItem.initialValue & option.mask));

	return (
		<>
			{
				changedOptions.length !== 0 &&
                <LabelValue
                    label={ label }
                    value={
						<div className="flex flex-col">
							{
								changedOptions
									.map(option =>
										(formItem.value & option.mask) > (formItem.initialValue & option.mask) // added
											?
											<span key={ option.mask } className="text-green-500">{ recordingOptionDictionary(language)[ option.text ] }</span>
											:
											<span key={ option.mask } className="line-through text-red-500">{ recordingOptionDictionary(language)[ option.text ] }</span>,
									)
							}
						</div>
					}
                />
			}
			{
				changedRelativeValues.length !== 0 &&
                <LabelValue
                    label={ `${ label } unit` }
                    value={
						<div className="flex gap-1.5">
							{
								changedRelativeValues
									.map(option =>
										(formItem.value & option.mask) > (formItem.initialValue & option.mask) // added
											?
											<Fragment key={ option.mask }>
												<span className="line-through text-red-500">{ unit }</span>
												<span className="text-green-500">{ unitPercent }</span>
											</Fragment>
											:
											<Fragment key={ option.mask }>
												<span className="line-through text-red-500">{ unitPercent }</span>
												<span className="text-green-500">{ unit }</span>
											</Fragment>,
									)
							}
						</div>
					}
                />
			}
		</>
	);
}

type ChannelsDiffProps<T extends string> = {
	label: ReactNode
	keysWithTranslations: {
		[key in T]: Nullable<string>
	}
	formItem: FormItem<T[]>
}

function ChannelsDiff<T extends string>(props: ChannelsDiffProps<T>) {
	const {
		label,
		keysWithTranslations,
		formItem,
	} = props;

	const added = formItem.value.filter(val => !formItem.initialValue.includes(val));
	const removed = formItem.initialValue.filter(val => !formItem.value.includes(val));

	if (added.length === 0 && removed.length === 0) return null;

	return (
		<LabelValue
			label={ label }
			value={
				<div className="flex gap-1.5">
					{
						removed
							.filter(val => isNotNull(keysWithTranslations[ val ]))
							.map(val =>
								<span key={ val } className="line-through text-red-500">{ keysWithTranslations[ val ] }</span>,
							)
					}
					{
						added
							.filter(val => isNotNull(keysWithTranslations[ val ]))
							.map(val =>
								<span key={ val } className="text-green-500">{ keysWithTranslations[ val ] }</span>,
							)
					}
				</div>
			}
		/>
	);
}

type ActionsDiffProps = {
	label: ReactNode
	formItem: FormItem<number>
	options: EnumOptionsSetting<MapRelay>["options"]
}

function ActionsDiff(props: ActionsDiffProps) {

	const {
		label,
		formItem,
		options,
	} = props;

	if (formItem.value === formItem.initialValue) return null;

	const added = options
		.filter(option => (formItem.initialValue & option.mask) === 0 && (formItem.value & option.mask) > 0)
		.map(option => mapRelayDictionary()[ option.text ]);

	const removed = options
		.filter(option => (formItem.value & option.mask) === 0 && (formItem.initialValue & option.mask) > 0)
		.map(option => mapRelayDictionary()[ option.text ]);

	return (
		<LabelValue
			label={ label }
			value={
				<div className="flex gap-1.5">
					{
						removed.map(val =>
							<span key={ val } className="line-through text-red-500">{ val }</span>,
						)
					}
					{
						added.map(val =>
							<span key={ val } className="text-green-500">{ val }</span>,
						)
					}
				</div>
			}
		/>
	);
}

const mapStateToProps = (state: RootState) => ({
	isSaving: didLoadingRecordExist(state, { loadableType: LoadableType.SET_BUFFOR_CONFIGURATION }),
});

export default connect(mapStateToProps)(ConfirmConfigurationModal);
