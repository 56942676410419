import { Summary } from "src/app/types/api/dashboard.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { Button, Modal } from "flowbite-react";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";

type Props = {
	isOpen: boolean
	handleClose: () => void
	summary: Summary["summary"]
};

type EditLocalizationOrUserInfoForm = {
	localization: string
	localizationMaxLen: number
	userInfo: string
	userInfoMaxLen: number
}

const validator: FormValidator<EditLocalizationOrUserInfoForm> = {
	localization: (localization, optional, form) => {
		if (localization.length > form.localizationMaxLen.value) {
			return `Location can be up to ${ form.localizationMaxLen.value } characters long`;
		}

		return null;
	},
	localizationMaxLen: () => null,
	userInfo: (userInfo, optional, form) => {
		if (userInfo.length > form.userInfoMaxLen.value) {
			return `User Info can be up to ${ form.userInfoMaxLen.value } characters long`;
		}

		return null;
	},
	userInfoMaxLen: () => null,
};

function EditLocalizationOrUserInfoModal(props: Props) {

	const {
		isOpen,
		handleClose,
		summary,
	} = props;

	const { t } = useTranslation();

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: EditLocalizationOrUserInfoForm) => {
		reducerForm.handleChange("localization", values.localization);
		reducerForm.handleChange("userInfo", values.userInfo);
		handleClose();
	};

	const _getInitialState = (): Form<EditLocalizationOrUserInfoForm> => ({
		localization: createFormField((reducerForm.form.localization.value !== reducerForm.form.localization.initialValue) ? reducerForm.form.localization.value : (summary?.cfgLocalization?.value ?? "")),
		localizationMaxLen: createFormField(summary?.cfgLocalization?.maxLen ?? 40),
		userInfo: createFormField((reducerForm.form.userInfo.value !== reducerForm.form.userInfo.initialValue) ? reducerForm.form.userInfo.value : (summary?.cfgMeterUserInfo?.value ?? "")),
		userInfoMaxLen: createFormField(summary?.cfgMeterUserInfo?.maxLen ?? 40),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(
		_getInitialState(),
		validator,
		_handleSubmit,
	);

	useEffect(() => {
		if (isOpen) {
			setForm(_getInitialState());
		}
	}, [ isOpen ]);

	return (
		<Modal
			show={ isOpen }
			onClose={ handleClose }
		>
			<Modal.Header>{ t("SUMMARY.edit localization and user") }</Modal.Header>
			<form noValidate onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="flex flex-col gap-4">
						<Input
							formItem={ form.localization }
							label={ t("SUMMARY.localization") }
							name="localization"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("localization", e.target.value),
								onBlur: () => handleBlur("localization"),
							} }
							hasBeenChanged={ reducerForm.form.localization.value !== reducerForm.form.localization.initialValue }
						/>
						<Input
							formItem={ form.userInfo }
							label={ t("SUMMARY.user info") }
							name="userInfo"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("userInfo", e.target.value),
								onBlur: () => handleBlur("userInfo"),
							} }
							hasBeenChanged={ reducerForm.form.userInfo.value !== reducerForm.form.userInfo.initialValue }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between gap-2">
					<Button color="gray" onClick={ handleClose }>
						{ t("UTILS.cancel") }
					</Button>
					<Button color="primary" type="submit">
						{ t("UTILS.save") }
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

export default (EditLocalizationOrUserInfoModal);
