import { RootState } from "src/app/store/root.reducer";
import { fetchBackendVersionAsync } from "src/app/store/features/misc/misc.actions";
import { connect } from "react-redux";
import { appVersion } from "src/app/utils/constants/constants";
import { DataState } from "src/app/types/redux.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function AppVersion(props: Props) {

	const _handleVersionClick = () => {
		props.fetchBackendVersion();

		if (props.backendVersion.dataState === DataState.PRESENT) {
			const data = [ new ClipboardItem({ "text/plain": new Blob([ `${ appVersion }\n${ props.backendVersion.data.version }` ], { type: "text/plain" }) }) ];
			navigator.clipboard.write(data);
		}
	};

	return (
		<div
			className="absolute bottom-[2px] left-[2px] cursor-pointer text-center z-50"
			onClick={ _handleVersionClick }
		>
			{
				props.backendVersion.dataState === DataState.PRESENT &&
                <div className="flex gap-1">
                    <p className="whitespace-nowrap text-[9px] leading-tight text-gray-500">
						{ `${ appVersion } (${ props.backendVersion.data.version })` }
                    </p>
                </div>
			}
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	backendVersion: state.misc.backendVersion,
});

const mapDispatchToProps = {
	fetchBackendVersion: fetchBackendVersionAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersion);
