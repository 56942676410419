import { Arc, Circle, Group, Layer, Line, Rect, Stage, Text } from "react-konva";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { DataPhasorLive } from "src/app/types/api/ws.types";
import { Nullable } from "src/app/types/util.types";
import VectorArrow from "src/app/components/Chart/Phasor/VectorArrow.component";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import { PhaseRotation } from "src/app/types/api/settings.types";
import { LayoutVariant } from "src/app/types/ui/layout.types";
import { layoutVariantDictionary, measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import { formatValueToDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import classNames from "classnames";
import Table from "src/app/components/Utils/Table.component";
import { saveKonvaChart } from "src/app/utils/helpers";
import { appColors } from "src/app/utils/constants/theme";
import { GridLoader } from "react-spinners";
import Konva from "konva";
import { MdOutlineFileUpload } from "react-icons/md";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import moment from "moment";
import { MeasurementCategory } from "src/app/types/misc.types";
import { useTranslation } from "react-i18next";
import Translate from "src/app/translations/Translate.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		lastRecord: Nullable<DataPhasorLive>
	};

type TableRecord = {
	name: string
	value: Nullable<string>
	unit: Nullable<string>
}

const _getLinePoints = (x: number, y: number, r: number, angleDegrees: number): [ x1: number, y1: number, x2: number, y2: number ] => [
	Math.cos(_degreeToRadian(angleDegrees)) * r + x,
	Math.sin(_degreeToRadian(angleDegrees)) * r + y,
	Math.cos(_degreeToRadian(180 + angleDegrees)) * r + x,
	Math.sin(_degreeToRadian(180 + angleDegrees)) * r + y,
];

const _getArrowPoints = (x: number, y: number, r: number, angleDegrees: number): [ x1: number, y1: number, x2: number, y2: number ] => [
	x,
	y,
	Math.cos(Math.PI + _degreeToRadian(angleDegrees)) * r + x,
	Math.sin(Math.PI + _degreeToRadian(angleDegrees)) * r + y,
];

const _shiftDegreeScope = (degree: number): number => {
	if (degree < 0) {
		while (degree < 0) {
			degree += 360;
		}
	} else if (degree > 360) {
		while (degree > 360) {
			degree -= 360;
		}
	}

	return degree;
};

const _getLabelDegrees = (firstDegree: number, secondDegree: number): number => {
	const positiveFirstDegree = _shiftDegreeScope(firstDegree);
	const positiveSecondDegree = _shiftDegreeScope(secondDegree);

	const diff = Math.abs(positiveSecondDegree - positiveFirstDegree);
	if (diff < 180) {
		return _shiftDegreeScope(Math.min(positiveFirstDegree, positiveSecondDegree) + diff / 2);
	} else {
		return _shiftDegreeScope(Math.max(positiveFirstDegree, positiveSecondDegree) + Math.abs(diff - 360) / 2);
	}
};

const _degreeToRadian = (angleDegree: number) => angleDegree * Math.PI / 180;

const radiusOffsetDegrees = 90;

const SPACING = 16;
const CARD_SPACING = 16;
const CARD_PADDING = 24 + 24;
const CARD_BORDER = 2;
const CARD_HEADER_HEIGHT = 40;
const BUTTON_GROUP_HEIGHT = 38;

function PhasorContainer(props: Props) {

	const { t } = useTranslation();

	const {
		lastRecord,
		isSidebarOpen,
		mainSize,
	} = props;

	const { recording } = useContext(SettingsContext);

	const cardRef = useRef<HTMLDivElement>(null);
	const stageRef = useRef<Konva.Stage>(null);
	const [ cardWidth, setCardWidth ] = useState(0);
	const [ variant, setVariant ] = useState(LayoutVariant.ALL);
	const [ showTriangle, toggleShowTriangle ] = useState(true);

	useEffect(() => {
		if (isNotNull(cardRef.current)) {
			const maxHeight = mainSize.height - SPACING - BUTTON_GROUP_HEIGHT - CARD_SPACING - CARD_PADDING - CARD_HEADER_HEIGHT - CARD_BORDER;
			if (maxHeight > 0) {
				setCardWidth(Math.min(maxHeight, cardRef.current.getBoundingClientRect().width));
			}
		}
	}, [ mainSize.height, mainSize.width, isSidebarOpen, variant ]);

	const radius = (cardWidth * 0.9) * 0.5;

	const xCenter = cardWidth / 2;
	const yCenter = cardWidth / 2;

	const u1Value = lastRecord?.phasor?.U1n_fund_rms?.value ?? lastRecord?.phasor?.U1e_fund_rms?.value;
	const u2Value = lastRecord?.phasor?.U2n_fund_rms?.value ?? lastRecord?.phasor?.U2e_fund_rms?.value;
	const u3Value = lastRecord?.phasor?.U3n_fund_rms?.value ?? lastRecord?.phasor?.U3e_fund_rms?.value;
	const u1Angle = lastRecord?.phasor?.U1n_fund_angle?.value ?? lastRecord?.phasor?.U1e_fund_angle?.value;
	const u2Angle = lastRecord?.phasor?.U2n_fund_angle?.value ?? lastRecord?.phasor?.U2e_fund_angle?.value;
	const u3Angle = lastRecord?.phasor?.U3n_fund_angle?.value ?? lastRecord?.phasor?.U3e_fund_angle?.value;

	const isU1n = isNotNull(u1Value);
	const isU2n = isNotNull(u2Value);
	const isU3n = isNotNull(u3Value);
	const isI1 = isNotNull(lastRecord?.phasor?.I1_fund_rms?.value);
	const isI2 = isNotNull(lastRecord?.phasor?.I2_fund_rms?.value);
	const isI3 = isNotNull(lastRecord?.phasor?.I3_fund_rms?.value);

	const isRightRotation = false;//settingsRecording.recording.cfgPhaseRotation?.enum?.some(enumValue => enumValue.value === settingsRecording.recording.cfgPhaseRotation?.value && enumValue.text === PhaseRotation.PHASE_ROTATION_CLOCKWISE) ?? true;
	const isRightArrowDirection = recording?.recording?.cfgPhaseRotation?.enum?.some(enumValue => enumValue.value === recording?.recording?.cfgPhaseRotation?.value && enumValue.text === PhaseRotation.PHASE_ROTATION_CLOCKWISE) ?? true;

	const uMax = Math.max(u1Value ?? 0, u2Value ?? 0, u3Value ?? 0);

	const u1Radius = (0.8 * (u1Value ?? 0)) / uMax * radius;
	const u1AngleDegrees = (isRightRotation ? 1 : -1) * (u1Angle ?? 0) + radiusOffsetDegrees;

	const u2Radius = (0.8 * (u2Value ?? 0)) / uMax * radius;
	const u2AngleDegrees = (isRightRotation ? 1 : -1) * (u2Angle ?? 0) + radiusOffsetDegrees;

	const u3Radius = (0.8 * (u3Value ?? 0)) / uMax * radius;
	const u3AngleDegrees = (isRightRotation ? 1 : -1) * (u3Angle ?? 0) + radiusOffsetDegrees;

	const iMax = Math.max(lastRecord?.phasor?.I1_fund_rms?.value ?? 0, lastRecord?.phasor?.I2_fund_rms?.value ?? 0, lastRecord?.phasor?.I3_fund_rms?.value ?? 0);

	const i1Radius = (0.8 * (lastRecord?.phasor?.I1_fund_rms?.value ?? 0)) / iMax * radius;
	const i1AngleDegrees = (isRightRotation ? 1 : -1) * (lastRecord?.phasor?.I1_fund_angle?.value ?? 0) + radiusOffsetDegrees;

	const i2Radius = (0.8 * (lastRecord?.phasor?.I2_fund_rms?.value ?? 0)) / iMax * radius;
	const i2AngleDegrees = (isRightRotation ? 1 : -1) * (lastRecord?.phasor?.I2_fund_angle?.value ?? 0) + radiusOffsetDegrees;

	const i3Radius = (0.8 * (lastRecord?.phasor?.I3_fund_rms?.value ?? 0)) / iMax * radius;
	const i3AngleDegrees = (isRightRotation ? 1 : -1) * (lastRecord?.phasor?.I3_fund_angle?.value ?? 0) + radiusOffsetDegrees;

	// Coordinates
	const labelRadiusOffset = 8;
	const u1Points = _getArrowPoints(xCenter, yCenter, u1Radius, u1AngleDegrees);
	const u1LabelPoints = _getArrowPoints(xCenter, yCenter, u1Radius + labelRadiusOffset, u1AngleDegrees);
	const u2Points = _getArrowPoints(xCenter, yCenter, u2Radius, u2AngleDegrees);
	const u2LabelPoints = _getArrowPoints(xCenter, yCenter, u2Radius + labelRadiusOffset, u2AngleDegrees);
	const u3Points = _getArrowPoints(xCenter, yCenter, u3Radius, u3AngleDegrees);
	const u3LabelPoints = _getArrowPoints(xCenter, yCenter, u3Radius + labelRadiusOffset, u3AngleDegrees);

	const x12_1 = u1Points[ 2 ];
	const y12_1 = u1Points[ 3 ];
	const x12_2 = u2Points[ 2 ];
	const y12_2 = u2Points[ 3 ];
	const u12CenterPoint = [ x12_1 + 0.5 * (x12_2 - x12_1), y12_1 + 0.5 * (y12_2 - y12_1) ];
	const u12Radius = Math.sqrt(Math.pow(xCenter - u12CenterPoint[ 0 ], 2) + Math.pow(yCenter - u12CenterPoint[ 1 ], 2));
	const u12LabelPoints = _getArrowPoints(xCenter, yCenter, u12Radius + labelRadiusOffset, _getLabelDegrees(u1AngleDegrees, u2AngleDegrees));

	const x23_1 = u2Points[ 2 ];
	const y23_1 = u2Points[ 3 ];
	const x23_2 = u3Points[ 2 ];
	const y23_2 = u3Points[ 3 ];
	const u23CenterPoints = [ x23_1 + 0.5 * (x23_2 - x23_1), y23_1 + 0.5 * (y23_2 - y23_1) ];
	const u23Radius = Math.sqrt(Math.pow(xCenter - u23CenterPoints[ 0 ], 2) + Math.pow(yCenter - u23CenterPoints[ 1 ], 2));
	const u23LabelPoints = _getArrowPoints(xCenter, yCenter, u23Radius + labelRadiusOffset, _getLabelDegrees(u2AngleDegrees, u3AngleDegrees));

	const x31_1 = u3Points[ 2 ];
	const y31_1 = u3Points[ 3 ];
	const x31_2 = u1Points[ 2 ];
	const y31_2 = u1Points[ 3 ];
	const u31CenterPoint = [ x31_1 + 0.5 * (x31_2 - x31_1), y31_1 + 0.5 * (y31_2 - y31_1) ];
	const u31Radius = Math.sqrt(Math.pow(xCenter - u31CenterPoint[ 0 ], 2) + Math.pow(yCenter - u31CenterPoint[ 1 ], 2));
	const u31LabelPoints = _getArrowPoints(xCenter, yCenter, u31Radius + labelRadiusOffset, _getLabelDegrees(u3AngleDegrees, u1AngleDegrees));

	const i1Points = _getArrowPoints(xCenter, yCenter, i1Radius, i1AngleDegrees);
	const i1LabelPoints = _getArrowPoints(xCenter, yCenter, i1Radius + labelRadiusOffset, i1AngleDegrees);
	const i2Points = _getArrowPoints(xCenter, yCenter, i2Radius, i2AngleDegrees);
	const i2LabelPoints = _getArrowPoints(xCenter, yCenter, i2Radius + labelRadiusOffset, i2AngleDegrees);
	const i3Points = _getArrowPoints(xCenter, yCenter, i3Radius, i3AngleDegrees);
	const i3LabelPoints = _getArrowPoints(xCenter, yCenter, i3Radius + labelRadiusOffset, i3AngleDegrees);

	const _exportToCsv = () => {
		const rows = [
			isNotNull(lastRecord?.phasor?.U1n_fund_rms) ? [ `U1n[${ lastRecord?.phasor?.U1n_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U1n_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U1n_fund_angle) ? [ `U1n angle[${ lastRecord?.phasor?.U1n_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U1n_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U2n_fund_rms) ? [ `U2n[${ lastRecord?.phasor?.U2n_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U2n_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U2n_fund_angle) ? [ `U2n angle[${ lastRecord?.phasor?.U2n_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U2n_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U3n_fund_rms) ? [ `U3n[${ lastRecord?.phasor?.U3n_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U3n_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U3n_fund_angle) ? [ `U3n angle[${ lastRecord?.phasor?.U3n_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U3n_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U1e_fund_rms) ? [ `U1e[${ lastRecord?.phasor?.U1e_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U1e_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U1e_fund_angle) ? [ `U1e angle[${ lastRecord?.phasor?.U1e_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U1e_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U2e_fund_rms) ? [ `U2e[${ lastRecord?.phasor?.U2e_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U2e_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U2e_fund_angle) ? [ `U2e angle[${ lastRecord?.phasor?.U2e_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U2e_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U3e_fund_rms) ? [ `U3e[${ lastRecord?.phasor?.U3e_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.U3e_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			isNotNull(lastRecord?.phasor?.U3e_fund_angle) ? [ `U3e angle[${ lastRecord?.phasor?.U3e_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.U3e_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ] : null,
			[ `I1[${ lastRecord?.phasor?.I1_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.I1_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
			[ `I1 angle[${ lastRecord?.phasor?.I1_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.I1_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
			[ `I2[${ lastRecord?.phasor?.I2_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.I2_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
			[ `I2 angle[${ lastRecord?.phasor?.I2_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.I2_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
			[ `I3[${ lastRecord?.phasor?.I3_fund_rms?.unit ?? "V" }]`, `"${ (lastRecord?.phasor?.I3_fund_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
			[ `I3 angle[${ lastRecord?.phasor?.I3_fund_angle?.unit ?? "°" }]`, `"${ (lastRecord?.phasor?.I3_fund_angle?.value ?? 0).toFixed(6).replace(".", ",") }"` ],
		].filter(isNotNull);

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `phasor_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	return (
		<div className="flex flex-col gap-4">
			<Button.Group>
				{
					Object.values(LayoutVariant).map(layoutVariant =>
						<Button
							key={ layoutVariant }
							color={ variant === layoutVariant ? "primary" : "gray" }
							onClick={ () => setVariant(layoutVariant) }
							pill
							size="sm"
						>
							{ layoutVariantDictionary()[ layoutVariant ] }
						</Button>,
					)
				}
			</Button.Group>
			<div className="grid grid-cols-3 gap-4">
				<div
					className={
						classNames(
							{ "col-span-2": variant === LayoutVariant.ALL },
							{ "col-span-3": variant === LayoutVariant.CHART },
						)
					}
				>
					{
						variant !== LayoutVariant.TABLE &&
                        <Card className="col-span-2">
                            <div className="flex justify-between items-center">
                                <ToggleSwitch
                                    className="[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4"
                                    checked={ showTriangle }
                                    label={ t("PHASORS.draw triangle") }
                                    onChange={ () => toggleShowTriangle(prevState => !prevState) }
                                />
                                <Dropdown
                                    size="sm"
                                    color="transparent"
                                    label={
										<>
											<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
											{ t("UTILS.export") }
										</>
									}
                                >
                                    <DropdownItem
                                        onClick={ () => {
											const name = `PQM750_phasor_${ moment(lastRecord?.phasor?.timestamp).format("MM-DD-HH-mm-ss") }`;
											if (isNull(stageRef.current)) return;

											saveKonvaChart(name, stageRef.current);
										} }
                                    >
										{ t("UTILS.png") }
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={ _exportToCsv }
                                    >
										{ t("UTILS.csv") }
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <div ref={ cardRef } className="flex justify-center relative">
								{
									isNull(lastRecord) &&
                                    <div className="z-50 absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 flex">
                                        <GridLoader size={ 15 } color="#0093DD"/>
                                    </div>
								}
                                <Stage
                                    width={ cardWidth }
                                    height={ cardWidth }
                                    ref={ stageRef }
                                >
                                    <Layer name="background">
                                        <Rect
                                            x={ 0 }
                                            y={ 0 }
                                            width={ cardWidth }
                                            height={ cardWidth }
                                            fill="white"
                                        />
                                        <Circle x={ xCenter } y={ yCenter } dash={ [ 5 ] } stroke="#9ca3af" fill="transparent" radius={ 1 / 5 * radius }/>
                                        <Circle x={ xCenter } y={ yCenter } dash={ [ 5 ] } stroke="#9ca3af" fill="transparent" radius={ 2 / 5 * radius }/>
                                        <Circle x={ xCenter } y={ yCenter } dash={ [ 5 ] } stroke="#9ca3af" fill="transparent" radius={ 3 / 5 * radius }/>
                                        <Circle x={ xCenter } y={ yCenter } dash={ [ 5 ] } stroke="#9ca3af" fill="transparent" radius={ 4 / 5 * radius }/>
                                        <Circle x={ xCenter } y={ yCenter } dash={ [ 5 ] } stroke="#9ca3af" fill="transparent" radius={ radius }/>
                                        <Line points={ [ xCenter + radius, yCenter, xCenter - radius, yCenter ] } dash={ [ 5 ] } stroke="#9ca3af"/>
                                        <Line points={ [ xCenter, yCenter - radius, xCenter, yCenter + radius ] } dash={ [ 5 ] } stroke="#9ca3af"/>
                                        <Line points={ _getLinePoints(xCenter, yCenter, radius, 30) } dash={ [ 5 ] } stroke="#9ca3af"/>
                                        <Line points={ _getLinePoints(xCenter, yCenter, radius, 60) } dash={ [ 5 ] } stroke="#9ca3af"/>
                                        <Line points={ _getLinePoints(xCenter, yCenter, radius, 120) } dash={ [ 5 ] } stroke="#9ca3af"/>
                                        <Line points={ _getLinePoints(xCenter, yCenter, radius, 150) } dash={ [ 5 ] } stroke="#9ca3af"/>
                                    </Layer>
                                    <Layer name="legend">
                                        <Group name="arc-arrow-left">
                                            <Arc
                                                angle={ 30 }
                                                rotation={ isRightArrowDirection ? 285 : 225 }
                                                innerRadius={ radius + 20 }
                                                outerRadius={ radius + 20 }
                                                x={ xCenter }
                                                y={ yCenter }
                                                stroke="black"
                                            />
                                            <Line
                                                points={
													[
														Math.cos(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + xCenter,
														Math.sin(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + yCenter,

														Math.cos(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + xCenter,
														Math.sin(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + yCenter - 6,

														Math.cos(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + xCenter + (isRightArrowDirection ? -6 : 6),
														Math.sin(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + yCenter,

														Math.cos(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + xCenter,
														Math.sin(_degreeToRadian(180 + (isRightArrowDirection ? 135 : 45))) * (radius + 20) + yCenter,
													]
												}
                                                stroke="black"
                                            />
                                        </Group>
                                        <Text
                                            text="0"
                                            fontSize={ 16 }
                                            x={ xCenter - 4 }
                                            y={ yCenter - radius - 20 }
                                            stroke="black"
                                            strokeWidth={ 1 }
                                            fill="black"
                                        />
                                    </Layer>
									{
										isNotNull(lastRecord) &&
                                        <>
											{/*U1*/ }
											{
												isU1n &&
                                                <VectorArrow
                                                    points={ u1Points }
                                                    labelPoints={ u1LabelPoints }
                                                    angle={ u1AngleDegrees }
                                                    name={ isNotNull(lastRecord?.phasor?.U1n_fund_rms) ? "U1N" : "U1E" }
                                                    color={ appColors.U1 }
                                                />
											}
											{/*U2*/ }
											{
												isU2n &&
                                                <VectorArrow
                                                    points={ u2Points }
                                                    labelPoints={ u2LabelPoints }
                                                    angle={ u2AngleDegrees }
                                                    name={ isNotNull(lastRecord?.phasor?.U2n_fund_rms) ? "U2N" : "U2E" }
                                                    color={ appColors.U2 }
                                                />
											}
											{/*U3*/ }
											{
												isU3n &&
                                                <VectorArrow
                                                    points={ u3Points }
                                                    labelPoints={ u3LabelPoints }
                                                    angle={ u3AngleDegrees }
                                                    name={ isNotNull(lastRecord?.phasor?.U3n_fund_rms) ? "U3N" : "U3E" }
                                                    color={ appColors.U3 }
                                                />
											}
											{/*I1*/ }
											{
												isI1 &&
                                                <VectorArrow
                                                    points={ i1Points }
                                                    labelPoints={ i1LabelPoints }
                                                    angle={ i1AngleDegrees }
                                                    name="I1"
                                                    color={ appColors.I1 }
                                                />
											}
											{/*I2*/ }
											{
												isI2 &&
                                                <VectorArrow
                                                    points={ i2Points }
                                                    labelPoints={ i2LabelPoints }
                                                    angle={ i2AngleDegrees }
                                                    name="I2"
                                                    color={ appColors.I2 }
                                                />
											}
											{/*I3*/ }
											{
												isI3 &&
                                                <VectorArrow
                                                    points={ i3Points }
                                                    labelPoints={ i3LabelPoints }
                                                    angle={ i3AngleDegrees }
                                                    name="I3"
                                                    color={ appColors.I3 }
                                                />
											}
											{/*U Connector Line*/ }
											{
												showTriangle &&
                                                <>
													{
														(isU1n && isU2n) &&
                                                        <VectorArrow
                                                            points={ [
																u1Points[ 2 ],
																u1Points[ 3 ],
																u2Points[ 2 ],
																u2Points[ 3 ],
															] }
                                                            labelPoints={ u12LabelPoints }
                                                            angle={ _getLabelDegrees(u1AngleDegrees, u2AngleDegrees) }
                                                            name="U12"
                                                            color={ appColors.U12 }
                                                            strokeWidth={ 2 }
                                                        />
													}
													{
														(isU2n && isU3n) &&
                                                        <VectorArrow
                                                            points={ [
																u2Points[ 2 ],
																u2Points[ 3 ],
																u3Points[ 2 ],
																u3Points[ 3 ],
															] }
                                                            labelPoints={ u23LabelPoints }
                                                            angle={ _getLabelDegrees(u2AngleDegrees, u3AngleDegrees) }
                                                            name="U23"
                                                            color={ appColors.U23 }
                                                            strokeWidth={ 2 }
                                                        />
													}
													{
														(isU1n && isU3n) &&
                                                        <VectorArrow
                                                            points={ [
																u3Points[ 2 ],
																u3Points[ 3 ],
																u1Points[ 2 ],
																u1Points[ 3 ],
															] }
                                                            labelPoints={ u31LabelPoints }
                                                            angle={ _getLabelDegrees(u3AngleDegrees, u1AngleDegrees) }
                                                            name="U31"
                                                            color={ appColors.U31 }
                                                            strokeWidth={ 2 }
                                                        />
													}
                                                </>
											}
                                        </>
									}
                                </Stage>
                            </div>
                        </Card>
					}
				</div>
				<div
					className={
						classNames(
							{ "col-span-3": variant === LayoutVariant.TABLE },
							{ "hidden": variant === LayoutVariant.CHART },
						)
					}
				>
					<Table
						className="[&>div]:border-b-0"
						title={ t("TABLE.actual values") }
						isLoading={ isNull(lastRecord) }
						options={ {
							filter: false,
							search: false,
							pagination: false,
							setRowProps: (_, dataIndex) => {
								if (isNull(lastRecord)) return {};
								return {
									className: measurementCategoryRowClassNameDictionary()[ MeasurementCategory.PHASORS ],
								};
							},
						} }
						cellPadding={ 0 }
						columns={ [
							{
								name: "Parameter",
								label: t("TABLE.parameter").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) =>
										<div>
											<div className={ `!p-2 ${ measurementCategoryCellClassNameDictionary()[ MeasurementCategory.PHASORS ] }` }>
												{ parameter.name }
											</div>
										</div>,
									sort: false,
									filter: false,
								},
							}, {
								name: "Value",
								label: t("TABLE.value").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => (
										<span>
											{ parameter.value !== null && parameter.value !== undefined ? parameter.value : "---" }
										</span>
									),
									sort: false,
									filter: false,
								},
							}, {
								name: "Unit",
								label: t("TABLE.unit").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => parameter.unit ?? "---",
									sort: false,
									filter: false,
								},
							},
						] }
						data={ [
							isNotNull(lastRecord?.phasor?.U1n_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U1N" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U1n_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U1n_fund_rms?.value, lastRecord?.phasor?.U1n_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U1n_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U1n angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U1n_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U1n_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U2n_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U2N" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U2n_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U2n_fund_rms?.value, lastRecord?.phasor?.U2n_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U2n_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U2n angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U2n_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U2n_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U3n_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U3N" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U3n_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U3n_fund_rms?.value, lastRecord?.phasor?.U3n_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U3n_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U3n angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U3n_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U3n_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U1e_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U1E" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U1e_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U1e_fund_rms?.value, lastRecord?.phasor?.U1e_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U1e_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U1e angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U1e_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U1e_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U2e_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U2E" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U2e_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U2e_fund_rms?.value, lastRecord?.phasor?.U2e_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U2e_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U2e angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U2e_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U2e_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U3e_fund_rms) ? {
								name: <Translate i18nKey="TABLES.U3E" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U3e_fund_rms?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U3e_fund_rms?.value, lastRecord?.phasor?.U3e_fund_rms?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U3e_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.U3e angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U3e_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.U3e_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.I1_fund_rms) ? {
								name: <Translate i18nKey="TABLES.I1" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I1_fund_rms?.value, MeasurementCategory.CURRENTS),
								unit: getPrefixToUnit(lastRecord?.phasor?.I1_fund_rms?.value, lastRecord?.phasor?.I1_fund_rms?.unit, MeasurementCategory.CURRENTS),
							} : undefined,
							isNotNull(lastRecord?.phasor?.I1_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.I1 angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I1_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.I1_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.I2_fund_rms) ? {
								name: <Translate i18nKey="TABLES.I2" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I2_fund_rms?.value, MeasurementCategory.CURRENTS),
								unit: getPrefixToUnit(lastRecord?.phasor?.I2_fund_rms?.value, lastRecord?.phasor?.I2_fund_rms?.unit, MeasurementCategory.CURRENTS),
							} : undefined,
							isNotNull(lastRecord?.phasor?.I2_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.I2 angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I2_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.I2_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.I3_fund_rms) ? {
								name: <Translate i18nKey="TABLES.I3" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I3_fund_rms?.value, MeasurementCategory.CURRENTS),
								unit: getPrefixToUnit(lastRecord?.phasor?.I3_fund_rms?.value, lastRecord?.phasor?.I3_fund_rms?.unit, MeasurementCategory.CURRENTS),
							} : undefined,
							isNotNull(lastRecord?.phasor?.I3_fund_angle) ? {
								name: <Translate i18nKey="PHASORS.I3 angle" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.I3_fund_angle?.value, MeasurementCategory.ANGLES),
								unit: lastRecord?.phasor?.I3_fund_angle?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U_unbalance_zero_sequence) ? {
								name: <Translate i18nKey="PHASORS.U unbalance zero seq" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U_unbalance_zero_sequence?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U_unbalance_zero_sequence?.value, lastRecord?.phasor?.U_unbalance_zero_sequence?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U_unbalance_positive_sequence) ? {
								name: <Translate i18nKey="PHASORS.U unbalance positive seq" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U_unbalance_positive_sequence?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U_unbalance_positive_sequence?.value, lastRecord?.phasor?.U_unbalance_positive_sequence?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U_unbalance_negative_sequence) ? {
								name: <Translate i18nKey="PHASORS.U unbalance negative seq" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U_unbalance_negative_sequence?.value, MeasurementCategory.VOLTAGES),
								unit: getPrefixToUnit(lastRecord?.phasor?.U_unbalance_negative_sequence?.value, lastRecord?.phasor?.U_unbalance_negative_sequence?.unit, MeasurementCategory.VOLTAGES),
							} : undefined,
							isNotNull(lastRecord?.phasor?.U_unbalance_zero) ? {
								name: <Translate i18nKey="PHASORS.U unbalance zero" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U_unbalance_zero?.value, MeasurementCategory.PHASORS),
								unit: lastRecord?.phasor?.U_unbalance_zero?.unit,
							} : undefined,
							isNotNull(lastRecord?.phasor?.U_unbalance_negative) ? {
								name: <Translate i18nKey="PHASORS.U unbalance negative" className="whitespace-nowrap"/>,
								value: formatValueToDeciSON(lastRecord?.phasor?.U_unbalance_negative?.value, MeasurementCategory.PHASORS),
								unit: lastRecord?.phasor?.U_unbalance_negative?.unit,
							} : undefined,
						].filter(isNotNull) }
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	mainSize: state.ui.layout.mainSize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(PhasorContainer);
