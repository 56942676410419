import { createAction } from "typesafe-actions";
import { FetchEventRequest } from "src/app/types/api/dashboard.types";

export const uiFetchSummary = createAction("UI_DASHBOARD__FETCH_SUMMARY")<undefined>();

export const uiFetchStandardEvents = createAction("UI_DASHBOARD__FETCH_STANDARD_EVENTS")<undefined>();
export const uiFetchMoreStandardEvents = createAction("UI_DASHBOARD__FETCH_MORE_STANDARD_EVENTS")<number>(); // number of actual events

export const uiFetchUserEvents = createAction("UI_DASHBOARD__FETCH_USER_EVENTS")<undefined>();
export const uiFetchMoreUserEvents = createAction("UI_DASHBOARD__FETCH_MORE_USER_EVENTS")<number>(); // number of actual events

export const uiFetchStandardEventWaveform = createAction("UI_DASHBOARD__FETCH_STANDARD_EVENT_WAVEFORM")<FetchEventRequest>();
export const uiFetchUserEventWaveform = createAction("UI_DASHBOARD__FETCH_USER_EVENT_WAVEFORM")<FetchEventRequest>();

export const uiFetchStandardEventRmsWaveform = createAction("UI_DASHBOARD__FETCH_STANDARD_EVENT_RMS_WAVEFORM")<FetchEventRequest>();
export const uiFetchUserEventRmsWaveform = createAction("UI_DASHBOARD__FETCH_USER_EVENT_RMS_WAVEFORM")<FetchEventRequest>();

export const uiFetchStandardEventPqdif = createAction("UI_DASHBOARD__FETCH_STANDARD_EVENT_PQDIF")<FetchEventRequest>();
export const uiFetchUserEventPqdif = createAction("UI_DASHBOARD__FETCH_USER_EVENT_PQDIF")<FetchEventRequest>();

export const uiExportSettings = createAction("UI_DASHBOARD__EXPORT_SETTINGS")<undefined>();
export const uiImportSettings = createAction("UI_DASHBOARD__IMPORT_SETTINGS")<File>();
