import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { uiAuthMe, uiChangePassword, uiCreateUser, uiDeleteUser, uiFetchUsers, uiLogin, uiResetPassword } from "src/app/store/features/ui/user/ui.user.actions";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { changePasswordAsync, createUserAsync, deleteUserByIdAsync, fetchUsersAsync, loginAsync, resetPasswordAsync } from "src/app/store/features/user/user.actions";
import { push } from "redux-first-history";
import { defaultView } from "src/app/utils/constants/constants";
import { loginFormActions } from "src/app/store/features/form/form.actions";
import { authLoadingRecord, settingsLoadingRecords } from "src/app/store/features/ui/loading/ui.loading.reducer";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { uiFetchSettings } from "src/app/store/features/ui/settings/ui.settings.actions";
import i18n from "src/app/translations/i18n";
import { matchPath } from "react-router-dom";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import privateNoSidebarRoutes from "src/app/utils/routes/privateNoSidebar.routes";

export const uiLoginEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiLogin)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.LOGIN })),
				...settingsLoadingRecords.map(record => of(addLoadingRecord(record))),
				of(loginAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(loginAsync.success, action) || isActionOf(loginAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(loginAsync.success, responseAction)) {
							return merge(
								of(loginFormActions.resetForm()),
								of(push(defaultView)),
								of(uiFetchSettings()),
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
							);
						} else if (isActionOf(loginAsync.failure, responseAction)) {
							/*if (responseAction.payload.errors.some(error => error.httpStatus === 428 && error.codeName === ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP)) {
								return merge(
									of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
									of(push("/login/2fa")),
								);
							} else if (responseAction.payload.errors.some(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS)) {
								const error = responseAction.payload.errors.find(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS);
								if (isNull(error)) return of(removeLoadingRecord({ loadableType: LoadableType.LOGIN }));

								const date = moment().add(error.availableInSeconds, "s").format("HH:mm:ss");
								return merge(
									of(addMessage({
										view: View.LOGIN,
										type: SnackbarMessageType.ERROR,
										message: `Ooops. You've exceeded the max number of login attempts. For security reasons, you have to wait ${ error.availableInSeconds } seconds (${ date }) before try again`,
									})),
									of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								);
							}*/
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								...settingsLoadingRecords.map(record => of(removeLoadingRecord(record))),
							);
						} else {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								...settingsLoadingRecords.map(record => of(removeLoadingRecord(record))),
							);
						}
					}),
				),
			),
		),
	);

export const uiAuthMeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiAuthMe)),
		switchMap(_ => {
			const location = state$.value.router.location;
			if (isNull(location)) {
				return concat(
					of(removeLoadingRecord(authLoadingRecord)),
					of(uiFetchSettings()),
				);
			}

			const paths = privateNoSidebarRoutes.map(route => route.path);

			if (paths.some(path => isNotNull(matchPath(path, location.pathname)))) {
				return of(removeLoadingRecord(authLoadingRecord));
			}

			return concat(
				of(removeLoadingRecord(authLoadingRecord)),
				of(uiFetchSettings()),
			);
		}),
	);

export const uiChangePasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiChangePassword)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.UPDATE_PASSWORD })),
				of(changePasswordAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(changePasswordAsync.success, action) || isActionOf(changePasswordAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(changePasswordAsync.success, responseAction)) {
							if (responseAction.payload.data.userPasswordChange.status === 3) {
								return merge(
									of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.USER.wrong password") })),
									of(removeLoadingRecord({ loadableType: LoadableType.UPDATE_PASSWORD })),
								);
							} else {
								return merge(
									of(displayToast({ type: ToastType.SUCCESS, content: i18n.t("RESPONSES.USER.changed password success") })),
									of(removeLoadingRecord({ loadableType: LoadableType.UPDATE_PASSWORD })),
								);
							}
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.UPDATE_PASSWORD }));
						}
					}),
				),
			),
		),
	);

export const uiFetchUsersEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchUsers)),
		mergeMap(() =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.FETCH_USERS })),
				of(fetchUsersAsync.request()),
				action$.pipe(
					filter(action => isActionOf(fetchUsersAsync.success, action) || isActionOf(fetchUsersAsync.failure, action)),
					take(1),
					map(() => removeLoadingRecord({ loadableType: LoadableType.FETCH_USERS })),
				),
			),
		),
	);

export const uiCreateUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateUser)),
		mergeMap((action) =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.CREATE_USER })),
				of(createUserAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createUserAsync.success, action) || isActionOf(createUserAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createUserAsync.success, responseAction)) {
							if (responseAction.payload.data.userAdd.status === 4) {
								return merge(
									of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.USER.user exist") })),
									of(removeLoadingRecord({ loadableType: LoadableType.CREATE_USER })),
								);
							} else {
								return merge(
									of(displayToast({ type: ToastType.SUCCESS, content: i18n.t("RESPONSES.USER.user create success") })),
									of(fetchUsersAsync.request()),
									of(removeLoadingRecord({ loadableType: LoadableType.CREATE_USER })),
								);
							}
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.CREATE_USER }));
						}
					}),
				),
			),
		),
	);

export const uiDeleteUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteUser)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.user_name, loadableType: LoadableType.DELETE_USER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteUserByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteUserByIdAsync.success, action) || isActionOf(deleteUserByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteUserByIdAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: i18n.t("RESPONSES.USER.user deleted success") })),
								of(fetchUsersAsync.request()),
								of(removeLoadingRecord(loadingRecord)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiResetPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiResetPassword)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.user_name, loadableType: LoadableType.RESET_PASSWORD };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(resetPasswordAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(resetPasswordAsync.success, action) || isActionOf(resetPasswordAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(resetPasswordAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: i18n.t("RESPONSES.USER.password reset success") })),
								of(removeLoadingRecord(loadingRecord)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
