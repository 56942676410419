import { Card } from "flowbite-react";
import Select from "src/app/components/Form/Select.component";
import { createFormField } from "src/app/utils/forms";
import { useTranslation } from "react-i18next";

type Props = {};

function Rs485Card(props: Props) {

	const { t } = useTranslation();

	return (
		<Card>
			<div className="flex flex-col gap-3">
				<Select
					label={ t("COMMUNICATIONS.RS-485 no 1") }
					options={ [ { value: 0, label: "Modbus RTU" } ] }
					formItem={ createFormField(0, { disabled: true }) }
					onChange={ () => undefined }
					isSearchable={ false }
					isClearable={ false }
				/>
				<Select
					label={ t("COMMUNICATIONS.RS-485 no 2") }
					options={ [ { value: 0, label: "IRIG-B" } ] }
					formItem={ createFormField(0, { disabled: true }) }
					onChange={ () => undefined }
					isSearchable={ false }
					isClearable={ false }
				/>
			</div>
		</Card>
	);
}

export default (Rs485Card);
