import { connect } from "react-redux";
import { useSidebarContext } from "src/app/hoc/providers/Sidebar.provider";
import { Navbar as FlowbiteNavbar } from "flowbite-react";
import { HiMenuAlt1, HiX } from "react-icons/hi";
import { RootState } from "src/app/store/root.reducer";
import { isSmallScreen } from "src/app/store/features/ui/layout/ui.layout.selectors";
import UserDropdown from "src/app/components/Layout/Navbar/UserDropdown.component";
import { defaultView } from "src/app/utils/constants/constants";
import { Link } from "react-router-dom";
import Breadcrumbs from "src/app/components/Layout/Breadcrumbs.component";
import { ReactComponent as SonelLogo } from "src/assets/icons/logo.svg";
import ConfigurationSaver from "src/app/components/Layout/ConfigurationSaver.component";
import LanguageDropdown from "src/app/components/Layout/Navbar/LanguageDropdown.component";

type Props =
	{
		withSidebar: boolean
	}
	& ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function Navbar(props: Props) {

	const {
		isSmallScreen,
		withSidebar,
	} = props;

	const {
		isSidebarOpen,
		isPageWithSidebar,
		setOpenOnSmallScreens,
	} = useSidebarContext();

	return (
		<FlowbiteNavbar fluid className="z-50">
			<div className="w-full p-3 lg:px-5 lg:pl-3">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						{
							(withSidebar && isPageWithSidebar) && (
								<button
									onClick={ () => setOpenOnSmallScreens(!isSidebarOpen) }
									className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
								>
									<span className="sr-only">Toggle sidebar</span>
									{
										(isSidebarOpen && isSmallScreen)
											?
											<HiX className="h-6 w-6"/>
											:
											<HiMenuAlt1 className="h-6 w-6"/>

									}
								</button>
							) }
						<Link to={ defaultView }>
							<FlowbiteNavbar.Brand as="div">
								<SonelLogo/>
							</FlowbiteNavbar.Brand>
						</Link>
						<Breadcrumbs className="ml-8 md:ml-16 hidden lg:block"/>
						{
							withSidebar &&
                            <ConfigurationSaver className="ml-8"/>
						}
					</div>
					<div className="flex items-center lg:gap-3">
						<div>
							<LanguageDropdown/>
						</div>
						<div>
							<UserDropdown/>
						</div>
					</div>
				</div>
			</div>
		</FlowbiteNavbar>
	);
}

const mapStateToProps = (state: RootState) => ({
	isSmallScreen: isSmallScreen(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
