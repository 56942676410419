import { SettingsIo } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { digitalOutputDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsIo: SettingsIo
	}

type IoForm = {
	cfgRelay1ActiveState: number
	cfgRelay1Mode: number
	cfgRelay1ActiveTime: string
	cfgRelay1ActiveTimeMin: number
	cfgRelay1ActiveTimeMax: number
	cfgRelay1DeadTime: string
	cfgRelay1DeadTimeMin: number
	cfgRelay1DeadTimeMax: number
	cfgRelay2ActiveState: number
	cfgRelay2Mode: number
	cfgRelay2ActiveTime: string
	cfgRelay2ActiveTimeMin: number
	cfgRelay2ActiveTimeMax: number
	cfgRelay2DeadTime: string
	cfgRelay2DeadTimeMin: number
	cfgRelay2DeadTimeMax: number
}

const validator: FormValidator<IoForm> = {
	cfgRelay1ActiveState: () => null,
	cfgRelay1Mode: () => null,
	cfgRelay1ActiveTime: (cfgRelay1ActiveTime, optional, form) => validateNumberField(i18n.t("INPUTOUTPUT.active time"), cfgRelay1ActiveTime, optional, "he", { from: form.cfgRelay1ActiveTimeMin.value, to: form.cfgRelay1ActiveTimeMax.value }),
	cfgRelay1ActiveTimeMin: () => null,
	cfgRelay1ActiveTimeMax: () => null,
	cfgRelay1DeadTime: (cfgRelay1DeadTime, optional, form) => validateNumberField(i18n.t("INPUTOUTPUT.hold off time"), cfgRelay1DeadTime, optional, "he", { from: form.cfgRelay1DeadTimeMin.value, to: form.cfgRelay1DeadTimeMax.value, decimalPlaces: 2 }),
	cfgRelay1DeadTimeMin: () => null,
	cfgRelay1DeadTimeMax: () => null,
	cfgRelay2ActiveState: () => null,
	cfgRelay2Mode: () => null,
	cfgRelay2ActiveTime: (cfgRelay2ActiveTime, optional, form) => validateNumberField(i18n.t("INPUTOUTPUT.active time"), cfgRelay2ActiveTime, optional, "he", { from: form.cfgRelay2ActiveTimeMin.value, to: form.cfgRelay2ActiveTimeMax.value }),
	cfgRelay2ActiveTimeMin: () => null,
	cfgRelay2ActiveTimeMax: () => null,
	cfgRelay2DeadTime: (cfgRelay2DeadTime, optional, form) => validateNumberField(i18n.t("INPUTOUTPUT.hold off time"), cfgRelay2DeadTime, optional, "he", { from: form.cfgRelay2DeadTimeMin.value, to: form.cfgRelay2DeadTimeMax.value, decimalPlaces: 2 }),
	cfgRelay2DeadTimeMin: () => null,
	cfgRelay2DeadTimeMax: () => null,
};

function RelaysCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsIo,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: IoForm) => {
		reducerForm.handleChange("cfgRelay1ActiveState", values.cfgRelay1ActiveState);
		reducerForm.handleChange("cfgRelay1Mode", values.cfgRelay1Mode);
		reducerForm.handleChange("cfgRelay1ActiveTime", values.cfgRelay1ActiveTime);
		reducerForm.handleChange("cfgRelay1DeadTime", values.cfgRelay1DeadTime);
		reducerForm.handleChange("cfgRelay2ActiveState", values.cfgRelay2ActiveState);
		reducerForm.handleChange("cfgRelay2Mode", values.cfgRelay2Mode);
		reducerForm.handleChange("cfgRelay2ActiveTime", values.cfgRelay2ActiveTime);
		reducerForm.handleChange("cfgRelay2DeadTime", values.cfgRelay2DeadTime);
	};

	const _getInitialState = () => ({
		cfgRelay1ActiveState: createFormField((reducerForm.form.cfgRelay1ActiveState.value !== reducerForm.form.cfgRelay1ActiveState.initialValue) ? reducerForm.form.cfgRelay1ActiveState.value : settingsIo.io?.cfgRelay1ActiveState?.value ?? 0, { disabled: !isAdmin }),
		cfgRelay1Mode: createFormField((reducerForm.form.cfgRelay1Mode.value !== reducerForm.form.cfgRelay1Mode.initialValue) ? reducerForm.form.cfgRelay1Mode.value : settingsIo.io?.cfgRelay1Mode?.value ?? 0, { disabled: !isAdmin }),
		cfgRelay1ActiveTime: createFormField((reducerForm.form.cfgRelay1ActiveTime.value !== reducerForm.form.cfgRelay1ActiveTime.initialValue) ? reducerForm.form.cfgRelay1ActiveTime.value : settingsIo.io?.cfgRelay1ActiveTime?.value?.toString() ?? "", { disabled: !isAdmin }),
		cfgRelay1ActiveTimeMin: createFormField(settingsIo.io?.cfgRelay1ActiveTime?.minValue ?? 0, { disabled: !isAdmin }),
		cfgRelay1ActiveTimeMax: createFormField(settingsIo.io?.cfgRelay1ActiveTime?.maxValue ?? 0, { disabled: !isAdmin }),
		cfgRelay1DeadTime: createFormField((reducerForm.form.cfgRelay1DeadTime.value !== reducerForm.form.cfgRelay1DeadTime.initialValue) ? reducerForm.form.cfgRelay1DeadTime.value : settingsIo.io?.cfgRelay1DeadTime?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		cfgRelay1DeadTimeMin: createFormField(settingsIo.io?.cfgRelay1DeadTime?.minValue ?? 0, { disabled: !isAdmin }),
		cfgRelay1DeadTimeMax: createFormField(settingsIo.io?.cfgRelay1DeadTime?.maxValue ?? 0, { disabled: !isAdmin }),
		cfgRelay2ActiveState: createFormField((reducerForm.form.cfgRelay2ActiveState.value !== reducerForm.form.cfgRelay2ActiveState.initialValue) ? reducerForm.form.cfgRelay2ActiveState.value : settingsIo.io?.cfgRelay2ActiveState?.value ?? 0, { disabled: !isAdmin }),
		cfgRelay2Mode: createFormField((reducerForm.form.cfgRelay2Mode.value !== reducerForm.form.cfgRelay2Mode.initialValue) ? reducerForm.form.cfgRelay2Mode.value : settingsIo.io?.cfgRelay2Mode?.value ?? 0, { disabled: !isAdmin }),
		cfgRelay2ActiveTime: createFormField((reducerForm.form.cfgRelay2ActiveTime.value !== reducerForm.form.cfgRelay2ActiveTime.initialValue) ? reducerForm.form.cfgRelay2ActiveTime.value : settingsIo.io?.cfgRelay2ActiveTime?.value?.toString() ?? "", { disabled: !isAdmin }),
		cfgRelay2ActiveTimeMin: createFormField(settingsIo.io?.cfgRelay2ActiveTime?.minValue ?? 0, { disabled: !isAdmin }),
		cfgRelay2ActiveTimeMax: createFormField(settingsIo.io?.cfgRelay2ActiveTime?.maxValue ?? 0, { disabled: !isAdmin }),
		cfgRelay2DeadTime: createFormField((reducerForm.form.cfgRelay2DeadTime.value !== reducerForm.form.cfgRelay2DeadTime.initialValue) ? reducerForm.form.cfgRelay2DeadTime.value : settingsIo.io?.cfgRelay2DeadTime?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		cfgRelay2DeadTimeMin: createFormField(settingsIo.io?.cfgRelay2DeadTime?.minValue ?? 0, { disabled: !isAdmin }),
		cfgRelay2DeadTimeMax: createFormField(settingsIo.io?.cfgRelay2DeadTime?.maxValue ?? 0, { disabled: !isAdmin }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, []);

	useEffect(() => {
		handleChange("cfgRelay1ActiveState", reducerForm.form.cfgRelay1ActiveState.value);
		handleChange("cfgRelay1Mode", reducerForm.form.cfgRelay1Mode.value);
		handleChange("cfgRelay1ActiveTime", reducerForm.form.cfgRelay1ActiveTime.value);
		handleChange("cfgRelay1DeadTime", reducerForm.form.cfgRelay1DeadTime.value);
		handleChange("cfgRelay2ActiveState", reducerForm.form.cfgRelay2ActiveState.value);
		handleChange("cfgRelay2Mode", reducerForm.form.cfgRelay2Mode.value);
		handleChange("cfgRelay2ActiveTime", reducerForm.form.cfgRelay2ActiveTime.value);
		handleChange("cfgRelay2DeadTime", reducerForm.form.cfgRelay2DeadTime.value);
	}, [
		reducerForm.form.cfgRelay1ActiveState.value,
		reducerForm.form.cfgRelay1Mode.value,
		reducerForm.form.cfgRelay1ActiveTime.value,
		reducerForm.form.cfgRelay1DeadTime.value,
		reducerForm.form.cfgRelay2ActiveState.value,
		reducerForm.form.cfgRelay2Mode.value,
		reducerForm.form.cfgRelay2ActiveTime.value,
		reducerForm.form.cfgRelay2DeadTime.value,
	]);

	return (
		<Card>
			<form className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<div className="grid grid-cols-2 gap-4">
					<div className="flex flex-col gap-2">
						<h6 className="text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
							{ t("INPUTOUTPUT.relay 1") }
						</h6>
						{/*<Select
							label={t('INPUTOUTPUT.mode')}
							options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgRelay1Mode, digitalOutputModeDictionary) }
							formItem={ form.cfgRelay1Mode }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("cfgRelay1Mode", option?.value);
								handleBlur("cfgRelay1Mode");
							} }
							isSearchable={ false }
							isClearable={ false }
							hasBeenChanged={ reducerForm.form.cfgRelay1Mode.value !== reducerForm.form.cfgRelay1Mode.initialValue }
						/>*/ }
						<Input
							formItem={ form.cfgRelay1ActiveTime }
							label={ `${ t("INPUTOUTPUT.active time") } [${ settingsIo.io?.cfgRelay1ActiveTime?.unit ?? "---" }]` }
							name="cfgRelay1ActiveTime"
							inputProps={ {
								type: "number",
								step: 10,
								min: form.cfgRelay1ActiveTimeMin.value,
								max: form.cfgRelay1ActiveTimeMax.value,
								onChange: (e) => handleChange("cfgRelay1ActiveTime", e.target.value),
								onBlur: () => handleBlur("cfgRelay1ActiveTime"),
							} }
							hasBeenChanged={ reducerForm.form.cfgRelay1ActiveTime.value !== reducerForm.form.cfgRelay1ActiveTime.initialValue }
						/>
						<Input
							formItem={ form.cfgRelay1DeadTime }
							label={ `${ t("INPUTOUTPUT.hold off time") } [${ settingsIo.io?.cfgRelay1DeadTime?.unit ?? "---" }]` }
							name="cfgRelay1DeadTime"
							inputProps={ {
								type: "number",
								step: 1,
								onChange: (e) => handleChange("cfgRelay1DeadTime", e.target.value),
								onBlur: () => handleBlur("cfgRelay1DeadTime"),
							} }
							hasBeenChanged={ reducerForm.form.cfgRelay1DeadTime.value !== reducerForm.form.cfgRelay1DeadTime.initialValue }
						/>
						<Select
							label={ t("INPUTOUTPUT.action") }
							options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgRelay1ActiveState, digitalOutputDictionary) }
							formItem={ form.cfgRelay1ActiveState }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("cfgRelay1ActiveState", option?.value);
								handleBlur("cfgRelay1ActiveState");
							} }
							isSearchable={ false }
							isClearable={ false }
							hasBeenChanged={ reducerForm.form.cfgRelay1ActiveState.value !== reducerForm.form.cfgRelay1ActiveState.initialValue }
						/>
					</div>
					<div className="flex flex-col gap-2">
						<h6 className="text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
							{ t("INPUTOUTPUT.relay 2") }
						</h6>
						{/*<Select
							label={t('INPUTOUTPUT.mode')}
							options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgRelay2Mode, digitalOutputModeDictionary) }
							formItem={ form.cfgRelay2Mode }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("cfgRelay2Mode", option?.value);
								handleBlur("cfgRelay2Mode");
							} }
							isSearchable={ false }
							isClearable={ false }
							hasBeenChanged={ reducerForm.form.cfgRelay2Mode.value !== reducerForm.form.cfgRelay2Mode.initialValue }
						/>*/ }
						<Input
							formItem={ form.cfgRelay2ActiveTime }
							label={ `${ t("INPUTOUTPUT.active time") } [${ settingsIo.io?.cfgRelay2ActiveTime?.unit ?? "---" }]` }
							name="cfgRelay2ActiveTime"
							inputProps={ {
								type: "number",
								step: 10,
								min: form.cfgRelay2ActiveTimeMin.value,
								max: form.cfgRelay2ActiveTimeMax.value,
								onChange: (e) => handleChange("cfgRelay2ActiveTime", e.target.value),
								onBlur: () => handleBlur("cfgRelay2ActiveTime"),
							} }
							hasBeenChanged={ reducerForm.form.cfgRelay2ActiveTime.value !== reducerForm.form.cfgRelay2ActiveTime.initialValue }
						/>
						<Input
							formItem={ form.cfgRelay2DeadTime }
							label={ `${ t("INPUTOUTPUT.hold off time") } [${ settingsIo.io?.cfgRelay2DeadTime?.unit ?? "---" }]` }
							name="cfgRelay2DeadTime"
							inputProps={ {
								type: "number",
								step: 1,
								onChange: (e) => handleChange("cfgRelay2DeadTime", e.target.value),
								onBlur: () => handleBlur("cfgRelay2DeadTime"),
							} }
							hasBeenChanged={ reducerForm.form.cfgRelay2DeadTime.value !== reducerForm.form.cfgRelay2DeadTime.initialValue }
						/>
						<Select
							label={ t("INPUTOUTPUT.action") }
							options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgRelay2ActiveState, digitalOutputDictionary) }
							formItem={ form.cfgRelay2ActiveState }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("cfgRelay2ActiveState", option?.value);
								handleBlur("cfgRelay2ActiveState");
							} }
							isSearchable={ false }
							isClearable={ false }
							hasBeenChanged={ reducerForm.form.cfgRelay2ActiveState.value !== reducerForm.form.cfgRelay2ActiveState.initialValue }
						/>
					</div>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(RelaysCard);
