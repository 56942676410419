import { createAction } from "typesafe-actions";
import { createNetworkAction } from "src/app/utils/redux";
import { SuccessPayload } from "src/app/types/api/api.types";
import { BackendVersion } from "src/app/types/misc.types";
import { Nullable } from "src/app/types/util.types";
import { LanguageCode } from "src/app/translations/utils";

export const applicationStart = createAction("MISC__APPLICATION_START")<undefined>();
// export const registerActivity = createAction("MISC__REGISTER_ACTIVITY")<undefined>();
export const changeLanguage = createAction("MISC__CHANGE_LANGUAGE")<LanguageCode>();

export const fetchBackendVersionAsync = createNetworkAction<undefined, SuccessPayload<BackendVersion>>("MISC__FETCH_BACKEND_VERSION");
export const sendErrorAsync = createNetworkAction<any, SuccessPayload<null>>("MISC__SEND_ERROR");

export const setImpersonateUserId = createAction("MISC__SET_IMPERSONATE_USER_ID")<Nullable<number>>();

export const empty = createAction("MISC__EMPTY")<undefined>();
